import axios from 'axios';
import { log, generateUtmExt, isMobileApp } from '../../common/common';
import appConfig from '../../config/config';
import { authHeader } from '../helper/authHeader';
import { logoutUser } from './login.action';

import packageJson from '../../../package.json';
import { loginConstants } from '../constants/login.constant';
const url = appConfig.apiLoc;
const apiVersion = packageJson.version;
export const shareActions = (
  type,
  id,
  constantSuccess,
  constantFaliure,
  extrautm,
  referal_code = 'not_found',
  user_type = '',
  user_id = '',
  callback
) => {
  return async (dispatch) => {
    axios({
      method: 'GET',
      url: `${url}openapi/service?type=${type}&type_id=${id}${generateUtmExt()}&referal_code=${referal_code}&user_type=${user_id}&user_id=${user_type}`,
      headers: authHeader()
    })
      .then((response) => {
        if (response.data) {
          // console.log('successssssssssssssss', response);
          callback(response.data);
          dispatch({
            type: constantSuccess,
            payload: response.data
          });
          dispatch({
            type: loginConstants.LOG_OUT
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status == 401) {
            dispatch(logoutUser());
          }
        }
        log('Error', error);
        callback('error');
        dispatch({
          type: constantFaliure,
          payload: error
        });
      });
  };
};
export const getElectionShareData = (election_id, callback) => {
  return async (dispatch) => {
    axios({
      method: 'GET',
      url: `${url}openapi/share?election_id=${election_id}${generateUtmExt()}`,
      headers: {
        version: isMobileApp() ? `rjsa ${apiVersion}` : `rjsw ${apiVersion}`
      }
    })
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status == 401) {
            dispatch(logoutUser());
          }
        }
        log('Error', error);
        callback('error');
      });
  };
};
//https://developer.clirnet.com/knowledge/rnv21/openapi_new/share?election_id=50&channel_id=19
export const saveUtm = (payload) => {
  return {
    type: 'SAVE_UTM',
    payload
  };
};
