import axios from 'axios';
import jwt_decode from 'jwt-decode';
import dayjs from 'dayjs';
import { getLocalStorage, isMobileApp, setLocalStorage } from '../../common/common';
import packageJson from '../../../package.json';
import appConfig from '../../config/config';
import { onlineManager } from 'react-query';
import { toast } from 'react-toastify';
const baseURL = appConfig.apiLoc;
const authService = appConfig?.apiServices?.getTokenService;
const apiVersion = packageJson.version;

let accessToken = ''; /// getLocalStorage('accessToken', '') ? getLocalStorage('accessToken', '') : null;
accessToken = getLocalStorage('accessToken', '') ? getLocalStorage('accessToken', '') : null;
// let refreshToken = getLocalStorage('refreshToken', '') ? getLocalStorage('refreshToken', '') : null;
const axiosInstance = axios.create({
  baseURL,
  headers: {
    Authorization: getLocalStorage('accessToken', ''),
    version: isMobileApp() ? `rjsa ${apiVersion}` : `rjsw ${apiVersion}`
  }
});
const isOnline = onlineManager.isOnline();
axiosInstance.interceptors.request.use(
  async (req) => {
    if (!accessToken) {
      accessToken = getLocalStorage('accessToken', '') ? getLocalStorage('accessToken', '') : null;
      req.headers.Authorization = accessToken;
    }
    let access = ''; //jwt_decode(accessToken);
    access = jwt_decode(accessToken);
    let isExpired = '';
    isExpired = dayjs.unix(access.exp).diff(dayjs()) < 1;
    let isgetMethod = req?.method == 'get';
    let utmSource = getLocalStorage('utm_source', '');
    if (isgetMethod && utmSource) {
      let base_url = req.baseURL ? req.baseURL : baseURL;
      let req_url = new URL(base_url + req.url);
      console.log('req_url', req_url);
      if (!req_url.searchParams.has('utm_source')) {
        req_url.searchParams.append('utm_source', utmSource);
        req.url = req_url.toString();
      }
    }


    //console.group('AXIOS_INSTANCE', req);
    if (isOnline) {
      if (!isExpired) {
        accessToken = null;
        return req;
      } else {
        accessToken = null;
        try {
          const response = await axios.post(`${authService}auth/getAccessToken`, {
            token: getLocalStorage('refreshToken', '')
          });
          if (response.data.status_code == 200) {
            setLocalStorage('accessToken', response.data.data.token);
            req.headers.Authorization = response.data.data.token;
          }
          return req;
        } catch (error) {
          try {
            const response = await axios.post(`${authService}auth/getRefreshToken`, {
              token: getLocalStorage('refreshToken', '')
            });
            if (response.data.status_code == 200) {
              setLocalStorage('accessToken', response.data.data.token);
              setLocalStorage('refreshToken', response.data.data.refresh_token);
            }
            req.headers.Authorization = response.data.data.token;
            return req;
          } catch (error) {
            // alert('Please login again');
            window.location.href = window.location.origin;
            localStorage.clear();
            sessionStorage.clear();
            return Promise.reject('loginAgain');
          }
        }
      }
    } else {
      toast.error('Please make sure you have an active internet connection');
    }
  },
  async (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const res = await axios.post(`${authService}auth/getAccessToken`, {
          token: getLocalStorage('refreshToken', '')
        });
        if (res.data.status_code == 200) {
          // 1) put token to LocalStorage
          setLocalStorage('accessToken', res.data.data.token);
          // 2) Change Authorization header
          originalRequest.headers.Authorization = res.data.data.token;
          // 3) return originalRequest object with Axios.
          //console.groupEnd('AXIOS_INSTANCE_response_error', error);
          return axios(originalRequest);
        }
      } catch (error) {
        console.log('error', error);
      }
    }

    return Promise.reject(error);
  }
);
export default axiosInstance;
