import Loadable from 'react-loadable';
import LoadingFallback from '../fallback/LoadingFallbackNew';
import React from 'react';

const Login = Loadable({
  loader: () => import('../../../src/pages/login/LoginNew'),
  loading: LoadingFallback
});

const Registration = Loadable({
  loader: () => import('../../pages/registration/Registration'),
  loading: LoadingFallback
});

const NotFound = Loadable({
  loader: () => import('../../../src/pages/notfound/NotFound'),
  loading: LoadingFallback
});

const UnavailableV2 = Loadable({
  loader: () => import('../../../src/pages/unavailable/UnavailableV2'),
  loading: LoadingFallback
});
const AutoLogin = Loadable({
  loader: () => import('../../../src/pages/login/autoLogin'),
  loading: LoadingFallback
});
const TelemedApp = Loadable({
  loader: () => import('../../../src/pages/telemedLite/TelemedApp'),
  loading: LoadingFallback
});

const TelemedShare = Loadable({
  loader: () => import('../../../src/pages/share/TelemedShare'),
  loading: LoadingFallback
});

const AutoAuth = Loadable({
  loader: () => import('../../../src/pages/Autoauth/autoauth'),
  loading: LoadingFallback
});
const SessionInvitiation = Loadable({
  loader: () => import('../../../src/pages/session/SessionInvitation'),
  loading: LoadingFallback
});

export const publicRoutes = [
  {
    path: '/',
    component: <Login />,
    module: 'generic'
  },
  {
    path: '/Login',
    component: <Login />,
    module: 'generic'
  },
  {
    path: '/Login/:type/:data',
    component: <Login />,
    subPath: ['', ':utm'],
    module: 'generic'
  },
  {
    path: '/registration',
    component: <Registration />,
    subPath: ['', ':email', ':email/:referal_code'],
    module: 'generic'
  },
  {
    path: '/NotFound',
    component: <NotFound />,
    module: 'generic'
  },
  {
    path: '/Unavailable',
    component: <UnavailableV2 />,
    module: 'generic'
  },
  {
    path: '*',
    component: <NotFound />,
    module: 'generic'
  },
  {
    path: '/Autologin/:token/:redirect_name',
    subPath: [':redirect_page_id/:auto_subscription', ':redirect_page_id', ''],
    component: <AutoLogin />,
    module: 'generic'
  },
  {
    path: '/Telemed_App',
    component: <TelemedApp />,
    module: 'telemed'
  },
  {
    path: '/share/telemed',
    component: <TelemedShare />,
    module: 'telemed'
  },
  {
    path: '/SessionInvitation/:content_type/:content_id/:utm_source/:user_type/:user_id',
    component: <SessionInvitiation />,
    subPath: ['', ':token'],
    module: 'session'
  },
  {
    path: '/autoauth/:content_type/:content_id/:utm_source',
    component: <AutoAuth />,
    module: 'generic'
  },
  {
    path: '/autoauth/:content_type/:content_id/:utm_source/:user_type/:user_id',
    component: <AutoAuth />,
    subPath: ['', ':token'],
    module: 'generic'
  },
  {
    path: '/autoauthDirect/:content_type/:content_id/:utm_source/:user_type/:user_id',
    component: <AutoAuth />,
    subPath: ['', ':token'],
    module: 'generic'
  }
];
