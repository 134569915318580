//firebase
import 'firebase/storage';
import firebase from 'firebase/app';
import appConfig from '../config/config';
import { getLocalStorage, log } from './common';
import { toast } from 'react-toastify';
import 'firebase/auth';
const firebase_config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID
};
firebase.initializeApp(firebase_config);
const storage = firebase.storage();
export const firebaseUpload = (
  e,
  firebaseToken,
  fileTypeArray,
  progressCb,
  callback,
  errCallBack
) => {
  console.log('HEY', e, firebaseToken);
  if (e && firebaseToken) {
    const imageUrl = e;
    console.log('file', e);
    var unix_time = Math.round(+new Date() / 1000);
    var rand = Math.floor(Math.random() * 100000 + 1);
    const ext = imageUrl.name.split('.').pop().toLowerCase(); // Extract image extension

    const userDetails = getLocalStorage('user', {});
    console.log('USER', userDetails.user_master_id);
    const filename = `${userDetails.user_master_id + unix_time + rand}.${ext}`;
    console.log('FILE NAME', filename);
    if (fileTypeArray.includes(ext)) {
      firebase
        .auth()
        .signInWithCustomToken(firebaseToken)
        .then(() => {
          // Generate unique name

          const uploadTask = storage
            .ref(`${appConfig.imgFolder}${userDetails?.user_master_id}/${filename}`)
            .put(imageUrl);
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Upload is ' + progress + '% done');
              progressCb(Math.round(progress));
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                  console.log('Upload is paused');
                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  console.log('Upload is running');
                  break;
              }
            },
            (error) => {
              log('error', error);
            },
            () => {
              storage
                .ref(`${appConfig.imgFolder}${userDetails?.user_master_id}`)
                .child(filename)
                .getDownloadURL()
                .then((url) => {
                  callback(url, filename);
                })
                .catch((e) => console.log(e));
            }
          );
        })
        .catch((error) => {
          log('error', error);
        });
    } else {
      toast.error(`Allowed File Extensions : ${fileTypeArray.join()}`);
      errCallBack();
    }
  }
};

export const CapacitorFirebaseUpload = (
  e,
  firebaseToken,
  fileTypeArray,
  progressCb,
  callback,
  errCallBack
) => {
  console.log('HEY', e, firebaseToken);
  if (e && firebaseToken) {
    const imageUrl = e;
    console.log('file', JSON.stringify(e));
    var unix_time = Math.round(+new Date() / 1000);
    var rand = Math.floor(Math.random() * 100000 + 1);
    const ext = imageUrl.name.split('.').pop().toLowerCase(); // Extract image extension
    //alert(ext);
    const userDetails = getLocalStorage('user', {});
    const filename = `${userDetails.user_master_id + unix_time + rand}.${ext}`;
    console.log('FILE NAME', filename);
    if (fileTypeArray.includes(ext)) {
      firebase
        .auth()
        .signInWithCustomToken(firebaseToken)
        .then(() => {
          // Generate unique name

          const uploadTask = storage
            .ref(`${appConfig.imgFolder}${userDetails?.user_master_id}/${filename}`)
            // .putString(imageUrl);
            .putString(imageUrl.data, 'base64', { contentType: 'image/jpeg' });
          uploadTask.on(
            'state_changed',
            (snapshot) => {
              console.log('snapshot.state', snapshot);
              var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              console.log('Upload is ' + progress + '% done');
              progressCb(Math.round(progress));
              switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                  console.log('Upload is paused');
                  break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                  console.log('Upload is running');
                  break;
              }
            },
            (error) => {
              log('uploadTask error', JSON.stringify(error));
            },
            () => {
              storage
                .ref(`${appConfig.imgFolder}${userDetails?.user_master_id}`)
                .child(filename)
                .getDownloadURL()
                .then((url) => {
                  callback(url, filename);
                })
                .catch((e) => console.log(e));
            }
          );
        })
        .catch((error) => {
          console.log('my firebase error', JSON.stringify(error));
          log('error', error);
        });
    } else {
      toast.error(`Allowed File Extensions : ${fileTypeArray.join()}`);
      errCallBack();
    }
  }
};
