import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { CapacitorUpdater } from '@capgo/capacitor-updater';
import { FirebaseDynamicLinks } from '@pantrist/capacitor-firebase-dynamic-links';
import React, { useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getLocalStorage, isMobileApp, openNewTab, setLocalStorage } from '../common/common';
// import type { BundleInfo } from '@capgo/capacitor-updater'
import axios from 'axios';
import { toast } from 'react-toastify';
import currentPackage from '../../package.json';
import ConfirmationModal from '../components/modals/ConfirmationModal';
import appConfig from '../config/config';
import { isMobile } from 'react-device-detect';

const MobileNativeEventsListener = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const apiVersion = currentPackage.version;
  const isAndroid = Capacitor.getPlatform() == 'android';
  const isIos = Capacitor.getPlatform() == 'ios';
  const [UpdateTitle, setUpdateTitle] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const maxUpdateCancelDelay = useRef(0);
  const myDownloadVersion = useRef('');

  const onClose = () => {
    setOpenModal(false);
  };

  const cancleClick = () => {
    const cancleCount = getLocalStorage('update_calcle_count', 0);
    if (cancleCount >= 0 && cancleCount <= maxUpdateCancelDelay.current) {
      const newCount = cancleCount + 1;
      setLocalStorage('update_calcle_count', newCount);
      setOpenModal(false);
    } else {
      toast.warning('You Have To Update Your App');
    }
  };

  const confirmClick = () => {
    if (isAndroid) {
      // console.log("inside android redirect");
      let url = 'https://play.google.com/store/apps/details?id=app.clirnet.com.clirnetapp&pli=1';
      openNewTab(url, (e) => {
        console.log('error', e);
      });
    }
    if (isIos) {
      // console.log("inside ios redirect");
      let url = 'https://apps.apple.com/us/app/clirnet/id1450672654?ls=1';
      openNewTab(url, (e) => {
        console.log('error', e);
      });
    }
  };

  let supportedStartUrls = [
    'https://doctor.clirnet.com/index.html#/',

    'https://doctor.clirnet.com/#/',

    'https://doctor.clirnet.com/services/#/',

    'https://doctor.clirnet.com/services/index.html#/'
  ];

  const getSubstringToReplace = (url) => {
    let substringToReplace = '';
    supportedStartUrls.forEach((element) => {
      if (url.startsWith(element)) {
        substringToReplace = element;
      }
    });
    return substringToReplace;
  };

  const removeSubstringFromURL = (url, substringToRemove) => {
    return url.replace(substringToRemove, '');
  };

  const checkAppVersion = async (state) => {
    // console.log("inside fnc call", JSON.stringify(state));
    // let version = ''
    try {
      axios({
        baseURL: appConfig.apiLoc,
        url: getLocalStorage('accessToken', '') ? 'settings/version' : 'openapi/version',
        method: 'GET',
        headers: {
          Authorization: getLocalStorage('accessToken', ''),
          version: isMobileApp() ? `rjsa ${apiVersion}` : `rjsw ${apiVersion}`
        }
      }).then(async (resp) => {
        let res = resp.data;
        console.log("my api response", JSON.stringify(res));
        setUpdateTitle(res.data.text);
        let currentAppVersion = '';
        const appInfo = await App.getInfo();
        currentAppVersion = appInfo?.version;
        maxUpdateCancelDelay.current = res.data.maxUpdateCount;

        // play store update
        if (isAndroid && state.isActive && currentAppVersion != res.data.appVersionAndroid) {
          setOpenModal(true);
        }
        // apple store update 
        if (isIos && state.isActive && currentAppVersion != res.data.appVersionIos) {
          setOpenModal(true);
        }

        // live app update android 
        if (isAndroid) {
          if (state.isActive && currentPackage.version !== res.data.version) {
            if (currentPackage.version !== res.data.version) {
              myDownloadVersion.current = await CapacitorUpdater.download({
                url: res.data.link,
                version: res.data.version
              });
            }
          }
          if (!state.isActive && myDownloadVersion.current && currentPackage.version !== res.data.version) {
            try {
              await CapacitorUpdater.set({ id: myDownloadVersion.current.id });
              CapacitorUpdater.notifyAppReady();
            } catch (err) {
            }
          }
        }

        // live app update ios 
        if (isIos) {
          if (state.isActive && currentPackage.version !== res.data.versionIos) {
            if (currentPackage.version !== res.data.versionIos) {
              myDownloadVersion.current = await CapacitorUpdater.download({
                url: res.data.linkIos,
                version: res.data.versionIos
              });
            }
          }
          if (!state.isActive && myDownloadVersion.current && currentPackage.version !== res.data.versionIos) {
            try {
              await CapacitorUpdater.set({ id: myDownloadVersion.current.id });
              CapacitorUpdater.notifyAppReady();
            } catch (err) {
            }
          }
        }

      });
    } catch (err) {
      // console.log("Something went wrong");
    }
  };

  const addCapacitorListeners = () => {
    // console.log("inside addCapacitorListeners");
    // universal link listener for android
    App.addListener('appUrlOpen', (event) => {
      let url = event.url;
      // console.log("my event appUrlOpen==>", JSON.stringify(event));

      if (isIos && url.includes('app.clirnet.com.clirnetapp')) {
        return false;
      } else {
        const IsDeepLinkUrl = url.includes('clirnet.page.link');
        const autoAuthUrl = url.includes('autoauth');
        // console.log("autoAuthUrl====>>", autoAuthUrl);
        if (autoAuthUrl) {
          setLocalStorage('autoAuthFromAppUrlListener', true);
        }
        if (!IsDeepLinkUrl) {
          let substringToRemove = getSubstringToReplace(url);
          // console.log(":substringToRemove", JSON.stringify(substringToRemove));
          const modifiedURL = removeSubstringFromURL(url, substringToRemove);
          // console.log(":modified url", JSON.stringify(modifiedURL));

          if (modifiedURL == 'https://doctor.clirnet.com/') {
            navigate(`/Dashboard`);
          } else {
            let splittedURL = modifiedURL.split('/');
            let content_type = splittedURL[1];
            let content_id = splittedURL[2];
            let params = {
              content_type: content_type || '',
              content_id: content_id || 0
            };
            navigate(`/${modifiedURL}`);
          }

          // console.log("params", JSON.stringify(params));
          // navigateTo(params);
          // console.log("navigate url", `/${modifiedURL}`);
          // navigate("/autoauth/medwiki/12663/MESBSMS_MWMTPBILL29JULYIMPLI_INFO_final/user_master/1/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvZG9jdG9yLmNsaXJuZXQuY29tIiwiYXVkIjoiaHR0cHM6XC9cL2RvY3Rvci5jbGlybmV0LmNvbSIsInVzZXJkZXRhaWwiOnsidXNlcl9vcmlnaW4iOiJ1c2VyX21hc3RlciIsInVzZXJfbWFzdGVyX2lkIjoiMSIsInVzZXJuYW1lIjoiTWFua29tYnUiLCJmaXJzdF9uYW1lIjoiTWFua29tYnUiLCJtaWRkbGVfbmFtZSI6IlNhbWJhc2l2YW4iLCJsYXN0X25hbWUiOiJTd2FtaW5hdGhhbiIsImVtYWlsIjoiYXJuYWIuc2FoYSsxQGNsaXJuZXQuY29tIiwibW9iaWxlIjoiODIyMDQ4ODY4NSIsImNsaWVudCI6IjEiLCJjbGllbnRfaWRzIjoiMSIsImdyb3VwX2lkcyI6IjEifSwiaWF0IjoxNjgyNzY2Mzc5LCJleHAiOjE3MTQzODg3Nzl9.NPZEbBwAKX8KxnDVwVPFHLoMGQb_NaWl_pjgLZtzfA")
          // navigate("/autoauth/medwiki/20666/MESBSMS_MWMTPBILL29JULYIMPLI_INFO_final/user_master/1/eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwczpcL1wvZG9jdG9yLmNsaXJuZXQuY29tIiwiYXVkIjoiaHR0cHM6XC9cL2RvY3Rvci5jbGlybmV0LmNvbSIsInVzZXJkZXRhaWwiOnsidXNlcl9vcmlnaW4iOiJ1c2VyX21hc3RlciIsInVzZXJfbWFzdGVyX2lkIjoiMSIsInVzZXJuYW1lIjoiTWFua29tYnUiLCJmaXJzdF9uYW1lIjoiTWFua29tYnUiLCJtaWRkbGVfbmFtZSI6IlNhbWJhc2l2YW4iLCJsYXN0X25hbWUiOiJTd2FtaW5hdGhhbiIsImVtYWlsIjoiYXJuYWIuc2FoYSsxQGNsaXJuZXQuY29tIiwibW9iaWxlIjoiODIyMDQ4ODY4NSIsImNsaWVudCI6IjEiLCJjbGllbnRfaWRzIjoiMSIsImdyb3VwX2lkcyI6IjEifSwiaWF0IjoxNjkwMjgyNjIzLCJleHAiOjE3MjE5MDUwMjN9.mAeaKTK1hjO7Hscf-FkhdJSfxN_XF-odw1LMiRAqDyM")
          // navigate(`/share/medwiki/${12663}`);

          // navigate(myUri.hash.replace('#', ''))
          setTimeout(() => {
            window.location.reload();
          }, 0);
        }
      }
    });
    // deep link listener for android
    FirebaseDynamicLinks.addListener('deepLinkOpen', (data) => {
      // console.log("my event deepLinkOpen for FirebaseDynamicLinks==>", JSON.stringify(data));
      // alert("my event deepLinkOpen for FirebaseDynamicLinks==>" + JSON.stringify(data));

      let content_type = '';
      let content_id = '';

      let url = data.url;

      if (url.includes('#/share/')) {
        // console.log("inside here==>", url);
        const urlObj = new URL(url);
        const fragment = urlObj.hash.substr(2);
        const [, name, id] = fragment.split('/');
        let contentType = name
        let contentId = id
        let myParams = {
          content_type: contentType || '',
          content_id: contentId || 0
        };

        // console.log("myParams=======>", JSON.stringify(myParams));

        if (myParams.content_type == 'survey') {
          myParams.content_id != 0
            ? navigate(`/SPQdetails?id=${myParams.content_id}`)
            : navigate('/SPQlanding');
        } else if (myParams.content_type == 'feed') {
          myParams.content_id != 0
            ? navigate(`/MedwikiDetails?id=${myParams.content_id}`)
            : navigate('/Medwiki');
        } else if (myParams.content_type == 'gr') {
          myParams.content_id != 0
            ? navigate(`/GrandRoundDetails?id=${myParams.content_id}`)
            : navigate('/GrandRound');
        } else if (myParams.content_type == 'video_archive') {
          myParams.content_id != 0
            ? navigate(`/ClinicalVideoDetails?id=${myParams.content_id}`)
            : navigate('/ClinicalVideoLanding');
        } else if (myParams.content_type == 'channel') {
          myParams.content_id != 0
            ? navigate(`/ChannelProfile?id=${myParams.content_id}`)
            : navigate('/Channel');
        } else if (myParams.content_type == 'epub') {
          if (myParams.content_id == 0) {
            navigate(`/Ebook`);
          } else {
            navigate(`/EbookDetails?id=${myParams.content_id}`);
          }
        } else if (myParams.content_type == 'training') {
          if (myParams.content_id == 0) {
            navigate(`/TrainingLanding`);
          } else {
            navigate(`/TrainingCover?id=${myParams.content_id}`);
          }
        } else if (myParams.content_type == 'session') {
          myParams.content_id != 0
            ? navigate(`/SessionDetails?id=${myParams.content_id}`)
            : navigate('/Sessions');
        } else if (myParams.content_type == 'live') {
          myParams.content_id != 0
            ? navigate(`/LiveSession?id=${myParams.content_id}`)
            : navigate('/Sessions');
        } else if (myParams.content_type == 'profile') {
          navigate(`/UserProfile`);
        } else if (myParams.content_type == 'certificate') {
          if (myParams.content_id == 0) {
            navigate(`/CertificatesListing`);
          } else {
            navigate(`/CertificateDetails?id=${myParams.content_id}`);
          }
        } else if (myParams.content_type == 'election') {
          navigate(`/ElectionLanding?electionId=${myParams.content_id}`);
        } else if (myParams.content_type == 'connect') {
          params?.content_id != 0
            ? navigate(`/SpeakerProfile?id=${myParams.content_id}&active=true`)
            : navigate('/AllSessionList?type=master_doctors');
        } else if (myParams.content_type == 'dashboard') {
          navigate(`/Dashboard`);
        } else if (myParams.content_type == 'doctube') {
          navigate(`/Doctube`);
        } else if (myParams.content_type == 'cpd') {
          navigate(`/CpdReview`);
        }
        // else if (myParams.content_type == 'claim_certificate') {
        //     if (claimedCertificateId && claimedCertificateId != 0) {
        //         navigate(`/CertificateDetails?id=${claimedCertificateId}`);
        //     } else {
        //         navigate(`/CertificatesListing`);
        //     }
        // }
        else if (myParams.content_type == 'differential_diagnosis') {
          if (!myParams.content_id || myParams.content_id == 0) {
            navigate(`/DifferentialDiagnosis`);
          } else {
            navigate(`/DifferentialDiagnosis?id=${myParams.content_id}`);
          }
        } else {
          navigate(`/Dashboard`);
        }


      }
      else {
        console.log("here inside else");
        let urlObject = new URL(url);
        // console.log("myUri", JSON.stringify(urlObject));
        const searchParams = urlObject.searchParams;
        // console.log("searchParams", JSON.stringify(searchParams));
        searchParams.forEach((value, key) => {
          if (!isNaN(value)) {
            content_type = key;
            content_id = value;
          }
        });
        let params = {
          content_type: content_type || '',
          content_id: content_id || 0
        };

        // console.log("params=======>", JSON.stringify(params));

        if (params.content_type == 'survey') {
          params.content_id != 0
            ? navigate(`/SPQdetails?id=${params.content_id}`)
            : navigate('/SPQlanding');
        } else if (params.content_type == 'feed') {
          params.content_id != 0
            ? navigate(`/MedwikiDetails?id=${params.content_id}`)
            : navigate('/Medwiki');
        } else if (params.content_type == 'gr') {
          params.content_id != 0
            ? navigate(`/GrandRoundDetails?id=${params.content_id}`)
            : navigate('/GrandRound');
        } else if (params.content_type == 'video_archive') {
          params.content_id != 0
            ? navigate(`/ClinicalVideoDetails?id=${params.content_id}`)
            : navigate('/ClinicalVideoLanding');
        } else if (params.content_type == 'channel') {
          params.content_id != 0
            ? navigate(`/ChannelProfile?id=${params.content_id}`)
            : navigate('/Channel');
        } else if (params.content_type == 'epub') {
          if (params.content_id == 0) {
            navigate(`/Ebook`);
          } else {
            navigate(`/EbookDetails?id=${params.content_id}`);
          }
        } else if (params.content_type == 'training') {
          if (params.content_id == 0) {
            navigate(`/TrainingLanding`);
          } else {
            navigate(`/TrainingCover?id=${params.content_id}`);
          }
        } else if (params.content_type == 'session') {
          params.content_id != 0
            ? navigate(`/SessionDetails?id=${params.content_id}`)
            : navigate('/Sessions');
        } else if (params.content_type == 'live') {
          params.content_id != 0
            ? navigate(`/LiveSession?id=${params.content_id}`)
            : navigate('/Sessions');
        } else if (params.content_type == 'profile') {
          navigate(`/UserProfile`);
        } else if (params.content_type == 'certificate') {
          if (params.content_id == 0) {
            navigate(`/CertificatesListing`);
          } else {
            navigate(`/CertificateDetails?id=${params.content_id}`);
          }
        } else if (params.content_type == 'election') {
          navigate(`/ElectionLanding?electionId=${params.content_id}`);
        } else if (params.content_type == 'connect') {
          params?.content_id != 0
            ? navigate(`/SpeakerProfile?id=${params.content_id}&active=true`)
            : navigate('/AllSessionList?type=master_doctors');
        } else if (params.content_type == 'dashboard') {
          navigate(`/Dashboard`);
        } else if (params.content_type == 'doctube') {
          navigate(`/Doctube`);
        } else if (params.content_type == 'cpd') {
          navigate(`/CpdReview`);
        }
        // else if (params.content_type == 'claim_certificate') {
        //     if (claimedCertificateId && claimedCertificateId != 0) {
        //         navigate(`/CertificateDetails?id=${claimedCertificateId}`);
        //     } else {
        //         navigate(`/CertificatesListing`);
        //     }
        // }
        else if (params.content_type == 'differential_diagnosis') {
          if (!params.content_id || params.content_id == 0) {
            navigate(`/DifferentialDiagnosis`);
          } else {
            navigate(`/DifferentialDiagnosis?id=${params.content_id}`);
          }
        } else {
          navigate(`/Dashboard`);
        }
      }



      setTimeout(() => {
        window.location.reload();
      }, 0);
    });
    // Auto app update using CapGo

    App.addListener('appStateChange', (state) => {
      // console.log("inside listener", JSON.stringify(state));
      // checkAppVersion(state);
    });
  };

  const removedCapacitorListeners = () => {
    App.removeAllListeners();
  };

  React.useEffect(() => {
    addCapacitorListeners();
    return () => {
      removedCapacitorListeners();
    };
  }, [location]);

  return (
    <ConfirmationModal
      title={'App Update'}
      message={UpdateTitle && UpdateTitle}
      handleClose={onClose}
      open={openModal}
      onConfirm={confirmClick}
      onCancel={cancleClick}
    ></ConfirmationModal>
  );
};

export default MobileNativeEventsListener;
