// import React from 'react';
// import Placeholder from 'react-bootstrap/Placeholder';
// export function Skeleton({ variant, width, height, className = '' }) {
//   const getLastChar = (str) => {
//     if (typeof str !== 'string') return '';
//     return str.charAt(str.length - 1);
//   };
//   const styles = {
//     width: getLastChar(width) === '%' ? width : `${width}px`,
//     height: getLastChar(height) === '%' ? height : `${height}px`
//   };
//   const radiusClassname =
//     variant === 'circular' ? 'rounded-circle' : variant == 'text' ? 'rounded' : '';
//   return (
//     <Placeholder
//       as={'div'}
//       className={className + (radiusClassname ? ' ' + radiusClassname : '')}
//       animation="glow"
//     >
//       <Placeholder style={styles} />
//     </Placeholder>
//   );
// }
import React from 'react';
import { Skeleton as Skull } from '@mui/material';
export function Skeleton({ variant, width, height, className = '' }) {
  return <Skull variant={variant} width={width} height={height} className={className ?? ''} />;
}
