import { registerConstants } from '../constants/registrationConstants';

export const registrationReducer = (state = {}, action) => {
  switch (action.type) {
    case registerConstants.REGISTRATION_SUCCESS:
      return {
        ...state,
        data: action.payload
      };

    case registerConstants.REGISTRATION_FAILURE:
      return {
        ...state,
        error: action.payload.error
      };

    case registerConstants.GET_ALL_SPECIALITY_SUCCESS:
      return {
        ...state,
        data: action.payload.data
      };

    case registerConstants.GET_ALL_SPECIALITY_FALIURE:
      return {
        ...state,
        error: action.payload.message
      };

    default:
      return state;
  }
};
