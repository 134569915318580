import axios from 'axios';
import { FULL_SEARCH, GET_AUTO_SUGGESTION } from '../QueryKeys/search.key';
import { useQuery } from 'react-query';
import { default_images } from '../../common/defaultImages';
import axiosInstance from '../../Store/helper/axiosInstance';
import appConfig from '../../config/config';
const cacheTime = 60000;
const staleTime = 30000;
export const useGetAutoSuggestion = (query = '', enabled, onSuccess, onError) => {
  return useQuery(
    [GET_AUTO_SUGGESTION, query],
    () =>
      axios.post(appConfig.apiLoc + 'auto_suggest', {
        query
      }),
    {
      onSuccess,
      onError,
      enabled,
      select: (response) => {
        let output = response.data?.output;
        let toProceed =
          output && typeof output === 'object' && Array.isArray(output) && output?.length > 0;

        return toProceed ? output : [];
      },
      staleTime: staleTime,
      cacheTime: cacheTime,
      refetchOnWindowFocus: false
    }
  );
};

const SearchFn = async (query = '', specialities = '', isEmbedded = false) => {
  const apiUrl = isEmbedded ? 'datanew' : specialities ? 'data' : 'datanew';
  return axiosInstance.get(
    `search/${apiUrl}?from=0&to=100&type=&val=${
      query ? (isEmbedded ? 'keyword:' + query : query) : ''
    }&specialities=${specialities || ''}`
  );
};

export const useSearch = (
  searchOptions = {
    query: '',
    specialities: '',
    isEmbedded: false
  },
  enabled,
  onSuccess,
  onError
) => {
  let { query = '', specialities = '', isEmbedded = false } = searchOptions;
  return useQuery(
    [FULL_SEARCH, query, specialities, isEmbedded],
    () => SearchFn(query, specialities, isEmbedded),
    {
      enabled,
      onSuccess,
      onError,
      select: (response) => {
        return response.data?.data;
        // if (isEmbedded) {
        //   let output = response.data?.output;
        //   let toProceed =
        //     output && typeof output === 'object' && Array.isArray(output) && output?.length > 0;

        //   let processedData = toProceed
        //     ? output?.map((item) => {
        //         if (item?.content === 'medwiki') {
        //           return {
        //             trending_type: 'comp',
        //             type: 'comp',
        //             con_type: 'text',
        //             type_id: item?.content_id,

        //             question: item?.title,
        //             image: default_images.medwiki,

        //             answer: '',
        //             vault: 0,
        //             specialities: '',
        //             specialities_ids_and_names: item?.speciality
        //               ?.split(',')
        //               ?.map((s) => ({ id: 0, name: s }))
        //           };
        //         }
        //       })
        //     : [];
        //   return processedData;
        // } else {
        //   return response.data?.data;
        // }
      },
      staleTime: staleTime,
      cacheTime: cacheTime,
      refetchOnWindowFocus: false
    }
  );
};
