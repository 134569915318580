import { Navbar, Image } from 'react-bootstrap';
import React, { useEffect, useState, useRef, useMemo } from 'react';
import 'swiper/css';
import useRedirect from '../../../customHooks/useRedirect';
import { useDispatch, useSelector } from 'react-redux';
import DeleteConfirmation from '../../../modals/DeleteConfirmation';
import { ClirnetIconLogo } from '../../../icons';
import { getLocalStorage, reactHtmlParser, setLocalStorage } from '../../../../common/common';
import { updateUserViewSpeciality } from '../../../../Store/actions/dashboard.action';

import { changeRoute, changeTheme } from '../../../../Store/actions/settings.action';
import { useLocation } from 'react-router-dom';
import LazyImage from '../../../CustomLibraries/LazyImage';
import JoyRide from '../../../reactJoyRide/JoyRide';
import { changeExploreKey } from '../../../../Store/actions';

import { DEFAULT_MENU } from '../../../../Store/constants/app.constants';
import { Skeleton } from '../../../../skeleton/Skeleton';

const HeaderSpecialityDesktop = ({ getElementHeight, elHeight }) => {
  const { redirectTo } = useRedirect();
  const dispatch = useDispatch();
  const prevScrollPos = useRef(window.pageYOffset);
  const headerSecRef = useRef(0);
  const getHeight = useRef(0);
  const returnToSpecRef = useRef(0);
  const [headerTransform, setHeaderTransform] = useState(0);
  const [menuJson, setMenuJson] = useState('');
  const { pathname } = useLocation();
  const [specialityDataShowing, setSpecialityDataShowing] = useState('');
  const userSpeciality = JSON.parse(useSelector((state) => state.common.user_details.speciality));

  // const [userSpeciality, setUserSpeciality] = useState(
  //   getLocalStorage('user', false) ? JSON.parse(getLocalStorage('user', {})?.speciality) : ''
  // );

  const [{ deletionType, deletionModalShow }, setDeletionConfirmation] = useState({
    deletionType: '',
    deletionModalShow: false
  });
  const userDetails = useSelector((state) => state.common.user_details);
  const showingSpecData = useSelector((state) => state.dashboard.user_speciality_viewing);
  const exploreKey = useSelector((state) => state.common.exploreKey);

  const handleScroll = () => {
    const currentScrollPos = window?.pageYOffset;
    const headerHeight = headerSecRef?.current.clientHeight;
    if (prevScrollPos?.current > currentScrollPos) {
      // alert('Scroll up detected!', headerHeight);
      if (headerHeight <= 0) {
        setHeaderTransform(headerHeight);
      }
    } else {
      setHeaderTransform(0);
      // alert('Scroll down detected!');
    }
    prevScrollPos.current = currentScrollPos;
  };

  useEffect(() => {
    getElementHeight(getHeight.current.offsetHeight);
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    getElementHeight(getHeight.current.offsetHeight);
  }, [getHeight.current.offsetHeight]);

  useEffect(() => {
    if (showingSpecData && showingSpecData?.length > 0) {
      setSpecialityDataShowing(showingSpecData);
    }
  }, [showingSpecData]);

  useEffect(() => {
    if (userDetails && userDetails?.menu) {
      if (
        'all_menu' in JSON.parse(userDetails?.menu) &&
        JSON.parse(userDetails.menu).all_menu.length > 0
      )
        setMenuJson(JSON.parse(userDetails.menu));
      else setMenuJson(DEFAULT_MENU);
    }
  }, [userDetails]);

  const onGroupClick = () => {
    dispatch(changeExploreKey(null));

    redirectTo('Channel');
  };
  const onDoctubeClick = () => {
    dispatch(changeExploreKey(null));

    redirectTo('Doctube');
  };
  const onDashboardClick = () => {
    dispatch(changeExploreKey(null));
    redirectTo('Dashboard');
  };
  // const onExploreClick = () => {};
  const onSearchClick = () => {
    dispatch(changeExploreKey(null));

    redirectTo('SearchResult');
  };
  const onNotificationClick = () => {
    dispatch(changeExploreKey(null));

    redirectTo('Notification');
  };
  const logout = (type) => {
    if (type == 'logout') {
      window.location.href = '/';
      localStorage.clear();
      sessionStorage.clear();
      // dispatch(changeRoute(true));
      // dispatch(logoutUser());
      // dispatch(resetStore());
    }
  };
  const UserRegion = useSelector((state) => state.share.UserRegion);

  const redirectToDashboard = () => {
    dispatch(changeExploreKey(null));
    redirectTo('Dashboard');
  };

  const onChangeSpecialityClick = () => {
    console.log('onChangeSpecialityClick');
    redirectTo('SelectSpeciality');
  };

  const onMenuClick = (pathname, name, dontSetExploreKey = false) => {
    //  remove / from pathname
    if (!dontSetExploreKey) {
      dispatch(changeExploreKey(name));
    } else {
      dispatch(changeExploreKey(null));
    }

    let redirectPath = pathname.replace('/', '');
    console.log('onMenuClick', redirectPath);
    redirectTo(redirectPath);
  };

  const onReturnToSpecClick = () => {
    dispatch(updateUserViewSpeciality(userSpeciality));
    setLocalStorage('selectedSpeciality', userSpeciality[0]);
    // dispatch(changeTheme(userSpeciality?.specialities_name ?? ''));
    // document.body.setAttribute('data-bs-theme', userSpeciality?.specialities_name ?? '');
  };

  const isInDashboard = useMemo(() => {
    return pathname.toLowerCase() == '/dashboard';
  }, [pathname]);

  const current_menu = useSelector((state) => state.common.current_menu);
  const [currentMenu, setCurrentMenu] = useState('');

  useEffect(() => {
    if (current_menu?.toLowerCase()?.includes('dashboard')) {
      setCurrentMenu('dashboard');
    } else if (current_menu?.toLowerCase()?.includes('session')) {
      setCurrentMenu('session');
    } else if (current_menu?.toLowerCase()?.includes('medwiki')) {
      setCurrentMenu('medwiki');
    } else if (current_menu?.toLowerCase()?.includes('spq')) {
      setCurrentMenu('spq');
    } else if (current_menu?.toLowerCase()?.includes('clinicalvideo')) {
      setCurrentMenu('clinical');
    } else if (current_menu?.toLowerCase()?.includes('gr')) {
      setCurrentMenu('grandround');
    } else if (current_menu?.toLowerCase()?.includes('ebook')) {
      setCurrentMenu('ebook');
    } else if (current_menu?.toLowerCase()?.includes('channel')) {
      setCurrentMenu('channel');
    } else if (current_menu?.toLowerCase()?.includes('telemed')) {
      setCurrentMenu('telemed');
    } else if (current_menu?.toLowerCase()?.includes('discussrefer')) {
      setCurrentMenu('discussrefer');
    } else if (current_menu?.toLowerCase()?.includes('training')) {
      setCurrentMenu('training');
    } else if (current_menu?.toLowerCase()?.includes('doctube')) {
      setCurrentMenu('doctube');
    } else if (current_menu?.toLowerCase()?.includes('speciality')) {
      setCurrentMenu('speciality');
    } else if (current_menu?.toLowerCase()?.includes('diagnosis')) {
      setCurrentMenu('diagnosis');
    }
  }, [current_menu]);

  function getActiveClass(path) {
    if (currentMenu !== '') {
      if (
        currentMenu.includes(path.toLowerCase()) ||
        path.toLowerCase().includes(currentMenu.toLowerCase())
      ) {
        return 'active';
      }
    }
  }

  useEffect(() => {
    const specialityArray = [];
    const selectedSpeciality = getLocalStorage('selectedSpeciality', null);
    if (selectedSpeciality) {
      specialityArray.push(selectedSpeciality);
      setSpecialityDataShowing(specialityArray);
    } else {
      setSpecialityDataShowing(
        getLocalStorage('user', false) ? JSON.parse(getLocalStorage('user', {})?.speciality) : ''
      );
    }
  }, []);

  return (
    <Navbar
      className="clr_v3_Header position-fixed navbar py-0 px-4 m-0 top-0 bg-white w-100 rounded-0 flex-column  clr_v3_Header--desktop topHeader py-lg-0 py-3"
      expand="lg"
      style={{ transform: `translateY(-${headerTransform}px)` }}
      ref={getHeight}
      id="headerDesktopNavBar"
    >
      <JoyRide />
      <div className="position-relative w-100" ref={headerSecRef}>
        <div className="clr_v3_Header__navbar position-relative pb-0">
          {console.log(
            'niufiubdfbdfb',
            userSpeciality[0]?.master_specialities_id,
            specialityDataShowing[0]?.master_specialities_id
          )}
          {isInDashboard &&
            userSpeciality?.length > 0 &&
            specialityDataShowing?.length > 0 &&
            userSpeciality[0]?.master_specialities_id !=
              specialityDataShowing[0]?.master_specialities_id && (
              <div
                ref={returnToSpecRef}
                className="px-3 bg-primary mx-n4 py-2 text-black position-relative z-2 d-flex gap-2 justify-content-center align-items-center bg-opacity-25 clr_v3_Header__outside_Speciality"
                onClick={onReturnToSpecClick}
              >
                <h2 className="fs-5">Viewing content outside your Speciality.</h2>
                <a
                  href="javascript:void(0)"
                  className="fw-semibold d-inline-flex gap-1 fs-5 align-items-center"
                >
                  Return to my Speciality<i className="flaticon-chevron"></i>
                </a>
              </div>
            )}
          <nav className="position-relative z-2 text-white clr_v3_Header__nav">
            <div className="row align-items-center justify-content-between">
              <div className="d-flex align-items-center col-3 flex-grow-0 gap-20">
                <Navbar.Toggle aria-controls="clr_v3_headerCollapse" />
                {/* <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#clr_v3_headerCollapse" aria-controls="clr_v3_headerCollapse" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button> */}
                <div
                  onClick={redirectToDashboard}
                  className="clr_v3_Header__brand flex-shrink-0 bg-light d-flex align-items-center justify-content-center rounded-3 p-3 cursorPointer "
                  id="company_logo"
                >
                  <ClirnetIconLogo />
                </div>

                {isInDashboard && specialityDataShowing && specialityDataShowing?.length > 0 && (
                  <a
                    href="javascript:void(0)"
                    onClick={onChangeSpecialityClick}
                    className="fs-4 btn btn-outline-primary rounded-3 py-3 d-inline-flex align-content-center jusstify-content-center gap-2 clr_v3_Header__speciality__btn"
                    id="change-speciality-button"
                  >
                    <i className="flaticon-transfer-data d-inline-block align-middle fs-2"></i>
                    <span className="d-inline-block text-truncate">
                      {specialityDataShowing[0]?.specialities_name}
                    </span>
                  </a>
                )}
              </div>
              <div className="col px-20 d-flex justify-content-center">
                {/* <div className='clr_v3_Header__menu-link collapse navbar-collapse h-100 flex-grow-0' id='clr_v3_headerCollapse'> */}
                <Navbar.Collapse
                  className="clr_v3_Header__menu-link h-100 flex-grow-0"
                  id="clr_v3_headerCollapse"
                >
                  <ul className="d-flex p-0 align-items-center list-unstyled fs-16 mb-0 gap-5 ">
                    {menuJson &&
                      menuJson?.static_menu &&
                      menuJson?.static_menu.map((elem, key) => (
                        <li className={` ${getActiveClass(elem.url)}`} key={key}>
                          <a
                            href="javascript:void(0)"
                            className={`d-flex gap-2 align-items-center`}
                            // onClick={onGroupClick}
                            onClick={() => onMenuClick(elem.url, elem['short-name'], true)}
                          >
                            <div
                              className="clr_v3_Header__menu-link__nav-link__icon d-flex align-items-center justify-content-center"
                              dangerouslySetInnerHTML={{ __html: elem.icon }}
                            />
                            <span>{reactHtmlParser(elem.title)}</span>
                          </a>
                        </li>
                      ))}

                    <li className="position-relative">
                      <a
                        href="javascript:void(0)"
                        className="d-flex gap-2 align-items-center"
                        id="explore-menu"
                      >
                        {/* onClick={onExploreClick} */}
                        <div className="clr_v3_Header__menu-link__nav-link__icon d-flex align-items-center justify-content-center">
                          <i className="flaticon-other"></i>
                        </div>
                        {exploreKey && (
                          <>
                            <span class="badge bg-light text-black rounded-pill position-absolute translate-middle-x start-50 fw-semibold fs-6 exploretag">
                              {reactHtmlParser(exploreKey)}
                            </span>
                          </>
                        )}

                        <span>Explore</span>

                        <i className="flaticon-down-arrow"></i>
                      </a>
                      <ul
                        className="position-fixed translate-middle-x start-50 clr_v3_Header__exploreDesktop bg-white p-3 flex-wrap row-cols-3 d-flex"
                        style={{ paddingTop: `${elHeight}px` }}
                      >
                        {menuJson.all_menu?.length > 0 &&
                          menuJson?.all_menu?.map((key, ind) => {
                            if (
                              key?.url?.toLowerCase() === 'dashboard' ||
                              key?.url?.toLowerCase() === 'doctube' ||
                              key?.url?.toLowerCase() === 'channel'
                            )
                              return <></>;
                            else
                              return (
                                <li
                                  className="p-2"
                                  onClick={() => onMenuClick(key.url, key['short-name'])}
                                  key={ind}
                                >
                                  <a
                                    href="javascript:void(0)"
                                    className={`rounded-3 p-3 bg-primary d-flex gap-4 align-items-center ${getActiveClass(
                                      key.url
                                    )}`}
                                  >
                                    {key.icon && (
                                      <div className="py-2 bg-primary rounded-2 transition clr_v3_exploreDesktop__menuIcon">
                                        <LazyImage
                                          extraClass="d-flex align-items-center justify-content-center w-100 h-100"
                                          alt={key.title}
                                          src={key.icon}
                                        />
                                      </div>
                                    )}
                                    <div className="justify-content-between d-flex flex-column text-black clr_v3_exploreDesktop__menuNumber">
                                      {/* {key.count && <span className="mb-1">{key.count}</span>} */}
                                      {key.title && (
                                        <span className="fw-semibold text-black">
                                          {reactHtmlParser(key.title)}
                                        </span>
                                      )}
                                    </div>
                                  </a>
                                </li>
                              );
                          })}
                      </ul>
                    </li>
                  </ul>
                </Navbar.Collapse>
                {/* </div> */}
              </div>

              <div className="clr_v3_Header__right col-3 d-flex justify-content-end">
                <ul className="d-flex px-1 align-items-center list-unstyled fs-1 mb-0 bg-light rounded-pill gap-3">
                  <li>
                    <a href="javascript:void(0)" onClick={onSearchClick}>
                      <i className="flaticon-loupe fs-2"></i>
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" onClick={onNotificationClick}>
                      <i className="flaticon-notification fs-2"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      onClick={() =>
                        setDeletionConfirmation({
                          deletionType: 'logout',
                          deletionModalShow: true
                        })
                      }
                    >
                      <i className="flaticon-logout fs-2"></i>
                    </a>
                  </li>
                  {/* profile image start */}
                  {/* <li className="ms-4">
                  <div className="rounded-circle p-33 border border-white border-105 bg-transparent">
                    <div className="overflow-hidden border border-white clr_v3_userImage rounded-circle">
                      <img
                        src="https://img-cdn.clirnet.com/medwiki/43_server/video/1637400385_b1e9f19A39BB66b161489FaE84.jpeg?tr=w-264,h-264,pr=true,c-at_max"
                        width=""
                        height=""
                        alt="Dr. Tarun Grover"
                        className="object-fit-cover w-100 h-100 float-start"
                      />
                    </div>
                  </div>
                </li> */}
                  {/* profile image end */}
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
      {deletionType && (
        <DeleteConfirmation
          title={`Are you sure to log out ?`}
          type={deletionType}
          confirm={deletionModalShow}
          handleClose={() =>
            setDeletionConfirmation({ deletionType: '', deletionModalShow: false })
          }
          confirmDeletion={logout}
        />
      )}
    </Navbar>
  );
};

export default HeaderSpecialityDesktop;
