import React, { lazy } from 'react';
import DefaultLayout from '../../layout/DefaultLayout';
import DetailsPageLayout from '../../layout/DetailsPageLayout';
import SpecialityLayoutOldPage from '../../layout/SpecialityLayoutOldPage';
import SpecialityDetailsLayout from '../../layout/SpecialityDetailsLayout';
import SpecialityLayout from '../../layout/SpecialityLayout';
import SpecialityDefaultLayout from '../../layout/uiv3/SpecialityDefaultLayout';
import { Row } from 'react-bootstrap';
import Loadable from 'react-loadable';
import LoadingFallback from '../fallback/LoadingFallbackNew';

const Dashboard = Loadable({
  loader: () => import('../../../src/pages/dashboard/Dashboard'),
  loading: LoadingFallback
});
const EbookListing = Loadable({
  loader: () => import('../../../src/pages/ebook/EbookListing'),
  loading: LoadingFallback
});
const EbookDetails = Loadable({
  loader: () => import('../../../src/pages/ebook/EbookDetails'),
  loading: LoadingFallback
});
const EpaperDetails = Loadable({
  loader: () => import('../../../src/pages/uiv3/epaper/EpaperDetails'),
  loading: LoadingFallback
});
const Vault = Loadable({
  loader: () => import('../../../src/pages/vault/Vault'),
  loading: LoadingFallback
});

const HelpAndSupport = Loadable({
  loader: () => import('../../../src/pages/helpAndSupport/HelpAndSupport'),
  loading: LoadingFallback
});
const MedWikiListing = Loadable({
  loader: () => import('../../../src/pages/medwiki/MedwikiListing'),
  loading: LoadingFallback
});
const MedwikiPopular = Loadable({
  loader: () => import('../../../src/pages/medwikiV2/MedwikiLandingComponents/MedwikiPopular'),
  loading: LoadingFallback
});

const CpdReview = Loadable({
  loader: () => import('../../../src/pages/userProfile/CpdReview'),
  loading: LoadingFallback
});

const GrandRoundListing = Loadable({
  loader: () => import('../../../src/pages/grandRounds/GrandRoundListing'),
  loading: LoadingFallback
});

const DiscussRefer = Loadable({
  loader: () => import('../../../src/pages/discussRefer/DiscussRefer'),
  loading: LoadingFallback
});
const HospitalProfile = Loadable({
  loader: () => import('../../../src/pages/discussRefer/hospitalProfile'),
  loading: LoadingFallback
});
const DiscussForm = Loadable({
  loader: () => import('../../../src/pages/discussRefer/DiscussForm'),
  loading: LoadingFallback
});
const CaseDetails = Loadable({
  loader: () => import('../../../src/pages/discussRefer/CaseDetails'),
  loading: LoadingFallback
});
const ReInitiateRefer = Loadable({
  loader: () => import('../../../src/pages/discussRefer/ReInitiateRefer'),
  loading: LoadingFallback
});
const Activities = Loadable({
  loader: () => import('../../../src/pages/discussRefer/Activities'),
  loading: LoadingFallback
});
const ClinicalVideoList = Loadable({
  loader: () => import('../../../src/pages/clinicalVideo/ClinicalVideoList'),
  loading: LoadingFallback
});
const ClinicalVideoDetails = Loadable({
  loader: () => import('../../../src/pages/clinicalVideo/ClinicalDetails'),
  loading: LoadingFallback
});
const ClinicalVideoDetailsV2 = Loadable({
  loader: () => import('../../../src/pages/uiv3/clinicalVideo/ClinicalVideoDetails'),
  loading: LoadingFallback
});
const ChannelLanding = Loadable({
  loader: () => import('../../../src/pages/channel/ChannelLanding'),
  loading: LoadingFallback
});
const ChannelManage = Loadable({
  loader: () => import('../../../src/pages/channel/ChannelManage'),
  loading: LoadingFallback
});
const ChannelProfile = Loadable({
  loader: () => import('../../../src/pages/channel/ChannelProfile'),
  loading: LoadingFallback
});
const TopRatedMedwikiListing = Loadable({
  loader: () => import('../../../src/pages/medwikiV2/TopRatedMedwikiListing'),
  loading: LoadingFallback
});
const ChannelSuggestion = Loadable({
  loader: () => import('../../../src/pages/channel/ChannelSugesstion'),
  loading: LoadingFallback
});
const ChannelLandingV2 = Loadable({
  loader: () => import('../../../src/pages/channel/ChannelLandingV2'),
  loading: LoadingFallback
});
const ChannelProfileV2 = Loadable({
  loader: () => import('../../../src/pages/uiv3/channel/ChannelProfile'),
  loading: LoadingFallback
});

const ReferThankYou = Loadable({
  loader: () => import('../../../src/pages/discussRefer/ReferThankyou'),
  loading: LoadingFallback
});
const SPQ = Loadable({
  loader: () => import('../../../src/pages/spq/spq'),
  loading: LoadingFallback
});
const UserPoint = Loadable({
  loader: () => import('../../../src/pages/spq/UserPoint'),
  loading: LoadingFallback
});
const SPQreview = Loadable({
  loader: () => import('../../../src/pages/spq/SPQreview'),
  loading: LoadingFallback
});

const SessionListing = Loadable({
  loader: () => import('../../../src/pages/session/SessionListing'),
  loading: LoadingFallback
});
const SessionWaitingRoom = Loadable({
  loader: () => import('../../../src/pages/session/SessionWaitingRoom'),
  loading: LoadingFallback
});

const AllSessionList = Loadable({
  loader: () => import('../../../src/pages/session/AllSessionList'),
  loading: LoadingFallback
});
const LiveSession = Loadable({
  loader: () => import('../../../src/pages/session/LiveSession'),
  loading: LoadingFallback
});
const ElectionLandinng = Loadable({
  loader: () => import('../../../src/pages/election/ElectionLanding'),
  loading: LoadingFallback
});
const CandidateSelection = Loadable({
  loader: () => import('../../../src/pages/election/CandidateSelection'),
  loading: LoadingFallback
});
const VotingSummary = Loadable({
  loader: () => import('../../../src/pages/election/VotingSummary'),
  loading: LoadingFallback
});
const MobileVerification = Loadable({
  loader: () => import('../../../src/pages/election/MobileVerification'),
  loading: LoadingFallback
});

const Notification = Loadable({
  loader: () => import('../../../src/pages/notification/Notification'),
  loading: LoadingFallback
});
const DoctubeLanding = Loadable({
  loader: () => import('../../../src/pages/doctubeLanding/DoctubeLanding'),
  loading: LoadingFallback
});

const DoctubeListingVideos = Loadable({
  loader: () =>
    import(
      '../../../src/pages/doctube/doctubeProfileManagement/components/listing/DoctubeListingVideos'
    ),
  loading: LoadingFallback
});
const ProfileSteps = Loadable({
  loader: () => import('../../../src/pages/doctube/doctubeProfileManagement/ProfileSteps'),
  loading: LoadingFallback
});
const DoctubeDashboard = Loadable({
  loader: () => import('../../../src/pages/doctube/doctubeProfileManagement/DoctubeDashboard'),
  loading: LoadingFallback
});
const DoctubeVideoUpload = Loadable({
  loader: () => import('../../../src/pages/doctube/doctubeProfileManagement/DoctubeVideoUpload'),
  loading: LoadingFallback
});
const DoctubeManagePlaylist = Loadable({
  loader: () => import('../../../src/pages/doctube/doctubeProfileManagement/DoctubeManagePlaylist'),
  loading: LoadingFallback
});

const ShareLoginNew = Loadable({
  loader: () => import('../../../src/pages/shareLogin/ShareLoginNew'),
  loading: LoadingFallback
});

const ClinicalVideoLanding = Loadable({
  loader: () => import('../../../src/pages/clinicalVideo/ClinicalVideoLanding'),
  loading: LoadingFallback
});

const SearchResult = Loadable({
  loader: () => import('../../../src/pages/Search/SearchResult'),
  loading: LoadingFallback
});
const SearchResultV2 = Loadable({
  loader: () => import('../../../src/pages/Search/SearchV3'),
  loading: LoadingFallback
});
const SearchList = Loadable({
  loader: () => import('../../../src/pages/Search/SearchList'),
  loading: LoadingFallback
});
const TelemedLite = Loadable({
  loader: () => import('../../../src/pages/telemedLite/TelemedLite'),
  loading: LoadingFallback
});

const Unavailable = Loadable({
  loader: () => import('../../../src/pages/unavailable/Unavailable'),
  loading: LoadingFallback
});

const UploadCertificate = Loadable({
  loader: () => import('../../../src/pages/certificates/UploadCertificate'),
  loading: LoadingFallback
});
const CertificatesListing = Loadable({
  loader: () => import('../../../src/pages/certificates/CertificatesListing'),
  loading: LoadingFallback
});

const ProfileVideos = Loadable({
  loader: () => import('../../../src/pages/userProfile/ProfileVideos'),
  loading: LoadingFallback
});

const GRlanding = Loadable({
  loader: () => import('../../../src/pages/grTraining/TrainingLanding'),
  loading: LoadingFallback
});
const GRlisting = Loadable({
  loader: () => import('../../../src/pages/grTraining/TrainingListing'),
  loading: LoadingFallback
});
const GRcover = Loadable({
  loader: () => import('../../../src/pages/grTraining/TrainingCover'),
  loading: LoadingFallback
});
const GRcontent = Loadable({
  loader: () => import('../../../src/pages/grTraining/TrainingContent'),
  loading: LoadingFallback
});
const GRsummary = Loadable({
  loader: () => import('../../../src/pages/grTraining/TrainingSummary'),
  loading: LoadingFallback
});
const MedwikiLandingV2 = Loadable({
  loader: () => import('../../../src/pages/medwikiV2/MedwikiLandingV2'),
  loading: LoadingFallback
});
const Demo = Loadable({
  loader: () => import('../../../src/pages/Demo'),
  loading: LoadingFallback
});
const MedwikiDetails = Loadable({
  loader: () => import('../../../src/pages/uiv3/medwiki/MedwikiDetails'),
  loading: LoadingFallback
});
const MedwikiDetailsV2 = Loadable({
  loader: () => import('../../../src/pages/medwikiV2/MedwikiDetailsV2'),
  loading: LoadingFallback
});

const AdsPlayer = Loadable({
  loader: () => import('../../components/CustomLibraries/AdsPlayer'),
  loading: LoadingFallback
});

const PollDetails = Loadable({
  loader: () => import('../../../src/pages/spq/PollDetails'),
  loading: LoadingFallback
});

const CertificateDetails = Loadable({
  loader: () => import('../../../src/pages/certificates/CertificateDetails'),
  loading: LoadingFallback
});

const QueryListing = Loadable({
  loader: () => import('../../../src/pages/SpeakerProfile/QueryListing'),
  loading: LoadingFallback
});

const UpcomingSessionList = Loadable({
  loader: () => import('../../../src/pages/SpeakerProfile/UpcomingSessionsList'),
  loading: LoadingFallback
});

const PreviousSessionList = Loadable({
  loader: () => import('../../../src/pages/SpeakerProfile/PrviousSession'),
  loading: LoadingFallback
});

const SpeakerProfile = Loadable({
  loader: () => import('../../../src/pages/SpeakerProfile/SpeakerProfile'),
  loading: LoadingFallback
});

const SPQquestion = Loadable({
  loader: () => import('../../../src/pages/spq/SPQquestion'),
  loading: LoadingFallback
});

const SPQsubmitSuccessfully = Loadable({
  loader: () => import('../../../src/pages/spq/SPQsubmitSuccessfully'),
  loading: LoadingFallback
});

const MedwikiLanding = Loadable({
  loader: () => import('../../../src/pages/medwiki/MedwikiLanding'),
  loading: LoadingFallback
});

const SPQcompleted = Loadable({
  loader: () => import('../../../src/pages/spq/SPQcompleted'),
  loading: LoadingFallback
});
const SPQlanding = Loadable({
  loader: () => import('../../../src/pages/spq/SPQlanding'),
  loading: LoadingFallback
});

// const SessionLanding = Loadable(() => import("../../src/pages/session/SessionLanding"));
// const SessionDetails = Loadable({
//   loader: () => import('../../../src/pages/session/SessionDetails'),
//   loading: LoadingFallback
// });
const SessionDetailsOutlet = Loadable({
  loader: () => import('../../../src/pages/sessionOutlet/SessionDetailsOutlet'),
  loading: LoadingFallback
});
const LiveSessionOutlet = Loadable({
  loader: () => import('../../../src/pages/sessionOutlet/LiveSessionOutlet'),
  loading: LoadingFallback
});

const Photobooth = Loadable({
  loader: () => import('../../../src/pages/multidaySession/PhotoBooth'),
  loading: LoadingFallback
});
// const ConnectDetails = Loadable({
//   loader: () => import('../../src/pages/connect/ConnectDetails'),
//   loading: LoadingFallback
// });

const DoctubePlaylistDetails = Loadable({
  loader: () =>
    import('../../../src/pages/doctube/doctubeProfileManagement/DoctubePlaylistDetails'),
  loading: LoadingFallback
});

const GrandRoundDetails = Loadable({
  loader: () => import('../../../src/pages/grandRounds/GrandRoundDetails'),
  loading: LoadingFallback
});

const SPQdetails = Loadable({
  loader: () => import('../../../src/pages/spq/SPQdetails'),
  loading: LoadingFallback
});
const SpecialityDashboard = Loadable({
  loader: () => import('../../../src/pages/uiv3/dashboard/Dashboard'),
  loading: LoadingFallback
});

const Profile = Loadable({
  loader: () => import('../../../src/pages/uiv3/profile/Profile'),
  loading: LoadingFallback
});

const UserProfile = Loadable({
  loader: () => import('../../../src/pages/userProfile/UserProfileBkp.jsx'),
  loading: LoadingFallback
});
const Ddx = Loadable({
  loader: () => import('../../../src/pages/askClirnet/DiagnosisHelp'),
  loading: LoadingFallback
});

const MostReadContent = Loadable({
  loader: () => import('../../../src/pages/mostReadContent/MostReadContent'),
  loading: LoadingFallback
});

const SpecialityLanding = Loadable({
  loader: () => import('../../../src/pages/speciality/SpecialityLanding'),
  loading: LoadingFallback
});

const AllSpecialities = Loadable({
  loader: () => import('../../../src/pages/uiv3/AllSpeciality'),
  loading: LoadingFallback
});

const MostCommentedMedwikiListing = Loadable({
  loader: () => import('../../../src/pages/medwikiV2/MostCommentedMedwikiListing'),
  loading: LoadingFallback
});
const TagSearch = Loadable({
  loader: () => import('../../../src/pages/Search/TagSearch'),
  loading: LoadingFallback
});

export const layoutedRoutes = [
  {
    layout: <DefaultLayout />,
    SpecialityLayout: <SpecialityLayoutOldPage />,
    subpath: [
      {
        path: '/DifferentialDiagnosis',
        component: <Ddx />,
        module: 'aidda'
      },
      {
        path: '/Speciality',
        component: <SpecialityLanding />,
        module: 'speciality'
      },
      {
        path: '/MostRead',
        component: <MostReadContent />,
        specialityComponent: <MostReadContent />,
        module: 'speciality'
      },
      {
        path: '/Vault',
        component: <Vault />,
        module: 'speciality'
      },
      {
        path: '/HelpAndSupport',
        component: <HelpAndSupport />,
        module: 'generic'
      },
      {
        path: '/SearchResult',
        component: <SearchResultV2 />,
        module: 'generic'
      },
      {
        path: '/search/tag',
        component: <TagSearch />,
        module: 'generic'
      },
      {
        path: '/SearchList',
        component: <SearchList />,
        module: 'generic'
      },

      {
        path: '/UploadCertificate',
        component: <UploadCertificate />,
        module: 'generic'
      },
      {
        path: '/CertificatesListing',
        component: <CertificatesListing />,
        module: 'generic'
      },
      {
        path: '/CpdReview',
        component: <CpdReview />,
        module: 'generic'
      },
      {
        path: '/UpcomingSessionList',
        component: <UpcomingSessionList />,
        module: 'generic'
      },
      {
        path: '/PreviousSessionList',
        component: <PreviousSessionList />,
        module: 'generic'
      },
      {
        path: '/QueryListing',
        component: <QueryListing />,
        module: 'generic'
      },
      {
        path: '/SPQquestion',
        component: <SPQquestion />,
        module: 'spq'
      },
      {
        path: '/SPQreview',
        component: <SPQreview />,
        module: 'spq'
      },
      {
        path: '/SPQsubmitSuccessfully',
        component: <SPQsubmitSuccessfully />,
        module: 'spq'
      },
      {
        path: '/SPQperformed',
        component: <SPQcompleted />,
        module: 'spq'
      },
      {
        path: '/Sessions',
        component: <SessionListing />,
        module: 'session'
      },
      {
        path: '/AllSessionList',
        component: <AllSessionList />,
        module: 'session'
      },

      {
        path: '/Ebook',
        component: <EbookListing />,
        module: 'epub'
      },
      {
        path: '/MedwikiListing',
        component: <MedWikiListing />,
        module: 'medwiki'
      },
      {
        path: '/MostCommentedMedwikiListing',
        component: <MostCommentedMedwikiListing />,
        module: 'medwiki'
      },
      {
        path: '/MedwikiPopular',
        component: <MedwikiPopular />,
        module: 'medwiki'
      },
      {
        path: '/GrandRound',
        component: <GrandRoundListing />,
        module: 'gr'
      },
      {
        path: '/DiscussRefer',
        component: <DiscussRefer />,
        module: 'dnr'
      },
      {
        path: '/HospitalProfile',
        component: <HospitalProfile />,
        module: 'generic'
      },
      {
        path: '/DiscussForm',
        component: <DiscussForm />,
        module: 'dnr'
      },
      {
        path: '/ReferForm',
        component: <DiscussForm />,
        module: 'dnr'
      },
      {
        path: '/Activities',
        component: <Activities />,
        module: 'speciality'
      },
      {
        path: '/ReInitiateRefer',
        component: <ReInitiateRefer />,
        module: 'dnr'
      },
      {
        path: '/ReferThankyou',
        component: <ReferThankYou />,
        module: 'dnr'
      },
      {
        path: '/ClinicalVideos',
        component: <ClinicalVideoList />,
        module: 'clinicalVideo'
      },
      {
        path: '/Medwiki',
        component: <MedwikiLandingV2 />,
        module: 'medwiki'
      },
      {
        path: '/Demo',
        component: <Demo />,
        module: 'generic'
      },
      {
        path: '/ClinicalVideoLanding',
        component: <ClinicalVideoLanding />,
        module: 'clinicalVideo'
      },
      {
        path: '/Channel',
        component: <ChannelLandingV2 />,
        module: 'channel'
      },
      {
        path: '/ManageChannel',
        component: <ChannelManage />,
        module: 'channel'
      },
      {
        path: '/ChannelSuggestion',
        component: <ChannelSuggestion />,
        module: 'channel'
      },
      {
        path: '/TopRatedMedwikiListing',
        component: <TopRatedMedwikiListing />,
        module: 'medwiki'
      },
      {
        path: '/TeleMed',
        component: <TelemedLite />,
        module: 'telemed'
      },
      {
        path: '/SPQlanding',
        component: <SPQlanding />,
        module: 'spq'
      },
      {
        path: '/UserPoint',
        component: <UserPoint />,
        module: 'generic'
      },
      {
        path: '/ElectionLanding',
        component: <ElectionLandinng />,
        module: 'election'
      },
      {
        path: '/CandidateSelection',
        component: <CandidateSelection />,
        module: 'election'
      },
      {
        path: '/VotingSummary',
        component: <VotingSummary />,
        module: 'election'
      },
      {
        path: '/MobileVerification',
        component: <MobileVerification />,
        module: 'generic'
      },
      {
        path: '/AdsPlayer',
        component: <AdsPlayer />,
        module: 'generic'
      },
      {
        path: '/ProfileVideos',
        component: <ProfileVideos />,
        module: 'generic'
      },
      {
        path: '/TrainingLanding',
        component: <GRlanding />,
        module: 'courses'
      },

      {
        path: '/TrainingSummary',
        component: <GRsummary />,
        module: 'courses'
      },
      {
        path: '/Notification',
        component: <Notification />,
        module: 'generic'
      },
      {
        path: '/Doctube',
        component: <DoctubeLanding />,
        module: 'doctube'
      },

      {
        path: '/DoctubeListingVideos',
        component: <DoctubeListingVideos />,
        module: 'doctube'
      },
      {
        path: '/ProfileSteps',
        component: <ProfileSteps />,
        module: 'doctube'
      },
      {
        path: '/DoctubeDashboard',
        component: <DoctubeDashboard key="doctubeDashboard" />,
        module: 'doctube'
      },
      {
        path: '/DoctubeAnalytics',
        component: <DoctubeDashboard key="doctubeAnalytics" />,
        module: 'doctube'
      },
      {
        path: '/DoctubeVideoUpload',
        component: <DoctubeVideoUpload />,
        module: 'doctube'
      },
      {
        path: '/DoctubeManagePlaylist',
        component: <DoctubeManagePlaylist />,
        module: 'doctube'
      },
      {
        path: '/SPQ',
        component: <SPQ />,
        module: 'spq'
      },
      {
        path: '/TrainingListing',
        component: <GRlisting />,
        module: 'courses'
      }
    ]
  },
  {
    layout: <DefaultLayout />,
    SpecialityLayout: <SpecialityLayout />,
    subpath: [
      {
        path: '/Dashboard',
        component: <Dashboard />,
        specialityComponent: <SpecialityDashboard />,
        module: 'generic'
      },
      {
        path: '/UserProfile',
        component: <UserProfile />,
        specialityComponent: <Profile />,
        module: 'generic'
      },
      {
        path: '/AllSpecialities',
        component: <AllSpecialities />,
        module: 'speciality'
      }
    ]
  },
  {
    layout: <DetailsPageLayout />,
    SpecialityLayout: <SpecialityDetailsLayout />,
    subpath: [
      {
        path: '/TrainingContent',
        component: <GRcontent />,
        module: 'courses'
      },

      // {
      //   path: '/MedwikiDetails',
      //   component: <MedwikiDetailsV2 />,
      //   module: 'medwiki'
      // },
      {
        path: '/SpeakerProfile',
        component: <SpeakerProfile />,
        module: 'generic'
      },
      {
        path: '/CertificateDetails',
        component: <CertificateDetails />,
        module: 'generic'
      },
      {
        path: '/SPQdetails',
        component: <SPQdetails />,
        module: 'spq'
      },
      {
        path: '/PollDetails',
        component: <PollDetails />,
        module: 'spq'
      },
      // {
      //   path: '/SessionDetails',
      //   component: <SessionDetails />
      // },
      {
        path: '/SessionDetails',
        component: <SessionDetailsOutlet />,
        module: 'session'
      },
      {
        path: '/ClinicalVideoDetails',
        component: <ClinicalVideoDetails />,
        module: 'clinicalVideo'
      },
      {
        path: '/LiveSession',
        component: <LiveSessionOutlet />,
        module: 'session'
      },
      // {
      //   path: '/SessionWaitingroom',
      //   component: <SessionWaitingroomOutlet />,
      //   module: 'session'
      // },
      {
        path: '/photobooth',
        component: <Photobooth />,
        module: 'session'
      },
      {
        path: '/CaseDetails',
        component: <CaseDetails />,
        module: 'generic'
      },
      {
        path: '/GrandRoundDetails',
        component: <GrandRoundDetails />,
        module: 'gr'
      },

      {
        path: '/DoctubePlaylistDetails',
        component: <DoctubePlaylistDetails />,
        module: 'doctube'
      },

      {
        path: '/TrainingCover',
        component: <GRcover />,
        module: 'gr'
      }
    ]
  },
  {
    layout: <DetailsPageLayout />,
    SpecialityLayout: <SpecialityDefaultLayout />,
    subpath: [
      {
        path: '/MedwikiDetails',
        component: <MedwikiDetailsV2 />,
        specialityComponent: <MedwikiDetails />,
        module: 'medwiki'
      },
      {
        path: '/ChannelProfile',
        specialityComponent: <ChannelProfileV2 isSpecialityLayout />,
        component: (
          <div className="w-100 d-flex flex-column position-relative speciality-portal--defaultLayout">
            <div className="container-fluid">
              <Row className="align-items-start flex-nowrap">
                <ChannelProfileV2 />
              </Row>
            </div>
          </div>
        ),
        module: 'channel'
      },
      {
        path: '/EbookDetails',
        component: <EbookDetails />,
        specialityComponent: <EpaperDetails />,
        module: 'epub'
      }
      // {
      //   path: '/ClinicalVideoDetails',
      //   component: <ClinicalVideoDetailsV2 />,
      //   module: 'clinicalVideo'
      // }
    ]
  }
];
