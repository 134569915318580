import React from 'react';
import './loadingFallbackNew.scss';

function LoadingFallbackNew() {
  return (
    <div className="clr_v3_loader_container gap-20 d-flex vw-100 vh-100 justify-content-center align-items-center flex-column position-fixed top-0 start-0 bg-white">
      <div className="DNA_cont">
        <div className="nucleobase"></div>
        <div className="nucleobase"></div>
        <div className="nucleobase"></div>
        <div className="nucleobase"></div>
        <div className="nucleobase"></div>
        <div className="nucleobase"></div>
        <div className="nucleobase"></div>
        <div className="nucleobase"></div>
        <div className="nucleobase"></div>
        <div className="nucleobase"></div>
      </div>
      {/* <div className="text-black fs-4 fw-semibold  loader_text mt-5">Loading ...</div> */}
      <div className="loading d-flex flex-row mt-50 fs-3 ms-3 ms-md-4 fw-semibold ">
        <div className="loading__letter">L</div>
        <div className="loading__letter">o</div>
        <div className="loading__letter">a</div>
        <div className="loading__letter">d</div>
        <div className="loading__letter">i</div>
        <div className="loading__letter">n</div>
        <div className="loading__letter">g</div>
        <div className="loading__letter">.</div>
        <div className="loading__letter">.</div>
        <div className="loading__letter">.</div>
      </div>
    </div>
  );
}

export default LoadingFallbackNew;
