import React, { useCallback, useState, useEffect, memo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import '../assets/sass/uiV3/pages/_specialityLanding.scss';
import '../assets/sass/uiV3/component/_header.scss';
import HeaderSpecialityDesktop from '../components/baseUiV3/template/header/HeaderSpecialityDesktop';
import HeaderSpecialityMobile from '../components/baseUiV3/template/header/HeaderSpecialityMobile';
import { isMobile } from 'react-device-detect';
import MobileFooterNav from '../components/baseUiV3/template/footer/MobileFooterNav';
import HeaderProfileMobile from '../components/baseUiV3/template/header/HeaderProfileMobile';
import HeaderSpecialityDetailsMobile from '../components/baseUiV3/template/header/HeaderSpecialityDetailsMobile';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 *
 */
const SpecialityLayoutOldPage = () => {
  const [elHeight, setElheight] = useState(0);
  const [bottomHeight, setBottomHeight] = useState(0);
  const [navItem, setNavItem] = useState('All');
  // const [exploreMenuOpen, setExploreMenu] = useState(false);
  const { pathname } = useLocation();
  const pageName = pathname.slice(1).toLowerCase();
  // const exploreMenuToggler = () => {
  //   setExploreMenu(!exploreMenuOpen);
  // };
  const getElementHeight = (data) => {
    // alert(data)
    console.log('height', data);
    setElheight(data);
  };
  const getElementBottomHeight = (data) => {
    // alert(data)
    setBottomHeight(data);
  };
  const selectedNavItem = (data) => {
    setNavItem(data);
  };

  return (
    <>
      {isMobile ? (
        pageName == 'profile' ? (
          <HeaderProfileMobile getElementHeight={getElementHeight} />
        ) : pageName !== 'trainingcontent' ? (
          <HeaderSpecialityMobile
            getElementHeight={getElementHeight}
            selectedNavItem={selectedNavItem}
          />
        ) : (
          <HeaderSpecialityDetailsMobile getElementHeight={getElementHeight} />
        )
      ) : (
        <HeaderSpecialityDesktop getElementHeight={getElementHeight} />
      )}
      <div
        className={`${isMobile ? 'mobile ' : 'desktop'} speciality-portal w-100 d-flex`}
        style={!isMobile ? { paddingTop: `${elHeight}px` } : { paddingBottom: `${bottomHeight}px` }}
        // style={paddingTop: `${elHeight}px`, paddingBottom: `${bottomHeight}px` }}
      >
        {/* <div className="w-100 d-flex flex-column position-relative">
          <div className="container-fluid">
            <div className="row align-items-start flex-nowrap"> */}
        <Outlet />
        {/* </div>
          </div>
        </div> */}
      </div>

      {(pageName !== 'speciality' || pageName !== 'TrainingContent') &&
        (isMobile ? (
          pageName !== 'trainingcontent' &&
          pageName !== 'profilesteps' && (
            <MobileFooterNav
              getElementBottomHeight={getElementBottomHeight}
              // exploreMenuOpen={exploreMenuOpen}
              // exploreMenuToggler={exploreMenuToggler}
              height={elHeight}
            />
          )
        ) : (
          <></>
        ))}
    </>
  );
};

SpecialityLayoutOldPage.propTypes = propTypes;
SpecialityLayoutOldPage.defaultProps = defaultProps;
// #endregion

export default memo(SpecialityLayoutOldPage);
