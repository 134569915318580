import React, { useEffect, useRef } from 'react';
import { ClirnetIconLogo } from '../../../icons';
import {
  getLocalStorage,
  isApp,
  isIosApp,
  isMobileApp,
  removeLocalStorage
} from '../../../../common/common';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useRedirect from '../../../customHooks/useRedirect';
import QueryString from 'query-string';
const HeaderSpecialityDetailsMobile = ({ getElementHeight }) => {
  const location = useLocation();
  const { id, title } = QueryString.parse(location.search);
  const getHeight = useRef(0);
  const current_menu = useSelector((state) => state.common.current_menu);
  const isFromNotification = getLocalStorage('autoAuthFromNotification');
  const isFromAutoAuth = getLocalStorage('autoAuthFromAppUrlListener');
  const navigate = useNavigate();

  useEffect(() => {
    getElementHeight(getHeight.current.offsetHeight);
  }, [getHeight.current.offsetHeight]);

  const { redirectTo } = useRedirect();
  const cancelClick = () => {
    if (isMobileApp()) {
      if (isFromNotification) {
        navigate('/Notification');
        removeLocalStorage('autoAuthFromNotification');
      } else if (isFromAutoAuth) {
        navigate('/Dashboard');
        removeLocalStorage('autoAuthFromAppUrlListener');
      } else {
        // alert("current_menu?.toLowerCase()====>" + JSON.stringify(current_menu))
        if (location?.state?.from_login) {
          if (current_menu?.toLowerCase()?.includes('session')) {
            navigate('/Sessions');
          } else if (current_menu?.toLowerCase()?.includes('medwiki')) {
            navigate('/Medwiki');
          } else if (current_menu?.toLowerCase()?.includes('spq')) {
            navigate('/SPQlanding');
          } else if (current_menu?.toLowerCase()?.includes('clinical')) {
            navigate('/ClinicalVideoLanding');
          } else if (current_menu?.toLowerCase()?.includes('gr')) {
            navigate('/GrandRound');
          } else if (current_menu?.toLowerCase()?.includes('ebook')) {
            navigate('/Ebook');
          } else if (current_menu?.toLowerCase()?.includes('channel')) {
            navigate('/Channel');
          } else if (current_menu?.toLowerCase()?.includes('dnr')) {
            navigate('/DiscussRefer');
          } else if (current_menu?.toLowerCase()?.includes('certificate')) {
            navigate('/CertificatesListing');
          } else if (current_menu?.toLowerCase()?.includes('training')) {
            navigate('/TrainingLanding');
          } else {
            navigate('/Dashboard');
          }
        } else {
          if (location.pathname?.toLowerCase().includes('livesession')) {
            redirectTo('SessionDetails', id, title);
          } else if (current_menu?.toLowerCase()?.includes('session')) {
            // alert("current_menu?.toLowerCase()====>" + JSON.stringify(current_menu))
            navigate('/Sessions');
          } else {
            // console.log('callled else');
            navigate(-1);
          }
        }
      }
    } else if (location?.state?.from_login) {
      if (current_menu?.toLowerCase()?.includes('session')) {
        navigate('/Sessions');
      } else if (current_menu?.toLowerCase()?.includes('medwiki')) {
        navigate('/Medwiki');
      } else if (current_menu?.toLowerCase()?.includes('spq')) {
        navigate('/SPQlanding');
      } else if (current_menu?.toLowerCase()?.includes('clinical')) {
        navigate('/ClinicalVideoLanding');
      } else if (current_menu?.toLowerCase()?.includes('gr')) {
        navigate('/GrandRound');
      } else if (current_menu?.toLowerCase()?.includes('ebook')) {
        navigate('/Ebook');
      } else if (current_menu?.toLowerCase()?.includes('channel')) {
        navigate('/Channel');
      } else if (current_menu?.toLowerCase()?.includes('dnr')) {
        navigate('/DiscussRefer');
      } else if (current_menu?.toLowerCase()?.includes('certificate')) {
        navigate('/CertificatesListing');
      } else if (current_menu?.toLowerCase()?.includes('training')) {
        navigate('/TrainingLanding');
      } else {
        navigate('/Dashboard');
      }
    } else {
      if (location.pathname?.toLowerCase().includes('livesession')) {
        redirectTo('SessionDetails', id, title);
      } else if (current_menu?.toLowerCase()?.includes('session')) {
        navigate('/Sessions');
      } else {
        console.log('callled else');
        navigate(-1);
      }
    }
  };

  const HandleAppReload = () => {
    window.location.reload();
  };

  return (
    <nav
      className="clr_v3_Header flex-column position-fixed text-black w-100 top-0 p-sm-4 p-3 backdrop-filter"
      //   ref={headerHeightRef}
      ref={getHeight}
    >
      {isIosApp() && <div className="iosSpecial-safe-area-top w-100"></div>}
      <div className="d-flex justify-content-between align-items-center ">
        <div
          className="clr_v3_Header__brand me-auto bg-white d-flex align-items-center justify-content-center rounded-3 p-2"
          onClick={() => navigate('/Dashboard')}
        >
          <ClirnetIconLogo />
        </div>
        <div className="d-flex align-items-center gap-4">
          {isMobileApp() && (
            <a
              href="javascript:void(0)"
              className="bg-light ratio-1 rounded-circle p-2 text-black"
              style={{ '--bs-bg-opacity': '.9' }}
              onClick={HandleAppReload}
            >
              <i className="flaticon-undo fs-30"></i>
            </a>
          )}
          <span
            className={`d-inline-block ${isMobileApp() ? 'p-4' : 'p-2'}`}
            onClick={() => cancelClick()}
          >
            <i class="flaticon flaticon-close fs-2"></i>
          </span>
        </div>
      </div>
    </nav>
  );
};

export default HeaderSpecialityDetailsMobile;
