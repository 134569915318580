import React from 'react';
import { Modal } from 'react-bootstrap';

const DeleteConfirmation = ({ title, type, confirm, handleClose, confirmDeletion }) => {
  return (
    <Modal className="centerModal" size="sm" centered show={confirm} onHide={handleClose}>
      <Modal.Header className="position-relative border-0 p-4 mb-1">
        <Modal.Title className="fs-4 text-black fw-semibold">Please Confirm</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <form>
          <div className="w-100 centerModalFrm">
            <h1 className="fs-16 text-black fw-semibold mb-4">{title}</h1>
            <div className="d-flex flex-wrap centerModalBttns">
              <button
                className="col fs-4 btn btn-danger py-3 px-4"
                onClick={handleClose}
                aria-label="Cancel"
              >
                Cancel
              </button>
              <button
                className="col fs-4 btn btn-primary py-3 px-4"
                type="button"
                onClick={() => confirmDeletion(type)}
                aria-label="Submit"
              >
                Yes I'm Sure
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default DeleteConfirmation;
