import React, { memo } from 'react';
import { isMobile } from 'react-device-detect';
import { Outlet, useLocation } from 'react-router-dom';
import MobileFooter from '../components/footer/MobileFooter';
import HeaderDesktop from '../components/header/HeaderDesktop';
import HeaderMobileMain from '../components/header/HeaderMobileMain';
import { useSelector } from 'react-redux';
import { isIosApp } from '../common/common';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 *
 */
const DefaultLayout = () => {
  const baseClass = useSelector((state) => state.settings.baseClass);
  const { pathname } = useLocation();

  const pageName = pathname.slice(1).toLowerCase();

  // alert(baseClass)
  return (
    <div className={baseClass}>
      {isIosApp() &&
        <div className='iosSpecial-safe-area-top w-100'></div>
      }
      {isMobile ? <HeaderMobileMain /> : <HeaderDesktop />}
      <Outlet />
      {/* {isMobile ? <MobileFooter /> : <></>} */}

      {(pageName !== 'speciality' || pageName !== 'TrainingContent') &&
        (isMobile ? (
          pageName !== 'trainingcontent' && pageName !== 'profilesteps' && <MobileFooter />
        ) : (
          <></>
        ))}
    </div>
  );
};

DefaultLayout.propTypes = propTypes;
DefaultLayout.defaultProps = defaultProps;
// #endregion

export default memo(DefaultLayout);
