import axios from 'axios';
import dayjs from 'dayjs';
import jwt_decode from 'jwt-decode';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import { loginSuccess, logoutUser } from '../Store/actions/login.action';
import { getLocalStorage, setLocalStorage } from '../common/common';
import appConfig from '../config/config';
import LoadingFallback from '../router/fallback/LoadingFallbackNew';

const SpecialityOnboarding = lazy(() => import('../pages/speciality/SpecialityOnboarding'));

const baseURL = appConfig?.apiServices?.getTokenService;

const BaseComponent = ({ children }) => {
  const userDetails = useSelector((state) => state.common.user_details);
  if (typeof userDetails == 'string') return <LoadingFallback />;
  return userDetails &&
    userDetails?.spec_dashboard_eligibility_status &&
    userDetails?.specility_update_status === '0' ? (
    <Suspense fallback={<LoadingFallback />}>
      <SpecialityOnboarding />
    </Suspense>
  ) : (
    children
  );
};

const PrivateComponent = ({ userDetails, children }) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const getRefreshToken = () => {
    return getLocalStorage('refreshToken', '') ? getLocalStorage('refreshToken', '') : null;
  };
  const fetchRefreshToken = async () => {
    try {
      const response = await axios.post(`${baseURL}auth/getRefreshToken`, {
        token: getLocalStorage('refreshToken', '')
      });
      console.log('Private_Route:', 'refresh token response success', response.data);
      if (response.data.data.token !== undefined || response.data.data.token !== null)
        setLocalStorage('accessToken', response.data.data.token);
      if (
        response.data.data.refresh_token !== undefined ||
        response.data.data.refresh_token !== null
      )
        setLocalStorage('refreshToken', response.data.data.refresh_token);
      if (loading) {
        setLoading(false);
      }
    } catch (error) {
      if (loading) {
        setLoading(false);
      }
      console.log('Private_Route: ref token', error);
      dispatch(logoutUser());
    }
  };

  const fetchAccessToken = async () => {
    try {
      const response = await axios.post(`${baseURL}auth/getAccessToken`, {
        token: getLocalStorage('refreshToken', '')
      });
      console.log('Private_Route:', 'response access success', response.data);
      setLocalStorage('accessToken', response.data.data.token);

      if (loading) {
        setLoading(false);
      }
    } catch (error) {
      console.log('Private_Route:', error);
      fetchRefreshToken();
    }
  };

  const isExpired = (accessToken) => {
    if (accessToken) {
      let access = jwt_decode(accessToken);
      let _isExpired = dayjs.unix(access.exp).diff(dayjs()) < 1;
      return _isExpired;
    } else {
      return true;
    }
  };
  useEffect(() => {
    if (getRefreshToken() && !isExpired(getRefreshToken())) {
      fetchAccessToken();
    } else {
      fetchRefreshToken();
    }
    return () => {};
  }, []);

  return loading ? (
    <LoadingFallback />
  ) : (
    <BaseComponent userDetails={userDetails} children={children} />
  );
};

const PrivateRoute = ({ children }) => {
  const userDet = useSelector((state) => state.common.user_details);

  const userDetailsS = useSelector((state) => state.common.user_details);
  const location = useLocation();
  const dispatch = useDispatch();

  const showingSpecData = useSelector((state) => state.dashboard.user_speciality_viewing);

  console.log('userDetails', userDetailsS);
  useEffect(() => {
    if (getLocalStorage('isLoggedIn', false)) {
      dispatch(loginSuccess());
    }
  }, []);

  const accessToken = () => {
    return getLocalStorage('accessToken', '') ? getLocalStorage('accessToken', '') : null;
  };

  const isExpired = (accessToken) => {
    if (accessToken) {
      let access = jwt_decode(accessToken);
      let _isExpired = dayjs.unix(access.exp).diff(dayjs()) < 1;

      return _isExpired;
    } else {
      return true;
    }
  };

  return (
    <>
      {!getLocalStorage('isLoggedIn', false) && userDet === '' ? (
        <>
          <Navigate to="/" state={{ from: location }} />
        </>
      ) : accessToken() && !isExpired(accessToken()) ? (
        <>
          <BaseComponent userDetails={userDet} children={children} />
        </>
      ) : (
        <>
          <PrivateComponent userDetails={userDet} children={children} />
        </>
      )}
    </>
  );
};

export default PrivateRoute;
