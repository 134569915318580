import { spqConstants } from '../constants/spq.constants';
import { commonConstants } from '../constants/commonConstants';

var initialState = {
  all_list_data: [],
  survey_list: [],
  poll_list_data: [],
  quiz_list_data: [],
  completed_all: [],
  completed_survey: [],
  completed_quiz: [],
  completed_poll: [],
  pending_list: [],
  spqDetails: {},
  spqRelated: []
};
export const spqReducer = (state = initialState, action) => {
  switch (action.type) {
    case spqConstants.GET_ALL_SUCCESS:
      if (action.payload.data)
        return {
          ...state,

          all_list_data: [...state.all_list_data, ...action.payload.data]
        };
      else {
        return {
          ...state,
          all_list_data: [],
          survey_list: [],
          poll_list_data: [],
          quiz_list_data: []
        };
      }
    case spqConstants.GET_ALL_FAILURE:
      return {
        ...state,
        error: action.payload.message
      };
    case spqConstants.GET_POLL_SUCCESS:
      if (action.payload.data)
        return {
          ...state,

          poll_list_data: [...state.poll_list_data, ...action.payload.data]
        };
      else {
        return {
          ...state,
          all_list_data: [],
          survey_list: [],
          poll_list_data: [],
          quiz_list_data: []
        };
      }
    case spqConstants.GET_POLL_FAILURE:
      return {
        ...state,
        error: action.payload.message
      };
    case spqConstants.GET_QUIZ_SUCCESS:
      if (action.payload.data)
        return {
          ...state,

          quiz_list_data: [...state.quiz_list_data, ...action.payload.data]
        };
      else {
        return {
          ...state,
          all_list_data: [],
          survey_list: [],
          poll_list_data: [],
          quiz_list_data: []
        };
      }
    case spqConstants.GET_QUIZ_FAILURE:
      return {
        ...state,
        error: action.payload.message
      };

    case spqConstants.GET_SURVEY_SUCCESS:
      if (action.payload.data)
        return {
          ...state,

          survey_list: [...state.survey_list, ...action.payload.data]
        };
      else {
        return {
          ...state,
          all_list_data: [],
          survey_list: [],
          poll_list_data: [],
          quiz_list_data: []
        };
      }
    case spqConstants.GET_SURVEY_FAILURE:
      return {
        ...state,
        error: action.payload.message
      };

    case spqConstants.GET_COMPLETED_ALL_SUCCESS:
      if (action.payload.data)
        return {
          ...state,
          completed_survey: [],
          completed_poll: [],
          completed_quiz: [],
          completed_all: action.payload.data
        };
      else {
        return {
          ...state,
          completed_survey: [],
          completed_quiz: [],
          completed_all: [],
          completed_poll: []
        };
      }
    case spqConstants.GET_COMPLETED_ALL_FAILURE:
      return {
        ...state,
        error: action.payload.message
      };
    case spqConstants.GET_COMPLETED_POLL_SUCCESS:
      if (action.payload.data)
        return {
          ...state,
          completed_survey: [],
          completed_quiz: [],
          completed_all: [],
          completed_poll: action.payload.data
        };
      else {
        return {
          ...state,
          completed_survey: [],
          completed_quiz: [],
          completed_all: [],
          completed_poll: []
        };
      }
    case spqConstants.GET_COMPLETED_POLL_FAILURE:
      return {
        ...state,
        error: action.payload.message
      };
    case spqConstants.GET_COMPLETED_QUIZ_SUCCESS:
      if (action.payload.data)
        return {
          ...state,
          completed_survey: [],
          completed_poll: [],
          completed_all: [],
          completed_quiz: action.payload.data
        };
      else {
        return {
          ...state,
          completed_survey: [],
          completed_quiz: [],
          completed_all: [],
          completed_poll: []
        };
      }
    case spqConstants.GET_COMPLETED_QUIZ_FAILURE:
      return {
        ...state,
        error: action.payload.message
      };

    case spqConstants.GET_COMPLETED_SURVEY_SUCCESS:
      if (action.payload.data)
        return {
          ...state,
          completed_poll: [],
          completed_quiz: [],
          completed_all: [],
          completed_survey: action.payload.data
        };
      else {
        return {
          ...state,
          completed_survey: [],
          completed_quiz: [],
          completed_all: [],
          completed_poll: []
        };
      }
    case spqConstants.GET_COMPLETED_SURVEY_FAILURE:
      return {
        ...state,
        error: action.payload.message
      };

    case spqConstants.GET_SPQ_DETAILS_SUCCESS:
      if (action.payload.data)
        return {
          ...state,
          spqDetails: action.payload.data[0]
        };

    case spqConstants.GET_SPQ_DETAILS_FAILURE:
      return {
        ...state,
        error: action.payload.message
      };
    case spqConstants.GET_SPQ_RELATED_SUCCESS:
      if (action.payload.data)
        return {
          ...state,
          spqRelated: [...state.spqRelated, ...action.payload.data]
        };

    case spqConstants.GET_SPQ_RELATED_FAILURE:
      return {
        ...state,
        error: action.payload.message
      };

    case spqConstants.GET_PENDING_SUCCESS:
      if (action.payload.data)
        return {
          ...state,
          pending_list: action.payload.data
        };

    case spqConstants.GET_PENDING_FAILURE:
      return {
        ...state,
        error: action.payload.message
      };
    case commonConstants.CLEAR_ARRAY_SUCCESS:
      return initialState;

    default:
      return state;
  }
};
