import { sessionConstants } from '../constants';
import { commonConstants } from '../constants/commonConstants';
let initialState = {
  session_detail: [],
  recent_session_data: [],
  featured_session_data: [],
  reserved_session_data: [],
  upcoming_session_data: [],
  featured_master_doctor: [],
  doctor_data: [],
  upcoming_session_by_doc: [],
  previous_sessions_by_doc: []
};
export const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case sessionConstants.GET_UPCOMING_SESSION_DATA_SUCCESS:
      return {
        ...state,
        upcoming_session_data: [...state.upcoming_session_data, ...action.payload]
      };
    case sessionConstants.GET_UPCOMING_SESSION_DATA_FALIURE:
      return {
        ...state,
        upcoming_session_data: []
      };

    case sessionConstants.GET_RECENT_SESSION_DATA_SUCCESS:
      return {
        ...state,
        recent_session_data: [...state.recent_session_data, ...action.payload]
      };
    case sessionConstants.GET_RECENT_SESSION_DATA_FALIURE:
      return {
        ...state,
        recent_session_data: []
      };

    case sessionConstants.GET_RESERVED_SESSION_DATA_SUCCESS:
      return {
        ...state,
        reserved_session_data: [...state.reserved_session_data, ...action.payload]
      };
    case sessionConstants.GET_RESERVED_SESSION_DATA_FALIURE:
      return {
        ...state,
        reserved_session_data: []
      };
    case sessionConstants.GET_FEATURED_SESSION_DATA_SUCCESS:
      return {
        ...state,
        featured_session_data: action.payload
      };
    case sessionConstants.GET_FEATURED_SESSION_DATA_FALIURE:
      return {
        ...state,
        featured_session_data: []
      };
    case sessionConstants.GET_SESSION_DETAILS_DATA_SUCCESS:
      return {
        ...state,
        session_detail: action.payload
      };
    case sessionConstants.GET_SESSION_DETAILS_DATA_FALIURE:
      return {
        ...state,
        session_detail: []
      };

    case sessionConstants.GET_FEATURED_MASTER_DOC_DATA_SUCCESS:
      return {
        ...state,
        featured_master_doctor: [...state.featured_master_doctor, ...action.payload]
      };
    case sessionConstants.GET_FEATURED_MASTER_DOC_DATA_FALIURE:
      return {
        ...state,
        featured_master_doctor: []
      };

    case sessionConstants.UPDATE_SESSION_DETAILS_DATA:
      return {
        ...state,
        session_detail: [...action.payload]
      };
    case sessionConstants.GET_DOCTOR_DATA_SUCCESS:
      return {
        ...state,
        doctor_data: action.payload
      };
    case sessionConstants.GET_DOCTOR_DATA_FALIURE:
      return {
        ...state,
        doctor_data: []
      };

    case sessionConstants.GET_UPCOMING_SESSIONS_BY_DOCTOR_SUCCESS:
      return {
        ...state,
        upcoming_session_by_doc: [...state.upcoming_session_by_doc, ...action.payload.data]
      };

    case sessionConstants.GET_UPCOMING_SESSIONS_BY_DOCTOR_FALIURE:
      return {
        ...state,
        upcoming_session_by_doc: []
      };

    case sessionConstants.GET_PREVIOUS_SESSION_BY_DOCTOR_SUCCESS:
      return {
        ...state,
        previous_sessions_by_doc: [...state.previous_sessions_by_doc, ...action.payload.data]
      };

    case sessionConstants.GET_PREVIOUS_SESSION_BY_DOCTOR_FALIURE:
      return {
        ...state,
        previous_sessions_by_doc: []
      };

    case commonConstants.CLEAR_ARRAY_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
