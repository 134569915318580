import React, { useCallback, useState, useEffect, memo } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import '../assets/sass/uiV3/pages/_specialityLanding.scss';
import '../assets/sass/uiV3/component/_header.scss';
import HeaderSpecialityDesktop from '../components/baseUiV3/template/header/HeaderSpecialityDesktop';
import HeaderSpecialityMobile from '../components/baseUiV3/template/header/HeaderSpecialityMobile';
import { isMobile } from 'react-device-detect';
import MobileFooterNav from '../components/baseUiV3/template/footer/MobileFooterNav';
import HeaderProfileMobile from '../components/baseUiV3/template/header/HeaderProfileMobile';
import HeaderSpecialityDetailsMobile from '../components/baseUiV3/template/header/HeaderSpecialityDetailsMobile';

// #region constants

// #endregion

// #region styled-components

// #endregion

// #region functions

// #endregion

// #region component
const propTypes = {};

const defaultProps = {};

/**
 *
 */
const SpecialityDetailsLayout = () => {
  const [elHeight, setElheight] = useState(0);
  const [bottomHeight, setBottomHeight] = useState(0);
  const [navItem, setNavItem] = useState('All');
  const { pathname } = useLocation();
  const pageName = pathname.slice(1);

  const getElementHeight = (data) => {
    // alert(data)
    setElheight(data);
  };
  const getElementBottomHeight = (data) => {
    // alert(data)
    setBottomHeight(data);
  };
  const selectedNavItem = (data) => {
    setNavItem(data);
  };
  // useEffect(() => {
  //   // let paramObj = {};
  //   // if (navItem == 'CME') {
  //   //   paramObj[`type`] = `${navItem}`;
  //   //   paramObj[`sessionType`] = `${navItem}`;
  //   // } else {
  //   // }
  //   setSearchParams({ type: `${navItem}` }, { replace: true });
  // }, [navItem]);

  const commentableRoutes = [
    'TrainingContent',
    'MedwikiDetails',
    'ClinicalVideoDetails',
    'GrandRoundDetails'
  ];
  function dontShowFooter() {
    var flag = false;
    commentableRoutes.forEach((element, index) => {
      if (pathname.toLowerCase().includes(element.toLowerCase())) {
        flag = true;
      }
      if (index == commentableRoutes.length - 1) {
      }
    });
    return flag;
  }

  const donstShowFooter = pathname.toLocaleLowerCase().includes('TrainingContent'.toLowerCase())
    ? true
    : false;

  return (
    <>
      {/* (isMobile ? 'w-100 mblscreen' : 'w-100 dskScreen desktopBody'); */}
      <div className={`${isMobile ? 'w-100 mblscreen' : 'w-100 dskScreen desktopBody p-0'} py-0`}>
        {isMobile ? (
          pageName == 'userProfile' ? (
            <HeaderProfileMobile getElementHeight={getElementHeight} />
          ) : (
            <HeaderSpecialityDetailsMobile getElementHeight={getElementHeight} />
          )
        ) : (
          <HeaderSpecialityDesktop getElementHeight={getElementHeight} elHeight={elHeight} />
        )}
        {console.log('bottomHeight', bottomHeight, document.getElementById('commentFrom'))}
        <div
          className={`${isMobile ? 'mobile' : 'desktop'} speciality-portal w-100 d-flex`}
          style={{
            paddingTop: `${elHeight}px`,
            paddingBottom: `${bottomHeight == 0 ? elHeight : bottomHeight}px`
          }}
        >
          {/* <div className="w-100 d-flex flex-column position-relative">
          <div className="container-fluid"> */}
          <Outlet />
          {/* </div>
        </div> */}
        </div>
        {pageName !== 'speciality' && isMobile && !dontShowFooter() ? (
          <MobileFooterNav
            getElementBottomHeight={getElementBottomHeight}
            // exploreMenuOpen={exploreMenuOpen}
            // exploreMenuToggler={exploreMenuToggler}
            height={elHeight}
          />
        ) : <></>}
      </div>
    </>
  );
};

SpecialityDetailsLayout.propTypes = propTypes;
SpecialityDetailsLayout.defaultProps = defaultProps;
// #endregion

export default memo(SpecialityDetailsLayout);
