import React, { memo, useEffect, useState } from 'react';
import { Navbar } from 'react-bootstrap';
import Marquee from 'react-fast-marquee';
import './css/headerMobileDetails.scss';
import { GrClose } from '../../components/icons';
import logoTop from './images/logoTop.png';
import { useNavigate, useLocation } from 'react-router';
import { getMarquee } from '../../Store/actions/dashboard.action';
import { useDispatch, useSelector } from 'react-redux';
import queryString from 'query-string';
import useRedirect from '../customHooks/useRedirect';
import {
  getLocalStorage,
  isApp,
  isIosApp,
  isMobileApp,
  removeLocalStorage
} from '../../common/common';
function HeaderMobileDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const { redirectTo } = useRedirect();
  const { id, title } = queryString.parse(location.search);
  const dispatch = useDispatch();
  const isFromNotification = getLocalStorage('autoAuthFromNotification', false);
  const isFromAutoAuth = getLocalStorage('autoAuthFromAppUrlListener', false);
  const current_menu = useSelector((state) => state.common.current_menu);
  // const marqueeText = useSelector((state) => state.dashboard.marquee);
  const [marqueeText, setMarqueeText] = useState([]);
  useEffect(() => {
    console.log('HEADER_DETAILS_LOCATION', location);
    dispatch(
      getMarquee((res) => {
        if (res != 'error') {
          setMarqueeText(res?.data);
        }
      })
    );
  }, []);
  const cancelClick = () => {
    // console.log('HEADER_DETAILS_LOCATION', location.pathname);
    // alert("login status------>" + location?.state?.from_login)
    // alert("autoauthFromNotification" + isFromNotification);
    // alert("autoauthFromAppUrlListener" + isFromAutoAuth);
    if (isMobileApp()) {
      if (isFromNotification) {
        navigate('/Notification');
        removeLocalStorage('autoAuthFromNotification');
      } else if (isFromAutoAuth) {
        navigate('/Dashboard');
        removeLocalStorage('autoAuthFromAppUrlListener');
      } else {
        if (location?.state?.from_login) {
          if (current_menu?.toLowerCase()?.includes('session')) {
            navigate('/Sessions');
          } else if (current_menu?.toLowerCase()?.includes('medwiki')) {
            navigate('/Medwiki');
          } else if (current_menu?.toLowerCase()?.includes('spq')) {
            navigate('/SPQlanding');
          } else if (current_menu?.toLowerCase()?.includes('clvideo')) {
            navigate('/ClinicalVideoLanding');
          } else if (current_menu?.toLowerCase()?.includes('gr')) {
            navigate('/GrandRound');
          } else if (current_menu?.toLowerCase()?.includes('ebook')) {
            navigate('/Ebook');
          } else if (current_menu?.toLowerCase()?.includes('channel')) {
            navigate('/Channel');
          } else if (current_menu?.toLowerCase()?.includes('dnr')) {
            navigate('/DiscussRefer');
          } else if (current_menu?.toLowerCase()?.includes('certificate')) {
            navigate('/CertificatesListing');
          } else if (current_menu?.toLowerCase()?.includes('training')) {
            navigate('/TrainingLanding');
          } else {
            navigate('/Dashboard');
          }
        } else {
          if (location.pathname?.toLowerCase().includes('livesession')) {
            redirectTo('SessionDetails', id, title);
          } else if (current_menu?.toLowerCase()?.includes('session')) {
            navigate('/Sessions');
          } else {
            // alert("callled else")
            navigate(-1);
          }
        }
      }
    } else if (location?.state?.from_login) {
      if (current_menu?.toLowerCase()?.includes('session')) {
        navigate('/Sessions');
      } else if (current_menu?.toLowerCase()?.includes('medwiki')) {
        navigate('/Medwiki');
      } else if (current_menu?.toLowerCase()?.includes('spq')) {
        navigate('/SPQlanding');
      } else if (current_menu?.toLowerCase()?.includes('clvideo')) {
        navigate('/ClinicalVideoLanding');
      } else if (current_menu?.toLowerCase()?.includes('gr')) {
        navigate('/GrandRound');
      } else if (current_menu?.toLowerCase()?.includes('ebook')) {
        navigate('/Ebook');
      } else if (current_menu?.toLowerCase()?.includes('channel')) {
        navigate('/Channel');
      } else if (current_menu?.toLowerCase()?.includes('dnr')) {
        navigate('/DiscussRefer');
      } else if (current_menu?.toLowerCase()?.includes('certificate')) {
        navigate('/CertificatesListing');
      } else if (current_menu?.toLowerCase()?.includes('training')) {
        navigate('/TrainingLanding');
      } else {
        navigate('/Dashboard');
      }
    } else {
      if (location.pathname?.toLowerCase().includes('livesession')) {
        redirectTo('SessionDetails', id, title);
      } else if (current_menu?.toLowerCase()?.includes('session')) {
        navigate('/Sessions');
      } else {
        navigate(-1);
      }
    }
  };

  const HandleAppReload = () => {
    window.location.reload();
  };

  return (
    <Navbar fixed="top" className="mblHeaderDetails flex-column p-0">
      {isIosApp() && <div className="iosSpecial-safe-area-top w-100 bg-light"></div>}
      {marqueeText?.length > 0 && (
        <div className="bg-black text-white fst-italic fw-semibold fs-4 w-100 text-nowrap lh-lg mblMarquee">
          <Marquee>
            {marqueeText?.map((_m) => (
              <span className="me-5" key={_m.id}>
                {_m.text}
              </span>
            ))}
          </Marquee>
        </div>
      )}
      <div className="mblHeaderDetailsMain w-100 bg-white shadow d-flex justify-content-between align-items-center">
        <div
          className="mblDtlsNavLeft gtm_cl_header_clirnet_logo"
          onClick={() => navigate('/Dashboard')}
        >
          <img
            src={logoTop}
            alt="top Logo"
            id="header_mobileDetails_clirnet_logo"
            className="float-start gtm_cl_header_clirnet_logo"
          />
        </div>
        <div className="d-flex align-items-center ms-auto gap-4">
          {isMobileApp() && (
            <a
              href="javascript:void(0)"
              className="bg-light ratio-1 rounded-circle p-2 text-black me-4"
              style={{ '--bs-bg-opacity': '.9' }}
              onClick={HandleAppReload}
            >
              <i className="flaticon-undo fs-30"></i>
            </a>
          )}
          <div className="mblDtlsNavRight" onClick={cancelClick}>
            <GrClose customClass="text-black fs-30" id={'header_mobileDetails_close'} />
          </div>
        </div>
      </div>
    </Navbar>
  );
}

export default memo(HeaderMobileDetails);
