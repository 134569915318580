export const SAVE_FILE_OBJECT = 'SAVE_FILE_OBJECT';
export const SAVE_FILE_CONSENT = 'SAVE_FILE_CONSENT';
export const SAVE_FILE_PRESCRIPTION = 'SAVE_FILE_PRESCRIPTION';
export const OPEN_CAMERA = 'OPEN_CAMERA';

export const BROWSE_FOR_DNR_CONSENT = 'BROWSE_FOR_DNR_CONSENT';
export const BROWSE_FOR_DNR_PRESCRIPTION = 'BROWSE_FOR_DNR_PRESCRIPTION';
export const DEFAULT_MENU ={
    "main": "home",
    "all_menu": [
        {
            "id": "1",
            "short-name": "Home",
            "url": "Dashboard",
            "icon": "https://storage.googleapis.com/medwiki/common/clirnet-menu-icons/Home-icon.svg",
            "title": "Home",
            "visible": true,
            "active_menu_name": "dashboard"
        },
        {
            "id": "2",
            "short-name": "Videos",
            "url": "ClinicalVideoLanding",
            "icon": "https://storage.googleapis.com/medwiki/common/clirnet-menu-icons/ClinicalVideo-icon.svg",
            "title": "Clinical Videos",
            "visible": true,
            "active_menu_name": "clvideo"
        },
        {
            "id": "3",
            "short-name": "Live CME",
            "url": "Sessions",
            "icon": "https://storage.googleapis.com/medwiki/common/clirnet-menu-icons/LiveCME-icon.svg",
            "title": "Live CME",
            "visible": true,
            "active_menu_name": "session"
        },
        {
            "id": "4",
            "short-name": "Medwiki&reg;",
            "url": "Medwiki",
            "icon": "https://storage.googleapis.com/medwiki/common/clirnet-menu-icons/Medwiki-icon.svg",
            "title": "MedWiki&reg;",
            "visible": true,
            "active_menu_name": "medwiki"
        },
        {
            "id": "6",
            "short-name": "DDx",
            "url": "DifferentialDiagnosis",
            "icon": "https://storage.googleapis.com/medwiki/common/clirnet-menu-icons/aida-icon.svg",
            "title": "Differential Diagnosis",
            "visible": true,
            "active_menu_name": "diagnosis"
        },
        {
            "id": "7",
            "short-name": "Channels",
            "url": "Channel",
            "icon": "https://storage.googleapis.com/medwiki/common/clirnet-menu-icons/Channel-icon.svg",
            "title": "Channels",
            "visible": true,
            "active_menu_name": "channel"
        },
        {
            "id": "8",
            "short-name": "E-Paper",
            "url": "Ebook",
            "icon": "https://storage.googleapis.com/medwiki/common/clirnet-menu-icons/EBook-icon.svg",
            "title": "E-Paper",
            "visible": true,
            "active_menu_name": "ebook"
        },
        {
            "id": "9",
            "short-name": "GR",
            "url": "GrandRound",
            "icon": "https://storage.googleapis.com/medwiki/common/clirnet-menu-icons/GrandRound-icon.svg",
            "title": "Grand Rounds",
            "visible": true,
            "active_menu_name": "gr"
        },
        {
            "id": "10",
            "short-name": "Courses",
            "url": "TrainingLanding",
            "icon": "https://storage.googleapis.com/medwiki/common/clirnet-menu-icons/training-icon.svg",
            "title": "Certified Courses",
            "visible": true,
            "active_menu_name": "training"
        },
        {
            "id": "11",
            "short-name": "SPQ",
            "url": "SPQlanding",
            "icon": "https://storage.googleapis.com/medwiki/common/clirnet-menu-icons/Polls-icon.svg",
            "title": "Poll & Quizzes",
            "visible": true,
            "active_menu_name": "spq"
        }
    ]
}