import React, { memo, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
// import ReactHtmlParser from 'react-html-parser';
// import {reactHtmlParser} from '../../common/common'
import { useGetAutoSuggestion } from '../../Queries/QueryHooks/search.hook';
import { changeShowSearch, toggleCommentFocus, toggleReference } from '../../Store/actions';
import { getAllSpecialities } from '../../Store/actions/medwikiActions';
import { reactHtmlParser } from '../../common/common';
import { GrClose, RiSearchLine } from '../../components/icons';
import useDebounce from '../customHooks/useDebounce';
import './css/searchTop.scss';
const HeaderSearchBar = ({ currentMenu }) => {
  const location = useLocation();
  const inputRef = useRef(null);
  const wrapperRef = useRef(null);
  const navigate = useNavigate();
  const isSearchOpen = useSelector((state) => state.common.isSearchOpen);
  useOutsideAlerter(wrapperRef, isSearchOpen);
  const [addClass, setAddClass] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const [suggestionLoading, setSuggestionLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const debouncedSearchTerm = useDebounce(searchText, 200);
  const dispatch = useDispatch();
  const is_speciality_called = useSelector((state) => state.medwiki.is_speciality_called);
  useEffect(() => {
    setSearchText('');
  }, [currentMenu]);
  useEffect(() => {
    !is_speciality_called && fetchAllSpecialities();
  }, [is_speciality_called]);
  useEffect(() => {
    if (isSearchOpen) {
      setAddClass('open');
    } else {
      setAddClass('');
      setIsExpanded(false);
    }
  }, [isSearchOpen]);
  const { data: autoSuggestionKeywords = [], isLoading: autoSuggestionLoading } =
    useGetAutoSuggestion(
      debouncedSearchTerm,
      !!debouncedSearchTerm && debouncedSearchTerm?.length > 2 && isExpanded
    );
  const [autoSuggestions, setAutoSuggestions] = useState([]);
  useEffect(() => {
    if (autoSuggestionKeywords?.length > 0) {
      setAutoSuggestions(autoSuggestionKeywords);
    }
  }, [autoSuggestionKeywords]);
  const searchClassHandler = (event) => {
    if (!location.pathname.includes('/SearchResult')) {
      // inputRef.current.focus();
      if (isMobile) {
        inputRef.current.focus();
        setIsExpanded(true);
      }
      dispatch(changeShowSearch(true));
    } else {
      event == 'click' && dispatch(toggleReference(true));
    }
  };

  function useOutsideAlerter(ref, isSearchOpen) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          isSearchOpen && dispatch(changeShowSearch(false));
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref, isSearchOpen]);
  }
  const fetchAllSpecialities = () => {
    dispatch(
      getAllSpecialities((res) => {
        if (res != 'error') {
          console.log('Sp', res.data);
        }
      })
    );
  };
  const closeSearch = () => {
    setTimeout(() => {
      dispatch(changeShowSearch(false));
    }, 50);
  };
  const focusInput = () => {
    dispatch(toggleCommentFocus(true));
    setIsExpanded(true);
    // if (searchedElements?.length == 0) {
    //   dispatch(fetchSearchData((res) => { }));
    // }
  };
  const onBlurInput = () => {
    dispatch(toggleCommentFocus(false));
  };
  const matchStringToBold = (querystr, output) => {
    let reg = new RegExp(querystr, 'gi');
    let final_str = output
      ? output.replace(reg, function (str) {
          return str.bold();
        })
      : '';
    return reactHtmlParser(final_str);
  };
  const submitSearch = (e) => {
    e.preventDefault();
    if (searchText.trim() !== '') navigate('/SearchResult?query=' + searchText);
  };
  const onClickSuggestion = (text) => {
    navigate('/SearchResult?query=' + text + '&embedded=true');
  };
  return (
    <div className="d-flex deskHdrSrchBarArea">
      <form className="deskHdrSrchBarFrm d-flex">
        {/* mobileSearch open */}
        <div
          ref={wrapperRef}
          className={`deskHdrSrchBar rounded-pill position-relative ${addClass} ${
            isMobile ? 'mobileSearch z-2' : ''
          }`}
          onClick={() => searchClassHandler('click')}
          onMouseEnter={() => searchClassHandler('mouseenter')}
          // onMouseLeave={() => isSearchOpen && dispatch(changeShowSearch(false))}
        >
          <input
            className="deskHdrSrchInput bg-transparent p-0 border-0 float-end gtm_cl_header_search_logo"
            type="text"
            placeholder="Search here"
            ref={inputRef}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => e.key == 'Enter' && submitSearch(e)}
            onFocus={focusInput}
            id="header_desktop_search_input"
            onBlur={onBlurInput}
          />
          <div className="deskHdrSrchIcon position-absolute top-50 end-0 translate-middle-y rounded-circle d-flex justify-content-center align-items-center cursorPointer">
            <RiSearchLine customClass={`fs-3 ${isMobile ? 'text-white' : 'text-black'}`} />
          </div>
          <button
            className="searchSubmiBtn text-black fs-16 fw-medium position-absolute top-50 end-0 translate-middle-y rounded-circle d-flex justify-content-center align-items-center cursorPointer gtm_cl_header_search_logo"
            onClick={submitSearch}
          >
            {/* <MdOutlineArrowRightAlt customClass="fs-1 text-black" /> */}
            GO
          </button>
          <a
            href="javascript:void(0)"
            className="searchColse position-absolute start-0 h-100 d-none justify-content-center align-items-center z-1"
            onClick={closeSearch}
          >
            <GrClose customClass="fs-3 text-white text-white" />
          </a>
          {autoSuggestions?.length > 0 && isExpanded && (
            <div className="searchSuggestion text-start position-absolute end-0 w-100 bg-white overflow-y-auto d-none">
              {suggestionLoading && (
                <div className="loader-line position-relative overflow-hidden w-100"></div>
              )}
              {/* search history */}
              {autoSuggestions?.length > 0 && (
                <ul className="ps-0 list-unstyled border-bottom border-light">
                  {autoSuggestions?.map((_sugg, _idx) => (
                    <li className="d-block position-relative" key={_idx + 1}>
                      <span
                        className="main_keyWord d-block"
                        onClick={() => onClickSuggestion(_sugg)}
                      >
                        <a
                          href="javascript:void(0)"
                          className="line-clamp-1 position-relative d-block text-dark py-4"
                        >
                          <RiSearchLine customClass="fs-3 text-dark text-dark position-absolute top-50 translate-middle-y" />{' '}
                          {matchStringToBold(searchText, _sugg)}
                        </a>
                      </span>
                    </li>
                  ))}
                </ul>
              )}

              {/* search history  End*/}
              {/* search speciality */}

              {/* {searchFiltered()?.length > 0 && (
                  <div className="w-100 text-start mt-2 p-4">
                    <h4 className="fs-3 text-black fw-medium mb-3">
                      Quick <span className="text-secondary">Search</span>
                    </h4>
                    {searchFiltered()?.map((_sp, _idx) => (
                    
                      <span
                        key={_idx + 1}
                        className="searchSuggsnTopic rounded-pill cursorPointer mb-3 me-3 bg-white text-nowrap py-3 px-4 mb-20 d-inline-block"
                        onClick={() => onClickSpecSuggestion(_sp)}
                      >
                        {_sp.specialities_name}
                      </span>
                  
                    ))}
                  </div>
                )} */}
              {/* search speciality */}
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default memo(HeaderSearchBar);
