import React from 'react';
import './css/error_fallback.scss';
import '../../../src/assets/css/animation/fallback.css';
import packageJson from '../../../package.json';
export const ErrorFallback = ({ error, info }) => {
  // const { redirectTo } = useRedirect();
  const onReloadClick = () => {
    window.location.reload();
  };

  const onRedirect = () => {
    // redirectTo('Dashboard');
    window.location.reload();
  };
  return (
    <>
      {/* <TrackerComponent page_name="error" module_page="error" /> */}
      <div className="error_container d-flex justify-content-center align-items-center text-white position-sticky overflow-hidden vh-100">
        <div id="clouds">
          <div className="cloud x1"></div>
          <div className="cloud x1_5"></div>
          <div className="cloud x2"></div>
          <div className="cloud x3"></div>
          <div className="cloud x4"></div>
          <div className="cloud x5"></div>
        </div>
        <div className="c">
          <div className="_404 position-relative d-inline-block z-2 h-auto">Oops!</div>
          <div className="_1 position-relative d-block text-center">Something went wrong</div>
          {/* <div className="_2">WAS NOT FOUND</div> */}
          <a
            className="error-btn me-3 bg-white position-relative d-inline-block py-3 px-20 mw-100 fs-3 my-0 mx-auto rounded mt-20 gtm_cl_errorFallback_back_to_home"
            href="javascript:void(0)"
            onClick={onRedirect}
          >
            BACK TO HOME
          </a>
          <a
            href="javascript:void(0)"
            className="error-btn bg-white position-relative d-inline-block py-3 px-20 mw-100 fs-3 my-0 mx-auto rounded mt-20 errorFallback_reload"
            type="button"
            onClick={onReloadClick}
          >
            RELOAD
          </a>
          <h5 className="text-white text-center mt-5 fw-semibold fs-3">
            <p>CLIRNET v{packageJson.version}</p>
          </h5>
        </div>
      </div>
    </>
  );
};
