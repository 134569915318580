import React, { Suspense } from 'react';
import {
  Navigate,
  Outlet,
  Route,
  createHashRouter,
  createRoutesFromElements
} from 'react-router-dom';
import PrivateRoute from '../components/PrivateRoute';

import * as Sentry from '@sentry/react';
import { useSelector } from 'react-redux';
import PublicRoute from '../components/PublicRoute';
import ShareRoute from '../components/ShareRoute';
import RootLayout from '../layout/RootLayout';
import { ErrorFallback } from '../pages/error';
import { layoutedRoutes } from './routes/layoutedRoutes';
import { PrivateRoutes } from './routes/privateRoutes';
import { publicRoutes } from './routes/publicRoutes';
import { shareRoutes } from './routes/shareRoutes';
import LoadingFallback from './fallback/LoadingFallbackNew';
export function useDefaultRouter() {
  const userDetails = useSelector((state) => state.common.user_details);

  const activeModules = {
    medwiki: true,
    spq: true,
    generic: true,
    clinicalVideo: true,
    session: true,
    election: true,
    doctube: true,
    epub: true,
    gr: true,
    dnr: true,
    courses: true,
    speciality: true,
    channel: true,
    telemed: true
  };

  return createHashRouter(
    createRoutesFromElements(
      <Route
        element={
          <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
            <RootLayout />
          </Sentry.ErrorBoundary>
        }
      >
        {publicRoutes.map((elem) => {
          if ('ShareRoute' in elem) {
            if ('subPath' in elem) {
              return (
                <Route path={elem.path}>
                  {elem.subPath &&
                    elem.subPath.map((key) => (
                      <Route
                        key={key}
                        path={key}
                        element={
                          <ShareRoute redirectTypeRoute={elem.redirectTypeRoute}>
                            <Suspense fallback={<LoadingFallback />}>{elem.component}</Suspense>
                          </ShareRoute>
                        }
                      />
                    ))}
                </Route>
              );
            } else {
              return (
                <Route
                  path={elem.path}
                  element={
                    <ShareRoute redirectTypeRoute={elem.redirectTypeRoute}>
                      <Suspense fallback={<LoadingFallback />}>{elem.component}</Suspense>
                    </ShareRoute>
                  }
                />
              );
            }
          } else {
            if ('subPath' in elem) {
              return (
                <Route exact path={elem.path}>
                  {elem.subPath &&
                    elem.subPath.map((subPath) => (
                      <Route
                        exact
                        path={subPath}
                        key={subPath}
                        element={
                          <PublicRoute>
                            <Suspense fallback={<LoadingFallback />}>{elem.component}</Suspense>
                          </PublicRoute>
                        }
                      />
                    ))}
                </Route>
              );
            } else {
              return (
                <Route
                  path={elem.path}
                  element={
                    <PublicRoute>
                      <Suspense fallback={<LoadingFallback />}>
                        <Suspense fallback={<LoadingFallback />}>{elem.component}</Suspense>
                      </Suspense>
                    </PublicRoute>
                  }
                />
              );
            }
          }
        })}
        {shareRoutes.map((elem) => {
          if ('subPath' in elem) {
            return (
              <Route path={elem.path}>
                {elem.subPath &&
                  elem.subPath.map((subPath) => (
                    <Route
                      path={subPath}
                      element={
                        <ShareRoute redirectTypeRoute={elem.redirectTypeRoute}>
                          <Suspense fallback={<LoadingFallback />}>{elem.component}</Suspense>
                        </ShareRoute>
                      }
                    />
                  ))}
              </Route>
            );
          } else {
            return (
              <Route
                path={elem.path}
                element={
                  <ShareRoute redirectTypeRoute={elem.redirectTypeRoute}>
                    <Suspense fallback={<LoadingFallback />}>{elem.component}</Suspense>
                  </ShareRoute>
                }
              />
            );
          }
        })}
        {layoutedRoutes.map((elem) => {
          if ('subpath' in elem) {
            return (
              <Route
                key={elem.subpath[0].path}
                element={
                  userDetails?.spec_dashboard_eligibility_status
                    ? userDetails.specility_update_status !== '0'
                      ? elem.SpecialityLayout
                      : null
                    : elem.layout
                }
              >
                {elem.subpath &&
                  elem.subpath.map((item, index) => {
                    if (item.module in activeModules && !activeModules[item.module]) {
                      <Navigate to="/unavailable" />;
                      return <></>;
                    } else {
                      return (
                        <Route
                          key={item.path}
                          path={item.path}
                          element={
                            <Suspense fallback={<LoadingFallback />}>
                              <PrivateRoute>
                                {userDetails?.spec_dashboard_eligibility_status &&
                                userDetails.specility_update_status !== '0' &&
                                'specialityComponent' in item
                                  ? item.specialityComponent
                                  : item.component}
                              </PrivateRoute>
                            </Suspense>
                          }
                        />
                      );
                    }
                  })}
              </Route>
            );
          }
        })}
        {PrivateRoutes.map((elem) => {
          if ('ShareRoute' in elem) {
            if ('subPath' in elem) {
              return (
                <Route path={elem.path}>
                  {elem.subPath &&
                    elem.subPath.map((subPath) => (
                      <Route
                        path={subPath}
                        element={
                          <Suspense fallback={<LoadingFallback />}>
                            <ShareRoute redirectTypeRoute={elem.redirectTypeRoute}>
                              <Suspense fallback={<LoadingFallback />}>{elem.component}</Suspense>
                            </ShareRoute>
                          </Suspense>
                        }
                      />
                    ))}
                </Route>
              );
            } else {
              return (
                <Route
                  path={elem.path}
                  element={
                    <ShareRoute redirectTypeRoute={elem.redirectTypeRoute}>
                      <Suspense fallback={<LoadingFallback />}>{elem.component}</Suspense>
                    </ShareRoute>
                  }
                />
              );
            }
          } else {
            if ('subPath' in elem) {
              return (
                <Route path={elem.path}>
                  {elem.subPath &&
                    elem.subPath.map((subPath) => (
                      <Route
                        path={subPath}
                        element={
                          <PrivateRoute>
                            <Suspense fallback={<LoadingFallback />}>{elem.component}</Suspense>
                          </PrivateRoute>
                        }
                      />
                    ))}
                </Route>
              );
            } else {
              return (
                <Route
                  path={elem.path}
                  element={
                    <PrivateRoute>
                      <Suspense fallback={<LoadingFallback />}>{elem.component}</Suspense>
                    </PrivateRoute>
                  }
                />
              );
            }
          }
        })}
      </Route>
    ),
    {
      basename: '/'
    }
  );
}
