import { commonConstants } from '../constants/commonConstants';
import { ebookConstants } from '../constants/ebook.constant';

let initialState = {
  ebookList: [],
  error: ''
};

export const ebookReducer = (state = initialState, action) => {
  switch (action.type) {
    case ebookConstants.GET_EBOOKS_SUCCESS:
      return {
        ...state,
        ebookList: [...state.ebookList, ...action.payload.data]
      };

    case ebookConstants.GET_EBOOKS_FAILURE:
    case ebookConstants.GET_EBOOK_DETAILS_FAILURE:
      return {
        ...state,
        error: action.payload.message
      };
    case ebookConstants.GET_EBOOK_DETAILS_SUCCESS:
      return {
        ...state,
        ebookDetails: action.payload.data
      };
      
    case commonConstants.CLEAR_ARRAY_SUCCESS:
      return initialState;

    default:
      return state;
  }
};
