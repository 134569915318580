import { dashboardConstants } from '../constants/dashboard.constant';
import { commonConstants } from '../constants/commonConstants';
import { getLocalStorage } from '../../common/common';
function getUserSpecialityInitialState() {
  let user = getLocalStorage('user', false);
  if (user) {
    let userSpeciality = user?.speciality;
    try {
      return JSON.parse(userSpeciality);
    } catch (error) {
      return [];
    }
  } else {
    return [];
  }
}
let initialState = {
  doctor_voice: [],
  session_list_data: [],
  comp_list_data: [],
  trending_gr_list_data: [],
  survey_list_data: [],
  recent_list_data: [],
  user_speciality_viewing: getUserSpecialityInitialState()
};
export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case dashboardConstants.DOCTOR_VOICE_SUCCESS:
      if (action.payload.data) {
        return {
          ...state,
          doctor_voice: action.payload.data
        };
      } else {
        return {
          ...state,
          doctor_voice: []
        };
      }
    case dashboardConstants.DOCTOR_VOICE_FAILURE:
      return {
        ...state,
        error: action.payload.message,
        doctor_voice: []
      };
    case dashboardConstants.SESSION_SLIDER_SUCCESS:
      if (action.payload.data) {
        return {
          ...state,
          session_list_data: action.payload.data.filter((_d) => _d.trending_type == 'session')
        };
      } else {
        return {
          ...state,
          session_list_data: []
        };
      }
    case dashboardConstants.SESSION_SLIDER_FAILURE:
      return {
        ...state,
        error: action.payload.message,
        session_list_data: []
      };
    case dashboardConstants.TRENDING_COMP_SUCCESS:
      if (action.payload.data) {
        return {
          ...state,
          comp_list_data: action.payload.data.filter((_d) => _d.trending_type == 'comp')
        };
      } else {
        return {
          ...state,
          comp_list_data: []
        };
      }
    case dashboardConstants.TRENDING_COMP_FAILURE:
      return {
        ...state,
        error: action.payload.message,
        comp_list_data: []
      };
    case dashboardConstants.TRENDING_GR_SUCCESS:
      if (action.payload.data) {
        return {
          ...state,
          trending_gr_list_data: action.payload.data.filter((_d) => _d.trending_type == 'gr')
        };
      } else {
        return {
          ...state,
          trending_gr_list_data: []
        };
      }
    case dashboardConstants.TRENDING_GR_FAILURE:
      return {
        ...state,
        error: action.payload.message,
        trending_gr_list_data: []
      };

    case dashboardConstants.TRENDING_SURVEY_SUCCESS:
      if (action.payload.data) {
        return {
          ...state,
          survey_list_data: action.payload.data.filter((_d) => _d.trending_type == 'survey')
        };
      } else {
        return {
          ...state,
          survey_list_data: []
        };
      }
    case dashboardConstants.TRENDING_SURVEY_FAILURE:
      return {
        ...state,
        error: action.payload.message,
        survey_list_data: []
      };
    case dashboardConstants.TRENDING_VIDEO_SUCCESS:
      if (action.payload.data) {
        return {
          ...state,
          video_list_data: action.payload.data.filter((_d) => _d.trending_type == 'video_archive')
        };
      } else {
        return {
          ...state,
          video_list_data: []
        };
      }
    case dashboardConstants.TRENDING_VIDEO_FAILURE:
      return {
        ...state,
        error: action.payload.message,
        video_list_data: []
      };
    case dashboardConstants.RECENT_SUCCESS:
      if (action.payload.data && state.recent_list_data) {
        return {
          ...state,
          recent_list_data: [...state.recent_list_data, ...action.payload.data]
        };
      } else if (state.recent_list_data) {
        return {
          ...state,
          recent_list_data: [...state.recent_list_data]
        };
      }
    case dashboardConstants.RECENT_FAILURE:
      return {
        ...state,
        error: action.payload.message
      };

    case dashboardConstants.CHANGE_USER_SPECIALITY_VIEW:
      return {
        ...state,
        user_speciality_viewing: action.payload
      };

    case commonConstants.CLEAR_ARRAY_SUCCESS:
      return {
        ...state,
        doctor_voice: [],
        session_list_data: [],
        comp_list_data: [],
        trending_gr_list_data: [],
        survey_list_data: [],
        recent_list_data: []
      };

    default:
      return state;
  }
};
