import Loadable from 'react-loadable';
import LoadingFallback from '../fallback/LoadingFallbackNew';
import React from 'react';

const ClinicalVideosShare = Loadable({
  loader: () => import('../../../src/pages/share/ClinicalVideosShare'),
  loading: LoadingFallback
});

const GrShare = Loadable({
  loader: () => import('../../../src/pages/share/GrShare'),
  loading: LoadingFallback
});

const MedwikiShare = Loadable({
  loader: () => import('../../../src/pages/share/MedwikiShare'),
  loading: LoadingFallback
});

const TrainingShare = Loadable({
  loader: () => import('../../../src/pages/share/TrainingShare'),
  loading: LoadingFallback
});

const PollShare = Loadable({
  loader: () => import('../../../src/pages/share/PollShare'),
  loading: LoadingFallback
});

const QuizShare = Loadable({
  loader: () => import('../../../src/pages/share/QuizShare'),
  loading: LoadingFallback
});

const SessionShare = Loadable({
  loader: () => import('../../../src/pages/share/SessionShare'),
  loading: LoadingFallback
});

const ChannelShare = Loadable({
  loader: () => import('../../../src/pages/share/channelShare'),
  loading: LoadingFallback
});

const EbookShare = Loadable({
  loader: () => import('../../../src/pages/share/ebookShare'),
  loading: LoadingFallback
});
const ElectionShare = Loadable({
  loader: () => import('../../../src/pages/share/ElectionShare'),
  loading: LoadingFallback
});

const DnrShare = Loadable({
  loader: () => import('../../../src/pages/share/DnrShare'),
  loading: LoadingFallback
});

const DoctubeShare = Loadable({
  loader: () => import('../../../src/pages/share/DoctubeShare'),
  loading: LoadingFallback
});

const ProfileShare = Loadable({
  loader: () => import('../../../src/pages/share/profileShare'),
  loading: LoadingFallback
});

const DifferentialDiagnosisShare = Loadable({
  loader: () => import('../../../src/pages/share/DiagnosisShare'),
  loading: LoadingFallback
});
const Ddx = Loadable({
  loader: () => import('../../../src/pages/askClirnet/DiagnosisHelp'),
  loading: LoadingFallback
});

export const shareRoutes = [
  {
    path: '/share/gr/:id',
    component: <GrShare />,
    subPath: ['', ':utm'],
    ShareRoute: true,
    redirectTypeRoute: 'GrandRoundDetails'
  },
  {
    path: '/share/training/:id',
    component: <TrainingShare />,
    subPath: [':utm', ''],
    ShareRoute: true,
    redirectTypeRoute: 'TrainingCover'
  },
  {
    path: '/share/differential_diagnosis/:id',
    component: <DifferentialDiagnosisShare />,
    subPath: ['', ':utm'],
    redirectTypeRoute: 'DifferentialDiagnosis',
    ShareRoute: true
  },
  {
    path: '/share/openddx/:id',
    component: <Ddx />,
    subPath: ['', ':utm'],
    redirectTypeRoute: 'DifferentialDiagnosis',
    ShareRoute: true
  },
  {
    path: '/share/medwiki/:id',
    component: <MedwikiShare />,
    subPath: ['', ':utm'],
    redirectTypeRoute: 'MedwikiDetails',
    ShareRoute: true
  },
  {
    path: '/share/Poll/:id',
    redirectTypeRoute: 'PollDetails',
    ShareRoute: true,
    component: <PollShare />,
    subPath: ['', ':utm']
  },
  {
    path: '/share/survey/:id',
    redirectTypeRoute: 'SPQdetails',
    ShareRoute: true,
    component: <QuizShare />,
    subPath: ['', ':utm']
  },
  {
    path: '/share/session/:id',
    redirectTypeRoute: 'SessionDetails',
    ShareRoute: true,
    component: <SessionShare />,
    subPath: ['', ':utm']
  },
  {
    path: '/share/doctorVoice/:id',
    redirectTypeRoute: 'Dashboard',
    ShareRoute: true,
    component: <PollShare />,
    subPath: ['', ':utm']
  },
  {
    path: '/share/channel/:id',
    redirectTypeRoute: 'ChannelProfile',
    ShareRoute: true,
    component: <ChannelShare />,
    subPath: ['', ':utm']
  },
  {
    path: '/share/epub/:id',
    redirectTypeRoute: 'EbookDetails',
    ShareRoute: true,
    component: <EbookShare />,
    subPath: ['', ':utm']
  },
  {
    path: '/share/election/:election_id',
    redirectTypeRoute: 'ElectionLanding',
    ShareRoute: true,
    component: <ElectionShare />,
    subPath: ['', ':utm']
  },
  {
    path: 'share/Dnr',
    redirectTypeRoute: 'DiscussRefer',
    ShareRoute: true,
    component: <DnrShare />,
    subPath: ['', ':utm']
  },
  {
    path: '/share/doctube',
    component: <DoctubeShare />,
    ShareRoute: true,
    redirectTypeRoute: 'Doctube',
    subPath: ['', ':utm']
  },
  {
    path: '/share/doctube_fst',
    component: <DoctubeShare />,
    ShareRoute: true,
    redirectTypeRoute: 'Doctube',
    subPath: ['', ':utm']
  },
  {
    path: '/share/Profile',
    component: <ProfileShare />,
    ShareRoute: true,
    redirectTypeRoute: 'Profile',
    subPath: ['', ':utm']
  },
  {
    path: '/share/archived_video/:id',
    component: <ClinicalVideosShare />,
    subPath: ['', ':utm'],
    ShareRoute: true,
    redirectTypeRoute: 'ClinicalVideoDetails'
  }
];
