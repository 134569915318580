import React, { memo } from 'react';
import ReactGA from 'react-ga4';
import { Outlet } from 'react-router-dom';
import MobileNativeEventsListener from '../capacitor/AppUrlListener';
import { isMobileApp } from '../common/common';

// ReactGA.initialize('G-MMRQERMH4E');
ReactGA.initialize(process.env.REACT_APP_GA_ID_PRODUCTION);

function RootLayout() {
  // ReactGA.send(window.location.pathname + window.location.search);
  // ReactGA.send({
  //   hitType: 'pageview',
  //   page: window.location.href,
  //   title: 'document.title',
  //   location: window.location.href
  // });
  // console.log("RootLayout====================>");
  return (
    <>
      {isMobileApp() && <MobileNativeEventsListener></MobileNativeEventsListener>}
      <Outlet />
    </>
  );
}

export default memo(RootLayout);
