import React, { useEffect, useRef, useState } from 'react';
import { Navbar } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'swiper/css';
import {
  useGetUserCmePointsAchived,
  useGetUserGoal,
  useGetUserGoalAchived
} from '../../../../Queries/QueryHooks/user.hook';
import { getFcmToken, getUserDetails } from '../../../../Store/actions';
import { postProfileImage } from '../../../../Store/actions/profile.action';
import { takePicture } from '../../../../capacitor/imageUpload';
import {
  getSessionStorage,
  handleCompressedUpload,
  isFileAllowed,
  isIosApp,
  isMobileApp,
  setSessionStorage
} from '../../../../common/common';
import { CapacitorFirebaseUpload, firebaseUpload } from '../../../../common/firebase';
import SpecialityProgressBar from '../../../../pages/speciality/SpecialityProgressBar';
import LoadingFallbackNew from '../../../../router/fallback/LoadingFallbackNew';
import LazyImage from '../../../CustomLibraries/LazyImage';

const HeaderProfileMobile = ({ getElementHeight }) => {
  function nextMultipleOfTen(num) {
    return Math.ceil(num / 10) * 10;
  }
  const inputRef = useRef(null);

  const onSuccess = (data) => {
    console.log('goal on success', data);
  };
  const onGetAchivedSuccess = (data) => {
    console.log('goal on success 1', data);
  };
  const onError = () => {};
  const userDetails = useSelector((state) => state.common.user_details);

  const onSuccessCmePoints = (data) => {
    console.log('goal on success cme point', data);
  };
  const { data: goalData, isLoading: goalLoader } = useGetUserGoal(onSuccess, onError);
  const { data: achivedGoalData, isLoading: achivedGoalLoader } = useGetUserGoalAchived(
    onGetAchivedSuccess,
    onError
  );
  const { data: cmePoints, isLoading: cmePointsLoader } =
    useGetUserCmePointsAchived(onSuccessCmePoints);
  const prevScrollPos = useRef(window.pageYOffset);
  const headerSecRef = useRef(0);
  const getHeight = useRef(0);
  const [headerTransform, setHeaderTransform] = useState(0);
  const [progress, setProgress] = useState(0);
  const [firebaseToken, setFirebaseToken] = useState('');
  const [backdrop, setBackdrop] = useState(false);
  const [userGoal, setuserGoal] = useState({
    read: 0,
    watch: 0,
    discuss: 0,
    cme_point: 0
  });
  const [achivedUserGoal, setAchivedUserGoal] = useState({
    article_read: {
      total_goal: 0,
      content_type: 'medwiki'
    },
    video_watched: {
      total_goal: 0,
      content_type: 'archived_video'
    },
    attended_cme: {
      total_goal: 0,
      content_type: 'session'
    }
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFcmToken(setFirebaseToken));
  }, []);
  const handleScroll = () => {
    // alert('Scroll  detected!');
    const currentScrollPos = window?.pageYOffset;
    const headerHeight = headerSecRef?.current.clientHeight;
    if (prevScrollPos?.current > currentScrollPos) {
      // alert('Scroll up detected!', headerHeight);
      if (headerHeight <= 0) {
        setHeaderTransform(headerHeight);
      }
    } else {
      setHeaderTransform(0);
      // alert('Scroll down detected!');
    }
    prevScrollPos.current = currentScrollPos;
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    getElementHeight(getHeight.current.offsetHeight);
  }, [getHeight.current.offsetHeight]);

  useEffect(() => {
    if (goalData || achivedGoalData) {
      console.group('goalData');
      const { user_goal = '' } = goalData || {};
      console.log('goal_on', user_goal);
      let temp_obj = user_goal ? JSON.parse(user_goal) : {};
      if (getSessionStorage('user_goal', false) || getSessionStorage('goal_achieved', false)) {
        let obj_from_storage = JSON.parse(getSessionStorage('user_goal', '{}'));
        let achived_from_storage = JSON.parse(getSessionStorage('goal_achieved', '{}'));
        console.log('achived_from_storage', achived_from_storage, achivedGoalData);
        const {
          article_read = 0,
          attended_cme = 0,
          video_watched = 0
        } = achived_from_storage || {};
        console.log(
          'obj_from_storage',
          obj_from_storage,
          article_read,
          attended_cme,
          video_watched
        );
        console.groupEnd('goalData');

        // setAchivedUserGoal((prev) => {
        //   console.log('prev', prev);
        //   // return { ...prev };
        //   let temp = { ...prev };
        //   temp.article_read.total_goal = Math.max(
        //     parseInt(temp.article_read?.total_goal ?? 0),
        //     parseInt(object_from_storage?.article_read?.total_goal ?? 0)
        //   );
        //   temp.video_watched.total_goal = Math.max(
        //     parseInt(temp.video_watched?.total_goal ?? 0),
        //     parseInt(object_from_storage?.video_watched?.total_goal ?? 0)
        //   );
        //   temp.attended_cme.total_goal = Math.max(
        //     parseInt(temp.attended_cme?.total_goal ?? 0),
        //     parseInt(object_from_storage?.attended_cme?.total_goal ?? 0)
        //   );
        //   console.log('temp', temp, object_from_storage);
        //   return temp;
        // });

        setuserGoal((prev) => {
          let temp = { ...prev };
          temp.read = Math.max(
            temp_obj?.read,
            nextMultipleOfTen(parseInt(article_read?.total_goal ?? 0)),
            nextMultipleOfTen(parseInt(achivedGoalData?.goal_achieved?.article_read?.total_goal))
          );
          temp.watch = Math.max(
            temp_obj?.watch,
            nextMultipleOfTen(parseInt(video_watched?.total_goal ?? 0)),
            nextMultipleOfTen(parseInt(achivedGoalData?.goal_achieved?.video_watched?.total_goal))
          );
          temp.discuss = Math.max(
            temp_obj?.discuss,
            nextMultipleOfTen(parseInt(attended_cme?.total_goal ?? 0)),
            nextMultipleOfTen(parseInt(achivedGoalData?.goal_achieved?.attended_cme?.total_goal))
          );
          temp.cme_point =
            cmePoints > temp_obj?.cme_point
              ? nextMultipleOfTen(parseInt(cmePoints))
              : nextMultipleOfTen(parseInt(temp_obj?.cme_point));

          console.log('temptest', temp_obj, achived_from_storage, temp, achivedUserGoal);
          return temp;
        });
      } else if (user_goal) {
        console.log('user_goal', user_goal, achivedGoalData);
        setuserGoal(JSON.parse(user_goal));
        setSessionStorage('user_goal', user_goal);
      } else {
        setuserGoal({
          read: 0,
          watch: 0,
          discuss: 0,
          cme_point: 0
        });
      }
    }
    return () => {};
  }, [goalData, cmePoints, achivedGoalData]);

  useEffect(() => {
    console.group('achivedGoalData');
    if (achivedGoalData) {
      const { goal_achieved } = achivedGoalData;
      console.log('goal on 2', goal_achieved);
      if (getSessionStorage('goal_achieved', false)) {
        let object_from_storage = JSON.parse(getSessionStorage('goal_achieved', '{}'));
        console.log('object_from_storage', object_from_storage);
        if (
          object_from_storage &&
          typeof object_from_storage == 'object' &&
          Object.keys(object_from_storage).length > 0
        ) {
          console.log('goal_achieved 1', object_from_storage);

          setAchivedUserGoal((prev) => {
            console.log('prev', prev);
            // return { ...prev };
            let temp = { ...prev };
            temp.article_read.total_goal = Math.max(
              parseInt(temp.article_read?.total_goal ?? 0),
              parseInt(object_from_storage?.article_read?.total_goal ?? 0)
            );
            temp.video_watched.total_goal = Math.max(
              parseInt(temp.video_watched?.total_goal ?? 0),
              parseInt(object_from_storage?.video_watched?.total_goal ?? 0)
            );
            temp.attended_cme.total_goal = Math.max(
              parseInt(temp.attended_cme?.total_goal ?? 0),
              parseInt(object_from_storage?.attended_cme?.total_goal ?? 0)
            );
            console.log('temp', temp, object_from_storage);
            return temp;
          });
        } else {
          console.log('goal_achieved 2', goal_achieved);
        }
      } else {
        console.log('goal_achieved 3', goal_achieved);
        setAchivedUserGoal(goal_achieved);
        setSessionStorage('goal_achieved', JSON.stringify(goal_achieved));
      }
    }
    console.groupEnd('achivedGoalData');
    return () => {};
  }, [achivedGoalData]);

  const parseSpecialities = (jsonStr) => {
    const jsonObj = JSON.parse(jsonStr);
    return jsonObj.map((obj) => obj.specialities_name).join(', ');
  };
  const navigate = useNavigate();

  const postMessageReactNative = async () => {
    const { Capacitor } = await import('@capacitor/core');
    if (Capacitor.getPlatform() == 'ios' || 'android') {
      // console.log("platform at profile ==>", Capacitor.getPlatform())
      takePicture((compressedFile) => {
        if (!compressedFile) {
          return false;
        }
        // console.log('compressedFile', compressedFile);
        setBackdrop(true);
        // handleCompressedUpload(largeFile, (compressedFile) => {
        CapacitorFirebaseUpload(
          compressedFile,
          firebaseToken,
          ['jpg', 'jpeg', 'png'],
          (_p) => setProgress(_p),
          (url, fileName) => {
            dispatch(
              postProfileImage({ image: url }, (res) => {
                // console.log('my res ==?', JSON.stringify(res));
                if (res != 'error') {
                  dispatch(getUserDetails(() => {}));
                  setBackdrop(false);
                  // dispatch(renderHeaderOnce());
                  toast.success('Profile picture updated successfully');
                  setProgress(0);
                }
              })
            );
          },
          () => {
            setBackdrop(false);
            setProgress(0);
          }
        );
        // })
      });
    }
  };

  const uploadProfileImage = (e) => {
    console.log('fileupload', e);
    if (!e) return;
    if (isFileAllowed(e)) {
      handleCompressedUpload(e, (compressedFile) => {
        setBackdrop(true);
        firebaseUpload(
          compressedFile,
          firebaseToken,
          ['jpg', 'jpeg', 'png'],
          (_p) => setProgress(_p),
          (url, fileName) => {
            dispatch(
              postProfileImage({ image: url }, (res) => {
                if (res != 'error') {
                  dispatch(getUserDetails(() => {}));
                  setBackdrop(false);
                  // dispatch(renderHeaderOnce());
                  toast.success('Profile picture updated successfully');
                  setProgress(0);
                }
              })
            );
          },
          () => {
            setBackdrop(false);
            setProgress(0);
          }
        );
      });
    } else {
      toast.error('Maximum allowed file size : 2 MB');
    }
  };

  const HandleAppReload = () => {
    window.location.reload();
  };

  return (
    <>
      <Navbar
        ref={getHeight}
        className="clr_v3_Header position-relaitive navbar pt-0 m-0 top-0 bg-white w-100 flex-column overflow-hidden pb-4 clr_v3_Header--mobile clr_v3_Header--profile"
        style={{ transform: `translateY(-${headerTransform}px)` }}
      >
        <div className="position-relative w-100" ref={headerSecRef}>
          <div className="clr_v3_Header__navbar position-relative px-sm-4 px-3">
            <div className="clr_v3_Header__background position-absolute top-0 start-0 w-100 h-100 d-flex align-items-end">
              <img
                src="https://img.freepik.com/premium-photo/medicine-doctor-hold-medical-cross-icon-healthcare-network-innovation-healthcare-technology_34200-496.jpg?w=996"
                alt="cover"
                className="object-fit-cover w-100 h-100 position-absolute"
              />
              <div className="overlay bg-primary" style={{ opacity: 0.9 }}></div>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="375"
                height="14"
                viewBox="0 0 375 14"
                className="clr_v3_Header__svgShape z-2 position-relative"
              >
                <path
                  id="Path_1495"
                  data-name="Path 1495"
                  d="M0,0S96.25,11.121,190,11.121,375,0,375,0V14H0Z"
                  fill="#fff"
                />
              </svg>
            </div>
            {isIosApp() && <div className="iosSpecial-safe-area-top w-100"></div>}
            <nav className="d-flex justify-content-between align-items-center py-4 position-relative z-2 text-white">
              <div
                className="me-auto d-flex align-items-center jusstify-content-center fs-3 fw-semibold"
                onClick={() => navigate(-1)}
              >
                <span className="me-3 d-inline-block">
                  <i className="flaticon-back fs-2"></i>
                </span>
                Profile
              </div>
              <div className="ms-auto clr_v3_Header__right">
                {/* <a href="#" className="ms-auto fs-16 text-white">
                <i className="flaticon-plus me-2 d-inline-block align-middle fs-2"></i>
                {parseSpecialities(userDetails?.speciality)}
              </a> */}
                {isMobileApp() && (
                  <ul className="d-flex p-0 align-items-center list-unstyled fs-30 mb-0 gap-3 gap-sm-4">
                    <li onClick={HandleAppReload}>
                      <a
                        href="javascript:void(0)"
                        className="bg-white ratio-1 rounded-circle"
                        style={{ '--bs-bg-opacity': '.1' }}
                      >
                        <i className="flaticon-undo fs-30"></i>
                      </a>
                    </li>
                  </ul>
                )}
              </div>
            </nav>
            <div className="z-2 position-relative d-flex flex-column clr_v3_Header--profile__details mt-3">
              <div className="d-flex align-items-center mb-4">
                <div className="rounded-circle p-2 border border-2 border-white position-relative mx-auto">
                  <div className="overflow-hidden clr_v3_userImage-lg rounded-circle position-relative">
                    {userDetails && userDetails?.profile_image && (
                      <LazyImage
                        src={userDetails?.profile_image}
                        width=""
                        height=""
                        alt="Dr. User Image"
                        className="object-fit-cover w-100 h-100 float-start"
                      />
                    )}
                    {/* <input
                  type="file"
                  className="position-absolute start-0 top-0 w-100 h-100 opacity-0 z-1"
                  onChange={(e) => uploadProfileImage(e.target.files[0])}
                /> */}
                  </div>
                  <div
                    className="position-absolute end-0 bottom-0 btn bg-white rounded-circle d-flex align-items-center justify-content-center z-2 p-0 icon-size-32 overflow-hidden cursorPointer"
                    onClick={() => isMobileApp() && postMessageReactNative()}
                  >
                    {!isMobileApp() ? (
                      <>
                        <input
                          type="file"
                          className="position-absolute start-0 w-100 h-100 top-0 opacity-0 z-n1"
                          onChange={(e) => uploadProfileImage(e.target.files[0])}
                          ref={inputRef}
                        />
                        <i
                          className="flaticon-camera fs-3 text-primary"
                          onClick={() => !isMobileApp() && inputRef.current.click()}
                        ></i>
                      </>
                    ) : (
                      <i className="flaticon-camera fs-3 text-primary"></i>
                    )}
                  </div>
                </div>

                <div className="text-white flex-1 d-flex flex-column ms-3">
                  {(userDetails?.first_name ||
                    userDetails?.last_name ||
                    userDetails?.middle_name) && (
                    <h2 className="fs-16 fw-semibold">{`${userDetails?.first_name} ${userDetails?.middle_name} ${userDetails?.last_name}`}</h2>
                  )}
                  {userDetails?.speciality && (
                    <p className="fs-4">{parseSpecialities(userDetails?.speciality)}</p>
                  )}
                </div>
              </div>
              {/* <div className="d-flex align-items-start text-start mt-3">
              <div className="text-white flex-1 me-auto">
                <h2 className="fs-16 fw-semibold">Prof. Dr. Shefali Agarwal</h2>
                <p>MBBS ,MD, MS, FACS</p>
              </div>
              <a
                href="javascript:void(0)"
                className="btn btn-light rounded-circle d-flex align-items-center justify-content-center ms-3 p-0 icon-size-32"
              >
                <i className="flaticon-draw"></i>
              </a>
            </div> */}
            </div>
          </div>
        </div>
        {!goalLoader && !achivedGoalLoader && userGoal && achivedUserGoal && (
          <div className="px-sm-4 px-3 mt-4 w-100 position-relative z-1 d-flex overflow-hidden">
            {/* <div className="px-2 d-flex flex-column text-center">
          <div className="clr_v3_profile__progressCircle mb-3 text-center success">
            <CircularProgressbarWithChildren className="w-100 h-100" value="20">
              <p className="fs-3 text-black fw-semibold lh-1">80%</p>
            </CircularProgressbarWithChildren>
          </div>
          <h4 className="fs-4 fw-medium text-black">Weekly</h4>
        </div>
        <div className="px-2 d-flex flex-column text-center">
          <div className="clr_v3_profile__progressCircle mb-3 text-center warning">
            <CircularProgressbarWithChildren className="w-100 h-100" value="20">
              <p className="fs-3 text-black fw-semibold lh-1">60%</p>
            </CircularProgressbarWithChildren>
          </div>
          <h4 className="fs-4 fw-medium text-black">Monthly</h4>
        </div>
        <div className="px-2 d-flex flex-column text-center">
          <div className="clr_v3_profile__progressCircle mb-3 text-center danger">
            <CircularProgressbarWithChildren className="w-100 h-100" value="20">
              <p className="fs-3 text-black fw-semibold lh-1">20%</p>
            </CircularProgressbarWithChildren>
          </div>
          <h4 className="fs-4 fw-medium text-black">Yearly</h4>
        </div> */}
            <div className="row mt-n3 ms-n3 me-0">
              {userGoal?.read && (
                <div className="col-6 ps-3 pe-0 pt-3">
                  <div className="p-4 border border-light rounded-3">
                    <SpecialityProgressBar
                      title="Article Read"
                      rating={`${achivedUserGoal?.article_read?.total_goal ?? 0}/${userGoal?.read}`}
                    />
                  </div>
                </div>
              )}
              <div className="col-6 ps-3 pe-0 pt-3">
                <div className="p-4 border border-light rounded-3">
                  <SpecialityProgressBar
                    title="Videos Watched"
                    rating={`${achivedUserGoal?.video_watched?.total_goal ?? 0}/${userGoal?.watch}`}
                  />
                </div>
              </div>
              <div className="col-6 ps-3 pe-0 pt-3">
                <div className="p-4 border border-light rounded-3">
                  <SpecialityProgressBar
                    title="Attended CME"
                    rating={`${achivedUserGoal?.attended_cme?.total_goal ?? 0}/${
                      userGoal?.discuss
                    }`}
                  />
                </div>
              </div>
              <div className="col-6 ps-3 pe-0 pt-3">
                <div className="p-4 border border-light rounded-3">
                  <SpecialityProgressBar
                    title="CME Points"
                    rating={`${cmePoints ?? 0}/${userGoal?.cme_point}`}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </Navbar>
      {backdrop && <LoadingFallbackNew />}
    </>
  );
};

export default HeaderProfileMobile;
