import { log } from '../../common/common';
import { profileConstants } from '../constants/profile.constant';
import { logoutUser } from './login.action';
import axiosInstance from '../helper/axiosInstance';

const getEducation = (payload) => {
  return {
    type: profileConstants.GET_ALL_EDUCATION,
    payload
  };
};
const getMembership = (payload) => {
  return {
    type: profileConstants.GET_ALL_MEMBERSHIP,
    payload
  };
};
const getAchievement = (payload) => {
  return {
    type: profileConstants.GET_ALL_ACHIEVEMENT,
    payload
  };
};
const getRegistration = (payload) => {
  return {
    type: profileConstants.GET_ALL_REGISTRATION,
    payload
  };
};
export const getAllProfileList = (callback) => {
  return async (dispatch) => {
    axiosInstance
      .get('profile/profile_detail')
      .then((response) => {
        if (response.data) {
          callback(response.data.data);
          log('achv');
          dispatch(getEducation(response.data?.data?.education ?? []));
          dispatch(getMembership(response.data?.data?.membership ?? []));
          dispatch(getRegistration(response.data?.data?.registration ?? []));
          dispatch(getAchievement(response.data?.data?.achievement ?? []));
        }
      })
      .catch((error) => {
        log('error', error);
        callback('error');
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
      });
  };
};
export const getAllEducation = (callback) => {
  return async (dispatch) => {
    axiosInstance
      .get('profile/get_all_education')
      .then((response) => {
        if (response.data) {
          callback(response.data.data);
          dispatch(getEducation(response.data.data));
        }
      })
      .catch((error) => {
        log('error', error);
        callback('error');
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
      });
  };
};
export const getAllMembership = (callback) => {
  return async (dispatch) => {
    axiosInstance
      .get('profile/get_all_membership')
      .then((response) => {
        if (response.data) {
          callback(response.data.data);
          dispatch(getMembership(response.data.data));
        }
      })
      .catch((error) => {
        log('error', error);
        callback('error');
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
      });
  };
};
export const getAllRegistration = (callback) => {
  return async (dispatch) => {
    axiosInstance
      .get('profile/get_all_registration')
      .then((response) => {
        if (response.data) {
          callback(response.data.data);
          dispatch(getRegistration(response.data.data));
        }
      })
      .catch((error) => {
        log('error', error);
        callback('error');
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
      });
  };
};
export const getAllAchievement = (callback) => {
  return async (dispatch) => {
    axiosInstance
      .get('profile/get_all_achievement')
      .then((response) => {
        if (response.data) {
          callback(response.data.data);
          dispatch(getAchievement(response.data.data));
        }
      })
      .catch((error) => {
        log('error', error);
        callback('error');
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
      });
  };
};

export const addEducation = (jsonObj, callback) => {
  return async (dispatch) => {
    axiosInstance
      .post('profile/submit_education', jsonObj)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
      });
  };
};
export const editEducation = (jsonObj, callback) => {
  return async (dispatch) => {
    axiosInstance
      .post('profile/edit_education', jsonObj)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
      });
  };
};
export const deleteEducation = (jsonObj, callback) => {
  return async (dispatch) => {
    axiosInstance
      .post('profile/delete_education', jsonObj)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        log('error', error);
        callback('error');
      });
  };
};
export const addMembership = (jsonObj, callback) => {
  return async (dispatch) => {
    axiosInstance
      .post('profile/submit_membership', jsonObj)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        log('error', error);
        callback('error');
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
      });
  };
};
export const editMembership = (jsonObj, callback) => {
  return async (dispatch) => {
    axiosInstance
      .post('profile/edit_membership', jsonObj)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        log('error', error);
        callback('error');
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
      });
  };
};
export const deleteMembership = (jsonObj, callback) => {
  return async (dispatch) => {
    axiosInstance
      .post('profile/delete_membership', jsonObj)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        log('error', error);
        callback('error');
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
      });
  };
};
export const addAchievement = (jsonObj, callback) => {
  return async (dispatch) => {
    axiosInstance
      .post('profile/submit_achievement', jsonObj)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        log('error', error);
        callback('error');
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
      });
  };
};
export const editAchievement = (jsonObj, callback) => {
  return async (dispatch) => {
    axiosInstance
      .post('profile/edit_achievement', jsonObj)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        log('error', error);
        callback('error');
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
      });
  };
};
export const deleteAchievement = (jsonObj, callback) => {
  return async (dispatch) => {
    axiosInstance
      .post('profile/delete_achievement', jsonObj)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        log('error', error);
        callback('error');
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
      });
  };
};
export const addRegistration = (jsonObj, callback) => {
  return async (dispatch) => {
    axiosInstance
      .post('profile/submit_registration', jsonObj)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        log('error', error);
        callback('error');
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
      });
  };
};
export const editRegistration = (jsonObj, callback) => {
  return async (dispatch) => {
    axiosInstance
      .post('profile/edit_registration', jsonObj)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        log('error', error);
        callback('error');
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
      });
  };
};
export const deleteRegistration = (jsonObj, callback) => {
  return async (dispatch) => {
    axiosInstance
      .post('profile/delete_registration', jsonObj)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        log('error', error);
        callback('error');
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
      });
  };
};
export const editDescription = (jsonObj, callback) => {
  return async (dispatch) => {
    axiosInstance
      .post('profile/add_profile_about', jsonObj)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        log('error', error);
        callback('error');
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
      });
  };
};
export const postProfileImage = (jsonObj, callback) => {
  return async (dispatch) => {
    axiosInstance
      .post('profile/update_profile_image', jsonObj)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        log('error', error);
        callback('error');
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
      });
  };
};

export const updateSpecialityName = (jsonObj, callback) => {
  return async (dispatch) => {
    axiosInstance
      .post('profile/updateNew', jsonObj)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        log('error', error);
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        callback('error');
      });
  };
};
export const changeSettings = (formData, callback) => {
  return async (dispatch) => {
    axiosInstance
      .post('settings/change', formData)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        log('error', error);
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        callback('error');
      });
  };
};

export const updateEmailWithOutVerification = (formData, callback) => {
  return async (dispatch) => {
    axiosInstance
      .post('settings/updateemail', formData)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        log('error', error);
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        callback('error');
      });
  };
};
export const otpVerifyForChange = (formData, callback) => {
  return async (dispatch) => {
    axiosInstance
      .post('settings/otp', formData)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        log('error', error);
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        callback('error');
      });
  };
};
export const fetchWatchedVideos = (from, to, callback) => {
  return async (dispatch) => {
    axiosInstance
      .get(`knwlg/historyvideo?from=${from}&to=${to}`)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        log('error', error);
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        callback('error');
      });
  };
};

export const sendUserGoal = (formData, callback) => {
  return async (dispatch) => {
    axiosInstance
      .post('cpd/addanalytics', formData)
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        log('error', error);
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        callback('error');
      });
  };
};
export const getCPDlist = (pageParam, callback) => {
  return async (dispatch) => {
    axiosInstance
      .get(`cpd/list?&from=${pageParam}&to=10`)
      .then((response) => {
        if (response?.data) {
          callback(response?.data?.data ?? null);
        }
      })
      .catch((error) => {
        log('error', error);
        if (error == 'loginAgain' || error?.name == 'InvalidTokenError') {
          dispatch(logoutUser());
        }
        callback(null);
      });
  };
};
//axiosInstance.get(`cpd/list?&from=${pageParam}&to=10`)
//https://developer.clirnet.com/knowledge/rnv28/knwlg/historyvideo?from=0&to=100
//'settings/change'
