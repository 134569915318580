import {
  certified_courses_static_image,
  clinical_Video_static_image,
  ebook_static_image,
  grand_rounds_static_image,
  medwiki_static_image,
  quizzes_static_image,
  surveys_static_image
} from './assets.common';

export const default_images = {
  medwiki: medwiki_static_image,
  clinical: clinical_Video_static_image,
  survey: surveys_static_image,
  session:
    'https://previews.123rf.com/images/varijanta/varijanta1604/varijanta160400086/55848266-modern-thin-line-design-concept-for-healthcare-website-banner-vector-illustration-concept-for-health.jpg',
  gr: grand_rounds_static_image,
  quiz: quizzes_static_image,
  training: certified_courses_static_image,
  epub: ebook_static_image
};
