import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { getLocalStorage } from '../common/common';
import { useDispatch, useSelector } from 'react-redux';
import { loginFailure } from '../Store/actions/login.action';
const PublicRoute = ({ children }) => {
  const dispatch = useDispatch();
  //  const userDetails = useSelector((state) => state.common.user_details);

  useEffect(() => {
    console.log('public');
    if (!getLocalStorage('isLoggedIn', false)) {
      dispatch(loginFailure());
      console.log('public - isLoggedIn', getLocalStorage('isLoggedIn', false));
    }
  }, []);

  const BaseComponent = () => {
    const [userDetails, setUserDetails] = useState(
      getLocalStorage('user', false) ? getLocalStorage('user', {}) : null
    );
    return (
      <>
        {userDetails && userDetails?.specility_update_status == '0' ? (
          <Navigate to="/onboarding" />
        ) : (
          <Navigate to="/dashboard" />
        )}
      </>
    );
  };

  return (
    // <>
    //   {getLocalStorage('isLoggedIn', false) ? <BaseComponent /> : children}
    // </>
    children
  );
};
export default PublicRoute;
