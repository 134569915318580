import React, { memo } from 'react';
import 'swiper/css';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
SwiperCore.use([Pagination, Navigation]);

const CommonSuggestionSection = ({
  card,
  title = '',
  titleType = '',
  cardData = [],
  colorDecider = '',
  btnText = 'View All',
  fullWidth = false,
  onViewAllClick = null
}) => {
  console.log('cardData', cardData[0]);
  return (
    <div className={`p-sm-4 p-3 w-100 position-relative ${colorDecider ?? ''}`}>
      <div
        className={`clr_v3_borderGraphics clr_v3_borderGraphics-big py-5  w-100 start-0 translate-middle-y top-50 position-absolute d-flex justify-content-between ${
          colorDecider == 'bg-primary' ? 'bg-primary' : ''
        }`}
      >
        <div
          className={`h-100 ${
            colorDecider == 'bg-primary' ? 'bg-white' : 'bg-primary'
          } bg-primary rounded-4 clr_v3_borderGraphics__line`}
        ></div>
        <div
          className={`h-100 ${
            colorDecider == 'bg-primary' ? 'bg-white' : 'bg-primary'
          } rounded-4 clr_v3_borderGraphics__line`}
        ></div>
      </div>
      <div className="d-flex justify-content-center align-items-end mb-4 text-start position-relative z-1">
        <h2
          className={`flex-1 me-3 fw-semibold fs-3 ${
            colorDecider == 'bg-primary' ? 'text-white' : 'text-black'
          }`}
        >
          <span className="fs-4 fw-normal d-block">
            {/* Browse */}
            {titleType}
          </span>
          {/* Other Specialities */}
          {title}
        </h2>

        <a
          roll="button"
          onClick={onViewAllClick}
          className={`fs-4 fw-semibold ms-auto d-inline-flex gap-2 align-items-center clr_v3_borderGraphics__ttl_link cursorPointer ${
            colorDecider == 'bg-primary' ? 'text-white' : 'text-primary'
          }`}
        >
          {btnText} <i className="flaticon-chevron fs-16"></i>
        </a>
      </div>
      <div className="position-relative z-1">
        {cardData && cardData.length > 1 ? (
          <Swiper className="" loop={false} slidesPerView={'auto'}>
            {cardData &&
              cardData.map((_i) => (
                <SwiperSlide className={`${fullWidth ? 'w-100' : 'w-auto'} pe-2`}>
                  {card(_i)}
                </SwiperSlide>
              ))}
          </Swiper>
        ) : (
          card(cardData[0])
        )}
      </div>
    </div>
  );
};

export default memo(CommonSuggestionSection);
