import { commonConstants } from '../constants/commonConstants';
import { discussReferConstants } from '../constants/discussRefer.constant';

let initialState = {
  landingPageData: {},
  hospitalProfile: {},
  recommended: [],
  activities: [],
  error: ''
};

export const discussReferReducer = (state = initialState, action) => {
  switch (action.type) {
    case discussReferConstants.FETCH_LANDING_SUCCESS:
      return {
        ...state,
        landingPageData: action.payload.data
      };

    case discussReferConstants.FETCH_LANDING_FAILURE:
      return {
        ...state,
        error: action.payload.message
      };
    case discussReferConstants.GET_HOSPITAL_PROFILE_SUCCESS:
      return {
        ...state,
        hospitalProfile: action.payload.data
      };

    case discussReferConstants.GET_HOSPITAL_PROFILE_FAILURE:
      return {
        ...state,
        error: action.payload.message
      };
    case discussReferConstants.GET_RECOMMENDED_SUCCESS:
      return {
        ...state,
        recommended: [...state.recommended, ...action.payload.data.data]
      };

    case discussReferConstants.GET_HOSPITAL_PROFILE_FAILURE:
      return {
        ...state,
        error: action.payload.message
      };
    case discussReferConstants.GET_ACTIVITIES_SUCCESS:
      return {
        ...state,
        activities: action.payload.data.data
      };

    case discussReferConstants.GET_ACTIVITIES_FAILURE:
      return {
        ...state,
        error: action.payload.message
      };
    case commonConstants.CLEAR_ARRAY_SUCCESS:
      return initialState;
    default:
      return state;
  }
};
