import { toast } from 'react-toastify';

// let checkPermissionCount = 0;

export const takePicture = async (callback) => {
  const { Camera, CameraResultType, CameraDirection, CameraSource } = await import(
    '@capacitor/camera'
  );
  const permissions = await Camera.requestPermissions();
  if (permissions.photos != 'granted' || permissions.camera != 'granted') {
    toast.warn(
      'Permission for Photos and Camera has been disabled. Please enable them in the settings to use this feature.'
    );
  } else {
    const photoImage = await Camera.getPhoto({
      quality: 100,
      resultType: CameraResultType.Uri,
      source: CameraSource.Prompt,
      allowEditing: false,
      saveToGallery: false
    });
    let image = photoImage;

    if (image) {
      // console.log('my image==>', JSON.stringify(image));
      const base64Data = await readAsBase64(image);
      // console.log('my base64Data==>', JSON.stringify(base64Data));
      const fileName = new Date().getTime() + '.jpeg';

      let myObj = {
        name: fileName,
        data: base64Data
      };
      // console.log('myObj==>', JSON.stringify(myObj));
      let compressedFile = myObj;
      callback(compressedFile);
    }
  }
};

const readAsBase64 = async (photo) => {
  const { Filesystem } = await import('@capacitor/filesystem');
  const file = await Filesystem.readFile({
    path: photo.path
  });
  // console.log('my file base 64==>', JSON.stringify(file));
  return file.data;
};
