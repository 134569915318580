import React, { useEffect, useRef, useState } from 'react';
import { Navbar } from 'react-bootstrap';
// import SwiperCore, { Navigation, Pagination } from 'swiper';
// import 'swiper/css';
import packageJSon from '../../../../../package.json';
import '../../../../assets/sass/uiV3/component/_mobileBottomNav.scss';
import useRedirect from '../../../customHooks/useRedirect';

import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import axiosInstance from '../../../../Store/helper/axiosInstance';
import { getLocalStorage, isIosApp, isMobileApp, reactHtmlParser } from '../../../../common/common';
import CommonSuggestionSection from '../../../../pages/uiv3/dashboard/components/CommonSuggestionSection';
import SpecialityCard from '../../cards/SpecialityCard';
import HeaderSpecialityMobile from '../header/HeaderSpecialityMobile';
import { changeExploreKey } from '../../../../Store/actions';
import HeaderSpecialityDetailsMobile from '../header/HeaderSpecialityDetailsMobile';
import { DEFAULT_MENU } from '../../../../Store/constants/app.constants';

// SwiperCore.use([Pagination, Navigation]);
const MobileFooterNav = ({ height, getElementBottomHeight }) => {
  const [exploreMenuOpen, setExploreMenu] = useState(false);
  const exploreMenuToggler = () => {
    setExploreMenu((prev) => !prev);
  };
  const UserRegion = useSelector((state) => state.share.UserRegion);

  const getHeight = useRef(0);
  const [specialityList, setSpecialityList] = useState([]);
  const location = useLocation();
  const exploreKey = useSelector((state) => state.common.exploreKey);

  useEffect(() => {
    console.log('exploreMenuOpen', exploreMenuOpen);
    if (exploreMenuOpen) {
      document.body.style.overflow = 'hidden';
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
    } else {
      document.body.style.overflow = null;
      document.body.style.width = null;
      document.body.style.position = 'relative';
    }
  }, [exploreMenuOpen]);

  const { redirectTo } = useRedirect();
  const user_details = useSelector((state) => state.common.user_details);

  const [menuJson, setMenuJson] = useState();
  // getLocalStorage('user', false) ? JSON.parse(getLocalStorage('user', {})?.menu) : ''

  useEffect(() => {
    if (user_details && JSON.parse(user_details?.menu)) {
      if (
        'all_menu' in JSON.parse(user_details.menu) &&
        JSON.parse(user_details.menu).all_menu.length > 0
      )
        setMenuJson(JSON.parse(user_details?.menu));
      else setMenuJson(DEFAULT_MENU);
    }
  }, [user_details]);

  useEffect(() => {
    if (getElementBottomHeight && getHeight.current.offsetHeight) {
      getElementBottomHeight(getHeight.current.offsetHeight);
    }
  }, [getHeight.current.offsetHeight]);

  const onGroupClick = () => {
    if (exploreMenuOpen) {
      exploreMenuToggler();
    }
    dispatch(changeExploreKey(null));
    redirectTo('Channel');
  };
  const onDoctubeClick = () => {
    if (exploreMenuOpen) {
      exploreMenuToggler();
    }
    dispatch(changeExploreKey(null));

    redirectTo('Doctube');
  };
  const onDashboardClick = () => {
    if (exploreMenuOpen) {
      exploreMenuToggler();
    }
    dispatch(changeExploreKey(null));

    redirectTo('Dashboard');
  };
  // const onExploreClick = () => {};
  const onSearchClick = () => {
    if (exploreMenuOpen) {
      exploreMenuToggler();
    }
    dispatch(changeExploreKey(null));

    redirectTo('SearchResult');
  };
  const onNotificationClick = () => {
    dispatch(changeExploreKey(null));

    redirectTo('Notification');
  };
  const logout = (type) => {
    if (type == 'logout') {
      redirectTo('Login');
      // dispatch(logoutUser());
    }
  };

  const redirectToDashboard = () => {
    dispatch(changeExploreKey(null));

    if (exploreMenuOpen) {
      exploreMenuToggler();
    }
    redirectTo('Dashboard');
  };

  const onChangeSpecialityClick = () => {
    console.log('onChangeSpecialityClick');
    redirectTo('SelectSpeciality');
  };

  const dispatch = useDispatch();

  const onMenuClick = (pathname, name, dontSetExploreKey = false) => {
    //  remove / from pathname
    if (!dontSetExploreKey) {
      dispatch(changeExploreKey(name));
      exploreMenuToggler();
    } else {
      dispatch(changeExploreKey(null));

    }
    let redirectPath = pathname.replace('/', '');
    console.log('onMenuClick', redirectPath);
    redirectTo(redirectPath);

  };
  const onViewAllSpecialityClick = () => {
    dispatch(changeExploreKey(null));
    if (exploreMenuOpen) exploreMenuToggler();
    redirectTo('AllSpecialities');
  };

  const selectedNavItem = (data) => {
    setNavItem(data);
  };

  const getElementHeight = (data) => { };

  useEffect(() => {
    axiosInstance.get('/speciality/get_all_specialities').then((res) => {
      console.log('res.data.data', res.data.data);
      setSpecialityList(res.data.data);
    });
  }, []);

  useEffect(() => {
    console.log('exploreMenuOpen => ', exploreMenuOpen);
  }, [exploreMenuOpen]);

  const current_menu = useSelector((state) => state.common.current_menu);
  const [currentMenu, setCurrentMenu] = useState(null);

  useEffect(() => {
    console.log('current_menu', current_menu);
    if (current_menu != '') {
      setCurrentMenu(null);
    } else {
      if (current_menu?.toLowerCase()?.includes('dashboard')) {
        setCurrentMenu('dashboard');
      } else if (current_menu?.toLowerCase()?.includes('session')) {
        setCurrentMenu('session');
      } else if (current_menu?.toLowerCase()?.includes('medwiki')) {
        setCurrentMenu('medwiki');
      } else if (current_menu?.toLowerCase()?.includes('spq')) {
        setCurrentMenu('spq');
      } else if (current_menu?.toLowerCase()?.includes('clinicalvideo')) {
        setCurrentMenu('clinical');
      } else if (current_menu?.toLowerCase()?.includes('gr')) {
        setCurrentMenu('grandround');
      } else if (current_menu?.toLowerCase()?.includes('ebook')) {
        setCurrentMenu('ebook');
      } else if (current_menu?.toLowerCase()?.includes('channel')) {
        setCurrentMenu('channel');
      } else if (current_menu?.toLowerCase()?.includes('telemed')) {
        setCurrentMenu('telemed');
      } else if (current_menu?.toLowerCase()?.includes('discussrefer')) {
        setCurrentMenu('discussrefer');
      } else if (current_menu?.toLowerCase()?.includes('training')) {
        setCurrentMenu('training');
      } else if (current_menu?.toLowerCase()?.includes('doctube')) {
        setCurrentMenu('doctube');
      } else if (current_menu?.toLowerCase()?.includes('speciality')) {
        setCurrentMenu('speciality');
      } else if (current_menu?.toLowerCase()?.includes('diagnosis')) {
        setCurrentMenu('diagnosis');
      }
    }
  }, [current_menu]);

  function getActiveClass(path) {
    if (current_menu) {
      if (
        current_menu.toLowerCase().includes(path.toLowerCase()) ||
        path.toLowerCase().includes(current_menu.toLowerCase())
      ) {
        return 'active';
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
  function viewAllClick(data) {
    if (exploreMenuOpen) {
      exploreMenuToggler();
    }
    redirectTo('Speciality', data.master_specialities_id, data.specialities_name);
  }
  const paddingStyle = !isMobile
    ? {
      height: `calc(100vh - ${getHeight.current.offsetHeight}px)`,
      paddingTop: `${height}px`
    }
    : {
      height: `calc(100svh - ${getHeight.current.offsetHeight}px)`
    };
  return (
    <Navbar fixed="bottom" className="clr_v3_mobileFooter navbar bg-white p-0">
      <ul
        className="row bg-white position-relative z-2 justify-content-around flex-1 mb-0 list-unstyled navbar-nav fs-5 fw-semibold text-uppercase"
        ref={getHeight}
      >
        {menuJson?.static_menu?.length > 0 &&
          menuJson?.static_menu?.map((elem, ind) => (
            <li
              key={ind}
              className={`col nav-item ${getActiveClass(elem.url)}`}
              onClick={() => onMenuClick(elem.url, elem['short-name'], true)}
            >
              <a
                href="javascript:void(0)"
                className="nav-link d-flex align-items-center justify-content-center flex-column position-relative overflow-hidden"
              >
                <div className="clr_v3_mobileFooter__Nav-Circle rounded-circle bg-primary position-absolute start-50"></div>

                <div
                  className="nav-link__icon d-flex align-items-center justify-content-center mb-2"
                  dangerouslySetInnerHTML={{ __html: elem.icon }}
                />
                <span className="nav-link__text fs-6 mt-2">
                  <span>{reactHtmlParser(elem.title)}</span>
                </span>
              </a>
            </li>
          ))}

        <li
          className={`col nav-item clr_v3_mobileFooter__ExploreClick ${exploreMenuOpen ? 'active' : ''
            }`}
          onClick={() => {
            exploreMenuToggler();
          }}
        >
          <a
            href="javascript:void(0)"
            id="explore-menu"
            className="nav-link d-flex align-items-center justify-content-center flex-column position-relative overflow-hidden"
          >
            <div className="clr_v3_mobileFooter__Nav-Circle rounded-circle bg-primary position-absolute start-50"></div>
            <div className="nav-link__icon d-flex align-items-center justify-content-center mb-2">
              <i className="transition flaticon-other fs-2"></i>
            </div>

            {exploreKey && (
              <span className="nav-link__text fs-6 mt-2">
                <span class="badge bg-light text-primary rounded-pill position-absolute translate-middle-x start-50 fw-semibold fs-6 exploretag">
                  {reactHtmlParser(exploreKey)}
                </span>
              </span>
            )}

            <span className="nav-link__text fs-6 mt-2">Explore</span>
          </a>
        </li>
        {isIosApp() && <div className="col-12 iosSpecial-safe-area-bottom w-100"></div>}
      </ul>

      <div
        className={`clr_v3_explore position-absolute w-100 bg-white d-flex flex-column transition pt-50 ${exploreMenuOpen ? 'active' : ''
          }`}
        style={paddingStyle}
      >
        {/* <HeaderSpecialityMobile
            getElementHeight={getElementHeight}
            // selectedNavItem={activeNavItem}
            exploreMenuOpen={exploreMenuOpen}
          /> */}

        {/* <HeaderSpecialityDetailsMobile getElementHeight={getElementHeight}  /> */}
        <div className="clr_v3_explore__menus px-sm-4 px-3 pt-4 pb-3 w-100 fs-5">
          <div className="row m-0 ms-n3 mt-n3 ">
            {menuJson?.all_menu?.length > 0 &&
              menuJson?.all_menu?.map((key, ind) => {
                if (
                  key?.url?.toLowerCase() === 'dashboard' ||
                  key?.url?.toLowerCase() === 'doctube' ||
                  key?.url?.toLowerCase() === 'channel' ||
                  (isMobileApp() && key.url.toLowerCase() == 'telemed')
                )
                  return <></>;
                else
                  return (
                    <div
                      className={`col-sm-3 col-4 p-0 pt-3 ps-3 ${getActiveClass(key.url)}`}
                      id={key.id}
                      onClick={() => onMenuClick(key.url, key['short-name'])}
                      key={ind}
                    >
                      <div className="rounded-4 border border-1 border-light d-flex flex-column h-100 w-100 overflow-hidden p-3">
                        <div className="d-flex flex-column align-items-center pt-2">
                          {key.icon && (
                            <div className="mb-1 py-2 d-flex justify-content-center align-items-center clr_v3_explore__menus__icon">
                              <img src={key.icon} className="w-100 h-100" />
                            </div>
                          )}
                          {key.title && (
                            <span className="fw-semibold text-black text-center">
                              {reactHtmlParser(key.title)}
                            </span>
                          )}
                        </div>
                        {key.count && (
                          <div className="justify-content-between d-flex align-items-center p-3 pt-4 pb-2 bg-light text-black position-relative clr_v3_explore__menus__number mt-1">
                            <span>{key.count} +</span>
                            <i className="flaticon-next"></i>
                          </div>
                        )}
                      </div>
                    </div>
                  );
              })}
          </div>
        </div>
        {/* <HeaderSpecialityDetailsMobile getElementHeight={getElementHeight}  /> */}
        {/* <div className="clr_v3_explore__menus px-sm-4 px-3 pt-4 pb-3 w-100 fs-5">
          <div className="row m-0 ms-n3 mt-n3 ">
            {menuJson?.all_menu?.length > 0 &&
              menuJson?.all_menu?.map((key, ind) => {
                if (
                  key?.url?.toLowerCase() === 'dashboard' ||
                  key?.url?.toLowerCase() === 'doctube' ||
                  key?.url?.toLowerCase() === 'channel' ||
                  (isMobileApp() && key.url.toLowerCase() == 'telemed')
                )
                  return <></>;
                else
                  return (
                    <div
                      className={`col-sm-3 col-4 p-0 pt-3 ps-3 ${getActiveClass(key.url)}`}
                      id={key.id}
                      onClick={() => onMenuClick(key.url, key['short-name'])}
                      key={ind}
                    >
                      <div className="rounded-4 border border-1 border-light d-flex flex-column h-100 w-100 overflow-hidden p-3">
                        <div className="d-flex flex-column align-items-center pt-2">
                          {key.icon && (
                            <div className="mb-1 py-2 d-flex justify-content-center align-items-center clr_v3_explore__menus__icon">
                              <img src={key.icon} className="w-100 h-100" />
                            </div>
                          )}
                          {key.title && (
                            <span className="fw-semibold text-black text-center">
                              {reactHtmlParser(key.title)}
                            </span>
                          )}
                        </div>
                        {key.count && (
                          <div className="justify-content-between d-flex align-items-center p-3 pt-4 pb-2 bg-light text-black position-relative clr_v3_explore__menus__number mt-1">
                            <span>{key.count} +</span>
                            <i className="flaticon-next"></i>
                          </div>
                        )}
                      </div>
                    </div>
                  );
              })}
          </div>
        </div> */}

        <div className="clr_v3_explore__bottomArea flex-grow-1 pb-4">
          <div className="d-flex align-items-start ms-n1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 375 19.95"
              className="w-100 h-auto"
            >
              <path
                id="Path_484"
                data-name="Path 484"
                d="M0,43H375v7.931S281.75,62.951,188,62.951,0,50.931,0,50.931Z"
                transform="translate(0 -43)"
                fill="#fff"
              />
            </svg>
          </div>

          {specialityList && specialityList?.length > 0 && (
            <CommonSuggestionSection
              card={(data) => (
                <SpecialityCard
                  data={data?.specialities_name}
                  icon={data?.icon}
                  type="small"
                  onClick={() => viewAllClick(data)}
                />
              )}
              title={'Other Specialities'}
              titleType={'Browse'}
              cardData={specialityList.slice(0, 5)}
              onViewAllClick={onViewAllSpecialityClick}
            />
          )}

          <div className="px-sm-4 px-3 w-100 position-relative mt-25 text-start">
            <h4 className="fw-semibold fs-4 text-black">{packageJSon.version}-V</h4>
          </div>
        </div>
      </div>
    </Navbar>
  );
};

export default MobileFooterNav;
