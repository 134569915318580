import React, { memo, useMemo } from 'react';
import { ProgressBar } from 'react-bootstrap';

const SpecialityProgressBar = ({ title, variant, now, rating }) => {
  console.log('RATING_DECIDE', rating);
  const percentage = useMemo(() => {
    let splitted = rating?.split('/');
    if (typeof parseInt(splitted[0]) === 'number' && typeof parseInt(splitted[1]) === 'number') {
      return (parseInt(splitted[0]) / parseInt(splitted[1])) * 100;
    } else {
      return 0;
    }
  }, [rating]);

  const color = useMemo(() => {
    let percentage = 0;
    let splitted = rating?.split('/');
    if (typeof parseInt(splitted[0]) === 'number' && typeof parseInt(splitted[1]) === 'number') {
      percentage = (parseInt(splitted[0]) / parseInt(splitted[1])) * 100;
    }
    if (percentage >= 0 && percentage <= 30) {
      return 'danger';
    } else if (percentage > 30 && percentage <= 70) {
      return 'warning';
    } else {
      return 'success';
    }
  }, [rating, percentage]);
  return (
    <div className={`d-flex flex-column gap-2 ${color}`}>
      <div className="d-flex justify-content-between">
        <span className="fs-14 fw-normal">{title}</span>
        <span className={`fs-14 fw-semibold text-${color}`}>{rating}</span>
      </div>
      <ProgressBar variant={color} now={percentage} />
    </div>
  );
};

export default memo(SpecialityProgressBar);
