import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Workbox } from 'workbox-window';
import App from './App';
import store from './Store/store';
import './index.css';
import reportWebVitals from './reportWebVitals';
const notifyAppReady = async () => {
  const { CapacitorUpdater } = await import('@capgo/capacitor-updater');
  CapacitorUpdater.notifyAppReady();
};

if (isMobileApp()) {
  notifyAppReady();
}

import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType
} from 'react-router-dom';
import packageJson from '../package.json';
import { isMobileApp } from './common/common';

Sentry.init({
  release: packageJson.version,
  environment: 'Production',
  dsn: 'https://0b1141fa1c76c4dfa96af3473db3ecda@o4505725323706368.ingest.sentry.io/4505725326917632',
  integrations: [
    new Sentry.BrowserTracing({
      // See docs for support of different versions of variation of react router
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      )
    }),
    new Sentry.Replay()
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  tracesSampleRate: 1.0,
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['https://doctor.clirnet.com', 'https://dockerapidev.clirnet.com/'],
  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0
});
// store.subscribe(() => {
//   console.log('store::', store.getState());
//   let storeState = store.getState();
// });
// const updateSW = registerSW({
//   onNeedRefresh() {},
//   onOfflineReady() {},
//   onRegisteredSW(swUrl, r) {}
// });
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (!isMobileApp()) {
  if ('serviceWorker' in navigator) {
    const wb = new Workbox('/service-worker.js');
    let registration;

    const showSkipWaitingPrompt = async (event) => {
      // Assuming the user accepted the update, set up a listener
      // that will reload the page as soon as the previously waiting
      // service worker has taken control.
      wb.addEventListener('controlling', () => {
        // At this point, reloading will ensure that the current
        // tab is loaded under the control of the new service worker.
        // Depending on your web app, you may want to auto-save or
        // persist transient state before triggering the reload.
        window.location.reload();
      });

      // When `event.wasWaitingBeforeRegister` is true, a previously
      // updated service worker is still waiting.
      // You may want to customize the UI prompt accordingly.

      let result = confirm('New version available, reload?');
      console.log('user has accepted the update', result);
      wb.messageSkipWaiting();
    };

    // Add an event listener to detect when the registered
    // service worker has installed but is waiting to activate.
    wb.addEventListener('waiting', (event) => {
      showSkipWaitingPrompt(event);
    });

    wb.register()
      .then((reg) => {
        console.log('service worker registered', reg);
      })
      .catch((err) => {
        console.log('service worker registration failed', err);
      });
  }
}

