const env = process.env.REACT_APP_ENVIRONMENT;

const baseConfig = {
  imageKitEndpoint: process.env.REACT_APP_IMAGE_CDN_ENDPOINT,
  socialLinks: {
    facebook: process.env.REACT_APP_FACEBOOK_LINK,
    twitter: process.env.REACT_APP_TWITTER_LINK,
    instagram: process.env.REACT_APP_INSTAGRAM_LINK,
    linkedin: process.env.REACT_APP_LINKEDIN_LINK,
    youtube: process.env.REACT_APP_YOUTUBE_LINK,
    privacyPolicy: process.env.REACT_APP_PRIVACY_POLICY_LINK,
    termsAndConditions: process.env.REACT_APP_TERMS_CONDITIONS_LINK,
    termsAndConditionsGT: process.env.REACT_APP_TERMS_CONDITIONS_LINK_GT
  },
  img_path: process.env.REACT_APP_IMAGE_PATH,
  app_url: process.env.REACT_APP_BASE_URL,
  imgFolder: 'doctor/',
  app_store_url: process.env.REACT_APP_STORE_URL,
  is_production_build: process.env.NODE_ENV === 'production',
  subDomain: ''
};

const apiConfigLocal = {
  gtag_measure_id: process.env.REACT_APP_GTAG_ID_PRODUCTION,
  adobePdfClientId: process.env.REACT_APP_ADOBE_PDF_CLIENT_ID_LOCAL,
  firestoreEndPoints: {
    analyticsApi: process.env.REACT_APP_FIRESTORE_DEV
  },
  apiServices: {
    getTokenService: process.env.REACT_APP_API_URL_AUTH_SERVICE_DEV, //REACT_APP_LOCAL_BASE_URL, //''
    feedService: process.env.REACT_APP_API_URL_FEED_SERVICE //REACT_APP_LOCAL_BASE_URL
  },

  apiLoc: process.env.REACT_APP_LOCAL_BASE_URL,
  crmApiLoc: process.env.REACT_APP_CRM_DEV, //REACT_APP_API_GATEWAY_URL_GCP_CLOUD, //REACT_APP_CRM_DEV,
  doctubeApiLoc: process.env.REACT_APP_DOCTUBE_API_DEV //REACT_APP_API_GATEWAY_URL_GCP_CLOUD, //REACT_APP_DOCTUBE_API_DEV,
};

const apiConfigDev = {
  gtag_measure_id: process.env.REACT_APP_GTAG_ID_PRODUCTION,
  adobePdfClientId: process.env.REACT_APP_ADOBE_PDF_CLIENT_ID_TEST_GLOBAL_UAT,
  firestoreEndPoints: {
    analyticsApi: process.env.REACT_APP_API_URL_GCP_DEV
  },
  apiServices: {
    getTokenService: process.env.REACT_APP_API_URL_GCP_DEV, //REACT_APP_LOCAL_BASE_URL, //''
    feedService: process.env.REACT_APP_API_URL_GCP_DEV //REACT_APP_LOCAL_BASE_URL
  },

  apiLoc: process.env.REACT_APP_API_URL_GCP_DEV,
  crmApiLoc: process.env.REACT_APP_CRM_DEV, //REACT_APP_API_GATEWAY_URL_GCP_CLOUD, //REACT_APP_CRM_DEV,
  doctubeApiLoc: process.env.REACT_APP_DOCTUBE_API_DEV //REACT_APP_API_GATEWAY_URL_GCP_CLOUD, //REACT_APP_DOCTUBE_API_DEV,
};

const apiConfigUat = {
  gtag_measure_id: process.env.REACT_APP_GTAG_ID_PRODUCTION,
  adobePdfClientId: process.env.REACT_APP_REACT_APP_ADOBE_PDF_CLIENT_ID_PROD_UAT,
  firestoreEndPoints: {
    analyticsApi: process.env.REACT_APP_FIRESTORE_DEV
  },
  // cloudrun -config for dev
  apiServices: {
    getTokenService: process.env.REACT_APP_API_URL_AUTH_SERVICE_PROD_UAT,
    feedService: process.env.REACT_APP_API_URL_GCP_PROD_UAT
  },
  apiLoc: process.env.REACT_APP_API_URL_GCP_PROD_UAT,
  crmApiLoc: process.env.REACT_APP_CRM_UAT,
  doctubeApiLoc: process.env.REACT_APP_DOCTUBE_API_UAT
  // cloudrun -config for dev
};

const apiConfigGatewayUat = {
  gtag_measure_id: process.env.REACT_APP_GTAG_ID_PRODUCTION,
  adobePdfClientId: process.env.REACT_APP_REACT_APP_ADOBE_PDF_CLIENT_ID_PROD_GLOBAL_UAT,
  firestoreEndPoints: {
    analyticsApi: process.env.REACT_APP_FIRESTORE_PROD
  },
  apiServices: {
    getTokenService: process.env.REACT_APP_API_GATEWAY_URL_GCP_CLOUD_UAT,
    feedService: process.env.REACT_APP_API_GATEWAY_URL_GCP_CLOUD_UAT
  },
  apiLoc: process.env.REACT_APP_API_GATEWAY_URL_GCP_CLOUD_UAT,
  crmApiLoc: process.env.REACT_APP_API_GATEWAY_URL_GCP_CLOUD_UAT,
  doctubeApiLoc: process.env.REACT_APP_API_GATEWAY_URL_GCP_CLOUD_UAT
};

const apiConfigGatewayProd = {
  gtag_measure_id: process.env.REACT_APP_GTAG_ID_PRODUCTION,
  adobePdfClientId: process.env.REACT_APP_ADOBE_PDF_CLIENT_ID_PROD,
  firestoreEndPoints: {
    analyticsApi: process.env.REACT_APP_FIRESTORE_PROD
  },
  apiServices: {
    getTokenService: process.env.REACT_APP_API_GATEWAY_URL_GCP_CLOUD_PROD,
    feedService: process.env.REACT_APP_API_GATEWAY_URL_GCP_CLOUD_PROD
  },
  apiLoc: process.env.REACT_APP_API_GATEWAY_URL_GCP_CLOUD_PROD,
  crmApiLoc: process.env.REACT_APP_API_GATEWAY_URL_GCP_CLOUD_PROD,
  doctubeApiLoc: process.env.REACT_APP_API_GATEWAY_URL_GCP_CLOUD_PROD
};

const environment = () => {
  switch (env) {
    case '1':
      return apiConfigLocal;
    case '2':
      return apiConfigDev;
    case '3':
      return apiConfigUat;
    case '4':
      return apiConfigGatewayUat;
    case '5':
      return apiConfigGatewayProd;
    default:
      break;
  }
};

const appConfig = {
  ...baseConfig,
  ...environment()
};
export default appConfig;
