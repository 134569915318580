import React, { lazy } from 'react';
import Loadable from 'react-loadable';
import LoadingFallback from '../fallback/LoadingFallbackNew';
const SpecialityOnboarding = Loadable({
  loader: () => import('../../../src/pages/speciality/SpecialityOnboarding'),
  loading: LoadingFallback
});

const SpecialitySelect = Loadable({
  loader: () => import('../../../src/pages/uiv3/dashboard/SpecialitySelector'),
  loading: LoadingFallback
});

const SessionWaitingroomOutlet = Loadable({
  loader: () => import('../../../src/pages/sessionOutlet/SessionWaitingroomOutlet'),
  loading: LoadingFallback
});

export const PrivateRoutes = [
  {
    path: '/Onboarding',
    component: <SpecialityOnboarding />,
    module: 'speciality'
  },
  {
    path: '/SelectSpeciality',
    component: <SpecialitySelect />,
    module: 'speciality'
  },
  {
    path: '/SessionWaitingroom',
    component: <SessionWaitingroomOutlet />,
    module: 'session'
  }
];
