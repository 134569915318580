import axios from 'axios';
import { autoAuthConstants } from '../constants/autoAuthConstants';
import appConfig from '../../config/config';
import packageJson from '../../../package.json';
import { logoutUser } from './login.action';
import { isMobileApp, log } from '../../common/common';
import { isMobile } from 'react-device-detect';

const apiVersion = packageJson.version;

const url = appConfig.apiLoc;

export const validateAutoAuthToken = (token, callback) => {
  return (dispatch) => {
    axios({
      method: 'POST',
      url: url + 'autologin/validate',
      headers: {
        Authorization: token,
        version: isMobileApp() ? `rjsa ${apiVersion}` : `rjsw ${apiVersion}`
      }
    })
      .then((response) => {
        console.log('Validate', response.data);
        if (response.data) {
          callback(response.data);
          dispatch({
            type: autoAuthConstants.TOKEN_VALIDATE_SUCCESS
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status == 401) {
            dispatch(logoutUser());
          }
        }

        log('Error', error);
        callback('error');
        dispatch({
          type: autoAuthConstants.TOKEN_VALIDATE_FALIURE
        });
      });
  };
};
export const checkDuplicateEmail = (token, email, callback) => {
  let formdata = new FormData();
  formdata.append('email', email);
  return (dispatch) => {
    axios({
      method: 'POST',
      url: url + 'autologin/check_email_condition',
      headers: {
        Authorization: token,
        version: isMobileApp() ? `rjsa ${apiVersion}` : `rjsw ${apiVersion}`
      },
      data: formdata
    })
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status == 401) {
            dispatch(logoutUser());
          }
        }

        log('Error', error);
        callback('error');
      });
  };
};

export const checkDuplicateMobile = (token, phone_no, callback) => {
  let formdata = new FormData();
  formdata.append('phone_no', phone_no);
  return (dispatch) => {
    axios({
      method: 'POST',
      url: url + 'autologin/check_mobile_condition',
      headers: {
        Authorization: token,
        version: isMobileApp() ? `rjsa ${apiVersion}` : `rjsw ${apiVersion}`
      },
      data: formdata
    })
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status == 401) {
            dispatch(logoutUser());
          }
        }

        log('Error', error);
        callback('error');
      });
  };
};

export const autoAuthSignUp = (token, formData, callback) => {
  return (dispatch) => {
    axios({
      method: 'POST',
      url: url + 'autologin/signup',
      headers: {
        Authorization: token,
        version: isMobileApp() ? `rjsa ${apiVersion}` : `rjsw ${apiVersion}`
      },
      data: formData
    })
      .then((response) => {
        if (response.data) {
          callback(response.data);
          dispatch({
            type: autoAuthConstants.AUTOAUTH_SIGNUP_SUCCESS
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status == 401) {
            dispatch(logoutUser());
          }
        }
        console.log('Ah');
        log('Error', error);
        callback('error');
        dispatch({
          type: autoAuthConstants.AUTOAUTH_SIGNUP_FALIURE
        });
      });
  };
};

export const checkDoctorCertificate = (token, payload, callback) => {
  return (dispatch) => {
    axios({
      method: 'POST',
      url: `${url}openapi/authenticatecertificate`,
      headers: {
        Authorization: token,
        version: isMobileApp() ? `rjsa ${apiVersion}` : `rjsw ${apiVersion}`
      },
      data: payload
    })
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status == 401) {
            dispatch(logoutUser());
          }
        }
        console.log('Ah');
        log('Error', error);
        callback('error');
      });
  };
};

export const getUserCertificate = (token, payload, callback) => {
  return (dispatch) => {
    axios({
      method: 'POST',
      url: `${url}autologin/get_user_certificate`,
      headers: {
        Authorization: token,
        version: isMobileApp() ? `rjsa ${apiVersion}` : `rjsw ${apiVersion}`
      },
      data: payload
    })
      .then((response) => {
        if (response.data) {
          callback(response.data);
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status == 401) {
            dispatch(logoutUser());
          }
        }
        console.log('Ah');
        log('Error', error);
        callback('error');
      });
  };
};
