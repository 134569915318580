import appConfig from '../config/config';
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  isMobile,
  mobileVendor,
  osName,
  osVersion,
  fullBrowserVersion,
  browserName,
  mobileModel
} from 'react-device-detect';
import dayjs from 'dayjs';
import jwt_decode from 'jwt-decode';
import packageJson from '../../package.json';
import Compressor from 'compressorjs';
import {
  certified_courses_static_image,
  clinical_Video_static_image,
  grand_rounds_static_image,
  medwiki_static_image,
  quizzes_static_image
} from './assets.common';
import { Capacitor } from '@capacitor/core';
import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

const appVersion = packageJson.version;
const prodMode = appConfig.is_production_build;

export const sendPageViewToGa = (_page, _title, _location) => {
  ReactGA.send({
    hitType: 'pageview',
    page: _page,
    title: _title,
    location: _location
  });
};
export const isExpired = (accessToken) => {
  if (accessToken) {
    let access = jwt_decode(accessToken);
    let isExpired = dayjs.unix(access.exp).diff(dayjs()) < 1;
    return isExpired;
  } else {
    return true;
  }
};
export const setLocalStorage = (key, value) => {
  if (localStorage) {
    localStorage.setItem(
      `${prodMode ? '@ClirnetStore_prod' : '@ClirnetStore_dev'}_${key}`,
      typeof value == 'object' ? JSON.stringify(value) : value
    );
  }
};
// export const isApp = () => (getLocalStorage('from_app', false) ? true : false);
export const removeLocalStorage = (key) => {
  localStorage.removeItem(`${prodMode ? '@ClirnetStore_prod' : '@ClirnetStore_dev'}_${key}`);
};
export const getLocalStorage = (key, defaultValue) => {
  if (window?.localStorage) {
    let local_data = localStorage.getItem(
      `${prodMode ? '@ClirnetStore_prod' : '@ClirnetStore_dev'}_${key}`
    );
    if (local_data) {
      try {
        let parsed_data = JSON.parse(local_data);
        if (parsed_data) {
          return parsed_data;
        }
      } catch (error) {
        // console.log('===>>>error', error);
        return local_data;
      }
    } else {
      return defaultValue;
    }
  }
};

export const setSessionStorage = (key, value) => {
  if (sessionStorage) {
    sessionStorage.setItem(
      `${prodMode ? '@ClirnetStore_prod' : '@ClirnetStore_dev'}_${key}`,
      value
    );
  }
};
export const removeSessionStorage = (key) => {
  sessionStorage.removeItem(`${prodMode ? '@ClirnetStore_prod' : '@ClirnetStore_dev'}_${key}`);
};
export const getSessionStorage = (key, defaultValue) => {
  let local_data = sessionStorage.getItem(
    `${prodMode ? '@ClirnetStore_prod' : '@ClirnetStore_dev'}_${key}`
  );
  if (local_data) {
    return local_data;
  } else {
    return defaultValue;
  }
};

export const log = (msg = 'clirnet:log:', value = 'noop') => {
  console.log(`====>>>${msg}`, value);
};
export const specialityPopOver = (val, customClass = 'text-primary fs-2 cursorPointer ms-2') => {
  let speciality_array = val ? val?.split?.(',') : [];
  if (speciality_array?.length > 0) {
    speciality_array.shift();
  }

  return (
    <>
      {val && val?.split(',').length > 1 ? (
        <OverlayTrigger
          placement="right"
          overlay={
            <Tooltip id="tooltip-right" className="fs-5">
              {val
                ?.split(',')
                ?.filter((_, _i) => _i != 0)
                ?.join(', ')}
            </Tooltip>
          }
        >
          <span className="d-flex">
            <i className={`flaticon-more ${customClass}`}></i>
          </span>
        </OverlayTrigger>
      ) : null}
    </>
  );
};
export const specialityPopOverFromArray = (
  array,
  customClass = 'text-primary fs-2 cursorPointer ms-2'
) => {
  // let speciality_array = val ? val?.split?.(',') : [];
  // if (speciality_array?.length > 0) {
  //   speciality_array.shift();
  // }
  let val = array?.map((_sp) => _sp.name)?.join(',');
  return (
    <>
      {val && val?.split(',').length > 1 ? (
        <OverlayTrigger
          placement="right"
          rootClose={true}
          trigger={isMobile ? 'click' : 'hover'}
          // show={false}
          overlay={
            <Tooltip id="tooltip-right" className="fs-5">
              {val
                ?.split(',')
                ?.filter((_, _i) => _i != 0)
                ?.join(', ')}
            </Tooltip>
          }
        >
          <span className="d-flex">
            <i className={`flaticon-more ${customClass}`}></i>
            {/* <svg className={customClass} strokeWidth="0" viewBox="0 0 16 16">
              <path d="M3 9.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm5 0a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"></path>
            </svg> */}
          </span>
        </OverlayTrigger>
      ) : null}
    </>
  );
};
export class Countdown {
  constructor(options) {
    var timer,
      instance = this,
      seconds = options.seconds || 10,
      updateStatus = options.onUpdateStatus || function () {},
      counterEnd = options.onCounterEnd || function () {};

    function decrementCounter() {
      updateStatus(seconds);
      if (seconds === 0) {
        counterEnd();
        instance.stop();
      }
      seconds--;
    }

    this.start = function () {
      clearInterval(timer);
      timer = 0;
      seconds = options.seconds;
      timer = setInterval(decrementCounter, 1000);
    };

    this.stop = function () {
      clearInterval(timer);
    };
  }
}
export const getUtmSource = () => {
  let local_data = localStorage.getItem(
    `${prodMode ? '@ClirnetStore_prod' : '@ClirnetStore_dev'}_utm_source`
  );
  if (local_data) {
    return local_data;
  } else {
    return '';
  }
};
export const generateUtmExt = () => {
  return getLocalStorage('utm_source', '')
    ? `&utm_source=${getLocalStorage('utm_source', '')}`
    : '';
};
export const getAgent = () => {
  var OSName = 'Unknown OS';
  if (navigator.appVersion.indexOf('Win') != -1) OSName = 'Windows';
  if (navigator.appVersion.indexOf('Mac') != -1) OSName = 'MacOS';
  if (navigator.appVersion.indexOf('IOS') != -1) OSName = 'IOS';
  if (navigator.appVersion.indexOf('X11') != -1) OSName = 'UNIX';
  if (navigator.appVersion.indexOf('Linux') != -1) OSName = 'Linux';
  if (navigator.appVersion.indexOf('Android') != -1) OSName = 'Android';
  // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
  //   var device_type = 'Mobile';
  // } else {
  //   var device_type = 'PC';
  // }
  return OSName;
};

// export const redirectTo = (type,id) => {
//     return navigate(`/${type}/${id}`)
// }
const getFormatedData = (val = 0) => {
  return val.toString().padStart(2, 0);
};
export const secondsToHms = (d) => {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? getFormatedData(h) + ':' : '';
  var mDisplay = m > 0 ? getFormatedData(m) + ':' : '';
  var sDisplay = s > 0 ? getFormatedData(s) : '';
  return hDisplay + mDisplay + sDisplay;
};
export const generateTitle = (title) => {
  return (window.document.title = `CLIRNET - ${title}`);
};

export const timeDifference = (from) => {
  let now = dayjs(new Date()); //todays date
  let end = dayjs(from); // another date
  let seconds = now.diff(end, 's');

  return seconds;
};

export const getCurrentTime = (_format = 'YYYY-MM-DD HH:mm:ss') => {
  return dayjs(new Date()).format(_format);
};

export const downloadFromUrl = (remote_url = '', file_name = 'clirnet_file') => {
  fetch(remote_url, {
    headers: {
      'Access-Control-Allow-Origin': '*'
    }
  })
    .then((response) => {
      response
        .blob()
        .then((blob) => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = file_name;
          a.click();
        })
        .catch((error) => {
          console.log('===>>ERROR WHILE FETCHING', error);
        });
    })
    .catch((error) => {
      console.log('===>>ERROR WHILE FETCHING', error);
    });
};

export const isFileAllowed = (file = null, sizeInMb = 2) => {
  if (file) {
    if (file?.size / 1024 > sizeInMb * 1024) {
      return false;
    } else return true;
  }
};

export const handleCompressedUpload = (image = null, callback) => {
  if (image) {
    new Compressor(image, {
      quality: 0.6, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        const myFile = new File([compressedResult], compressedResult.name, {
          type: compressedResult.type
        });
        callback(myFile);
        // console.log('COMPRESSED', compressedResult, myFile);
        // callback(compressedResult);
      },
      error(err) {
        console.log(err.message);
      }
    });
  }
};
export const getPollQuestionCount = (data) => {
  if (data?.json_data && data?.json_data !== 'false') {
    var parsedData = JSON.parse(unescape(data.json_data));
    let qArray = Object.keys(parsedData?.surveys).map((k) => {
      return parsedData.surveys[k];
    });
    return qArray.length;
  } else {
    return -1;
  }
};
export const getLimitedText = (text, limit) => {
  if (text?.length > limit) {
    return text.replace(/&\w+;\s*/g, '').substring(0, limit) + '...';
  } else {
    return text.replace(/&\w+;\s*/g, '');
  }
};
export const getClientDeviceDetails = (mobile_number = 'NOT_FOUND') => {
  return {
    platform: (osName ?? 'NOT_FOUND') + ' ' + (osVersion ?? 'NOT_FOUND'),
    version: appVersion || 'NOT_FOUND',
    browser: browserName || 'NOT_FOUND',
    browser_version: fullBrowserVersion || 'NOT_FOUND',
    mobilenumber: mobile_number,
    mobilemodel: isMobile ? mobileVendor + ' ' + mobileModel : 'NOT_MOBILE',
    user_master_id: getLocalStorage('user', false) ? getLocalStorage('user', {})?.user_master_id : 0
  };
};
export const deleteFromArray = (elem, array) => {
  const index = array.indexOf(elem);
  if (index > -1) {
    array.splice(index, 1); // 2nd parameter means remove one item only
  }

  return array;
};
export const isApp = () => (getLocalStorage('from_app', false) ? true : false);
export const calculateMultiArrayLength = (array) => {
  let arrayLength = 0;
  if (array && array?.length > 0) {
    array.forEach((_it) => {
      arrayLength += _it?.length;
    });
    return arrayLength;
  } else {
    return arrayLength;
  }
};
//'https://appcdn.clirnet.com/common/assets/default_doctor_image.svg'
export const DEMO_USER_PICTURE =
  'https://firebasestorage.googleapis.com/v0/b/clirnetapp.appspot.com/o/doctor%2F0E57DB94-9662-11F6-3541-148D6F42BDC8%2F0E57DB94-9662-11F6-3541-148D6F42BDC8167242955049480.png?alt=media&token=1754cd39-f142-4ad9-aca8-8cc102dfe88f';
export const CLINICAL_DEMO = clinical_Video_static_image;
export const SESSION_DEMO =
  'https://previews.123rf.com/images/varijanta/varijanta1604/varijanta160400086/55848266-modern-thin-line-design-concept-for-healthcare-website-banner-vector-illustration-concept-for-health.jpg';
export const MEDWIKI_DEMO = medwiki_static_image;
export const QUIZ_DEMO = quizzes_static_image;
export const GR_DEMO = grand_rounds_static_image;
export const COURSE_DEMO = certified_courses_static_image;
export const placeDemoUserPicture = (e) => {
  e.target.src =
    'https://firebasestorage.googleapis.com/v0/b/clirnetapp.appspot.com/o/doctor%2F0E57DB94-9662-11F6-3541-148D6F42BDC8%2F0E57DB94-9662-11F6-3541-148D6F42BDC8167242955049480.png?alt=media&token=1754cd39-f142-4ad9-aca8-8cc102dfe88f';
};
export const parseJson = (str) => {
  // if (!str) return false;
  //   try {
  //     let parsed_data = JSON.parse(str);
  //     return parsed_data;
  //   } catch (error) {
  //     console.log('error===========>', error);
  //     return false;
  //   }
};
export const MEDWIKI_NAME = (
  <>
    MedWiki<sup>&reg;</sup>
  </>
);
export const stringToArray = (param = '') => {
  var string = param;
  string = string.split(' ');
  var stringArray = new Array();
  for (var i = 0; i < string.length; i++) {
    stringArray.push(string[i]);
    if (i != string.length - 1) {
      stringArray.push(' ');
    }
  }
  return stringArray;
};

export const reactHtmlParser = (_params) => {
  if (_params || typeof _params == 'string') {
    return parse(`${_params}`);
  }
};
export const userDetails = () =>
  getLocalStorage('user', false) ? getLocalStorage('user', {}) : {};
// export const jsonParser =  (stringifiedJSON) => {
//   let paresdJSON=null
//   try {
//     paresdJSON = JSON.parse(stringifiedJSON);
//     console.log('paresdJSON', paresdJSON);
//     return paresdJSON;
//   }
//   catch (error) {
//     console.log('error===>', error);
//     return false
//   }
// }
export const isEligibleForSpeciality = (details) => {
  if (!details || typeof details != 'object') {
    console.log('isEligibleForSpecialityif', typeof details);
    return false;
  } else {
    const { spec_dashboard_eligibility_status, specility_update_status } = details;

    return (
      spec_dashboard_eligibility_status ||
      (spec_dashboard_eligibility_status && specility_update_status === '0')
    );
    // return spec_dashboard_eligibility_status;
  }
};

export const isEligibleForDoctorVoice = (details) => {
  if (!details || typeof details != 'object') {
    console.log('isEligibleForSpecialityif', typeof details);
    return false;
  } else {
    const { doctor_voice_eligibility_status } = details;
    return doctor_voice_eligibility_status;
  }
};

export const isMobileApp = () => Capacitor.getPlatform() !== 'web';
export const isIosApp = () => Capacitor.getPlatform() == 'ios';
export const isAndroidApp = () => Capacitor.getPlatform() == 'android';

export const getNativePlatform = () => Capacitor.getPlatform();

export const openNewTab = (url, cb) => {
  let win = window.open(url, '_blank');
  console.log(win, win.location);
  if (win) {
    win.location;
  } else {
    if (cb) {
      cb();
    } else {
      window.location.href = url;
    }
  }
};

export const generateUrl = () => {
  const location = useLocation();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const currentUrl = baseUrl + '#' + location.pathname + location.search + location.hash;
  return currentUrl;
};
